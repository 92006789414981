import React from 'react';

type buttonType =
  | 'Filled'
  | 'Outlined'
  | 'orange-Filled'
  | 'orange-Outlined'
  | 'red-Filled';
interface HFbuttonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  type?: buttonType;
  height?: number;
  width?: number;
  isFocus?: boolean;
}
const HFbutton = ({
  type = 'Filled',
  className,
  height = 50,
  width,
  isFocus,
  ...rest
}: HFbuttonProps) => {
  const btnRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (isFocus) {
      btnRef.current?.focus();
    }
  }, [isFocus]);

  const clickhandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    rest.onClick && rest.onClick(e);
  };
  const getColor = (type: buttonType) => {
    switch (type) {
      case 'Filled':
      default:
        return rest.disabled
          ? 'bg-purple2 text-gray3'
          : 'bg-purple5 text-white border hover:bg-purple6 active:border-gary9 active:text-gray9';
      case 'Outlined':
        return rest.disabled
          ? 'bg-white text-purple2 border border-purple2'
          : 'bg-white text-purple5 hover:bg-purple0 border border-purple5 active:bg-purple1';
      case 'orange-Filled':
        return rest.disabled
          ? 'bg-[#FFC9B0] text-gray3'
          : 'bg-orange4 text-white hover:bg-orange5 active:bg-[#D34100] active:text-[#4F1800]';
      case 'orange-Outlined':
        return rest.disabled
          ? 'bg-white text-gray4 border border-gray4'
          : 'bg-white border border-red5 text-red5 hover:bg-[#FF373F0D] active:bg-[#FF373F40]';
      case 'red-Filled':
        return rest.disabled
          ? 'bg-red-200 text-gray3'
          : 'bg-red5 text-white hover:bg-red-600 active:bg-red-900 active:text-[#4F1800]';
    }
  };

  const py = (height - 26) / 2;
  const widthStyle = width ? `w-[${width}px]` : 'w-full';

  return (
    <button
      {...rest}
      ref={btnRef}
      onClick={clickhandler}
      className={`${widthStyle} h-auto py-[${py}px] box-border px-2.5 rounded font-bold ${
        className === undefined ? getColor(type) : className
      }`}
    />
  );
};

export default HFbutton;
