import { motion } from 'framer-motion';

const HomeschoolCostEffective = () => {
  return (
    <>
      <div className="relative flex flex-col items-center bg-white py-20 px-3 sm:px-10">
        <div className="w-full max-w-[480px]">
          {/*타이틀 */}
          <div className="text-center text-3xl md:text-4xl text-[#737373] font-bold">
            아무리 비교해 봐도
            <br />
            이런 가성비는 없습니다!
          </div>

          {/* 비용 비교 */}
          <div className="grid grid-cols-2 mt-5 text-xs md:text-base text-center">
            <div className="bg-[#fffcf7] rounded-2xl border-4 border-[#ff9b00] p-4 py-10 relative shadow-lg">
              <div className="bg-[#FFE0AE] font-bold rounded-full px-1 py-1 text-xs md:text-base">
                젭스홈스쿨 교육 비용
              </div>
              <div className="border-b font-bold border-black pb-2 mt-2 text-lg">
                베이직 1년 기준
              </div>

              <div className="mt-4 font-bold text-[#ff4c00]">
                2025년 홈스쿨 등록비
              </div>

              <div className="font-bold text-lg">월 120,000원</div>

              <div className="mt-2 font-semibold">*목회자 할인가 적용시</div>

              <div className="font-bold text-lg">월 100,000원</div>
              <motion.div
                className="absolute -right-5 top-2/3 transform -translate-y-1/2"
                // style={{ paddingRight: 6 }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <DoubleLeftArrow width={40} />
              </motion.div>
              {/* <div className="absolute -right-5 top-2/3 transform -translate-y-1/2">
                <DoubleLeftArrow width={40} />
              </div> */}
            </div>
            {/* <div className="absolute right-0 top-1/2 transform translate-x-1/2">
              <DoubleLeftArrow width={40} />
            </div> */}

            <div className="h-fit bg-gray2 rounded-r-2xl my-auto px-1 sm:px-4 py-7 shadow-lg">
              <div className="w-fit mx-auto bg-gray8 px-3 py-1 rounded-full text-white font-bold text-xs md:text-base">
                초등학생 평균 영어 사교육비
              </div>
              <div className="border-b border-black font-bold pb-2 mt-2 text-lg">
                일반 학원비 기준
              </div>

              <div className="mt-4 font-semibold">
                초등학생{' '}
                <span className="text-[#FF9B00] font-bold">전체 사교육비</span>
              </div>

              <div className="font-bold text-lg">월 398,000원</div>

              <div className="mt-2 font-semibold">
                초등학생{' '}
                <span className="text-[#FF4F00] font-bold">영어 사교육비</span>
              </div>

              <div className="font-bold text-lg">월 248,000원</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DoubleLeftArrow = ({ width }: { width: number }) => (
  <svg width={width} viewBox="0 0 72 72" fill="none">
    <defs>
      <path
        id="left-arrow"
        d="M36 53.1957L20.4566 37L36 20.913L32.3242 17L13 37L32.3242 57L36 53.1957Z"
        fill="white"
      />
    </defs>
    <circle cx="36" cy="36" r="36" fill="#FF9B00" />
    <g clipPath="url(#clip1_1_2)">
      <use href="#left-arrow" />
    </g>
    <g clipPath="url(#clip1_1_2)" transform="translate(15,0)" opacity={0.5}>
      <use href="#left-arrow" />
    </g>
  </svg>
);

export default HomeschoolCostEffective;
