import React, { useEffect, useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import { DatePicker, Table } from 'antd';

import HFbutton from '../../../../components/common/input/HFbutton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  getClassGroupFilter,
  getLmsStudnet,
  getTodoVoucher,
  postTodoAssign,
} from '../../../../api/utils/lms/lmsApi';
import { columnsStudent } from './columns';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import {
  openAlert,
  openModal,
  openConfirm,
} from '../../../../store/features/modalSlice';
import { ToDoMgmtSubType } from '../../../../enum';
import ModalTicketConfirm from '../../../../components/modal/ModalTicketConfirm';
import { setPurchaseTicketLink } from '../../../../api/utils/lms/ExtendSetBtnLink';
import { selectLmsLists } from '../../../../store/features/lmsSlice';
import {
  LmsMultiSelect,
  toLmsSelectOptions,
} from '../../../../components/lms/LmsSelect';
import { LmsClassGroupType } from '../../../../constants/types/lms-type';
import { LmsStudentProps } from '../../../../constants/types';
import dayjs, { Dayjs } from 'dayjs';
import { LoadingSpinner } from '../../../../components/Loading/LoadingSpinner';
import { numberWithCommas } from '../../../../api/utils/format/numbers';

const RegisterTodoDetail = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const { classId } = useParams();
  const lmsLists = useAppSelector(selectLmsLists);
  const subType = useParams().subType.toUpperCase();
  const [params, setParams] = useSearchParams();
  const idParams = params.get('ids');
  const ids = idParams?.split(',').map((id) => +id);
  const type: string = params.get('type');

  const [classGroup, setClassGroup] = useState<LmsClassGroupType[]>([]);
  const [selectedClassGroup, setSelectedClassGroup] = useState<string[]>([]);
  const [students, setStudents] = useState<LmsStudentProps[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().add(1, 'month'));

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getLmsStudnet(classId).then((res) => {
      setStudents(res);
    });
    getClassGroupFilter(classId).then((res) => {
      setClassGroup(res);
    });
  }, []);

  // 시작 날짜 변경 핸들러
  const startDateHandler = (date: Dayjs) => {
    // 과거에서 시작 불가 처리
    if (date.startOf('day').isBefore(dayjs().startOf('day'))) {
      dispatch(openAlert({ message: '지나간 날짜는 선택할 수 없습니다.' }));
      return;
    }

    // 종료일 이후로 시작일 설정 불가 처리
    if (date > endDate) {
      dispatch(
        openAlert({ message: '시작일은 종료일 이전으로 설정해주세요.' }),
      );
      return;
    }
    setStartDate(date);
  };

  // 종료 날짜 변경 핸들러
  const endDateHandler = (date: Dayjs) => {
    console.log(date);
    // const calcDate = calcDateClone(toDay(date), { day: +1, sec: -1 });
    const calcDate = dayjs(date)
      .startOf('day')
      .add(1, 'day')
      .subtract(1, 'second');

    if (startDate > calcDate) {
      dispatch(openAlert({ message: '종료일은 시작일 이후로 설정해주세요.' }));
      return;
    }
    // 등록일 기준 1년 이내로 설정
    // const afterYear = calcDateClone(toDay(), { year: +1 });
    const afterYear = dayjs().add(1, 'year');

    calcDate.isAfter(1, 'year');
    if (afterYear < calcDate) {
      dispatch(
        openAlert({
          message:
            '학생에게 부여한 할 일은 등록일로부터 최대 1년간 유효합니다.',
        }),
      );
      return;
    }

    setEndDate(calcDate);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const gotoList = () => {
    params.set('stage', 'todo');
    setParams(params);
  };

  const submitHandler = () => {
    if (selectedRowKeys.length === 0) {
      dispatch(openAlert({ message: '학생을 선택해주세요.' }));
      return;
    }

    todoAddEventHandle(
      ids,
      startDate,
      endDate,
      selectedRowKeys.map((x) => +x),
    );
  };

  const todoAddEventHandle = async (
    todoIds: number[],
    fromDate: any,
    toDate: any,
    studentIds: number[],
  ) => {
    // 1. 과제이용권 필요한 과제 등록 시 (이용권있을때)
    // 2. 과제이용권 필요한 과제 등록 시 (이용권없을때)
    // 3. 과제이용권 필요한 과제 등록 시 (이용권있으나 부족할때)
    if (
      ToDoMgmtSubType.RECITATION === subType ||
      ToDoMgmtSubType.JEBS_AI_RECITATION === subType ||
      ToDoMgmtSubType.JEBSPL === subType
    ) {
      try {
        getTodoVoucher(classId, {
          courseId: lmsLists.course?.id,
        }).then((res) => {
          let voucherLists = res;
          const deductionVoucher = todoIds.length * studentIds.length;
          const remainingTickets = voucherLists.find(
            // 인증제 할일 등록 시 이용권 차감 처리
            (x) => x.type === type,
          ).voucherCount;
          let message = '과제 배포를 위한 이용권이';
          let confirmText;
          let status: boolean = true;
          const deductionVoucherWithCommas = numberWithCommas(deductionVoucher);
          const remainingTicketsWithCommas = numberWithCommas(remainingTickets);
          if (remainingTickets === 0) {
            // Case 1-1 : (구)인증제 이용권 0개일 때
            if (subType === 'RECITATION') {
              message = message + ' 없습니다.';
              // FIXME: Alert -> 원버튼모달 변경 필요
              dispatch(
                openAlert({
                  message: message,
                }),
              );
              return;
            }
            // Case 1-2 : AI인증제, 젭스플 이용권 0개일때
            else {
              message = message + `\n없습니다.\n지금 이용권을 구매하세요.`;
              confirmText = `구매하기`;
              status = false;
            }
          } else if (remainingTickets < deductionVoucher) {
            // Case 2-1 : (구)인증제 이용권 부족할 때
            if (subType === 'RECITATION') {
              message =
                message +
                `\n부족해 과제를 배포할 수 없습니다.\n부족한 이용권은 추가 구매 불가하며,\nAI인증제를 이용해주세요.`;
              // FIXME: Alert -> 원버튼모달 변경 필요
              dispatch(
                openAlert({
                  message: message,
                }),
              );
              return;
            }
            // Case 2-2 : AI인증제, 젭스플 이용권 부족할 때
            else {
              message =
                message +
                ` \n${deductionVoucherWithCommas}개 차감됩니다.\n(현재 이용권 ${remainingTicketsWithCommas}개)\n부족한 이용권을 지금 구매하세요.`;
              confirmText = `구매하기`;
              status = false;
            }
          } else {
            // Case 3 : (구)인증제, AI인증제, 젭스플 이용권 있어서 과제 정상 배포 가능할 때
            message =
              message +
              ` \n${deductionVoucherWithCommas}개 차감됩니다.\n(현재 이용권 ${remainingTicketsWithCommas}개)\n할 일을 등록하시겠습니까?`;
            confirmText = `등록하기`;
          }
          dispatch(
            openModal({
              body: (
                <ModalTicketConfirm
                  title="확인"
                  message={message}
                  cancelText="취소"
                  confirmText={confirmText}
                  onConfirm={() =>
                    buttonAction(
                      todoIds,
                      fromDate,
                      toDate,
                      studentIds,
                      status,
                      gotoList,
                    )
                  }
                />
              ),
            }),
          );
        });
      } catch (error) {}
    } else {
      buttonAction(todoIds, fromDate, toDate, studentIds, true, gotoList);
    }
  };

  const buttonAction = async (
    todoIds: number[],
    fromDate: any,
    toDate: any,
    studentIds: number[],
    status: boolean,
    onSuccess: () => void,
  ) => {
    if (!status) {
      const targetUrl = setPurchaseTicketLink(type);
      window.open(targetUrl, '_blank');
    } else {
      const params = {
        toDoIds: todoIds,
        studentIds: studentIds,
        fromDate: fromDate,
        toDate: toDate,
      };
      setLoading(true);
      postTodoAssign(classId, params).then((res) => {
        setLoading(false);
        if (res.statusCode === 200 || res.statusCode === 201) {
          dispatch(openAlert({ message: '할 일이 등록되었습니다.' }));
          // 이전 페이지가 있다면 이전 페이지로.
          onSuccess();
          // nav('?stage=todo');
        } else {
          dispatch(openAlert({ message: '할 일 등록에 실패했습니다.' }));
          console.error(res);
        }
      });
    }
  };

  return (
    <div className="relative flex w-full flex-col gap-3 rounded bg-white px-[20px] py-[16px]">
      {loading && (
        <div className="fixed left-0 top-0 z-[10000] flex h-full w-full flex-col items-center justify-center gap-3 bg-black opacity-80">
          <LoadingSpinner />
          <TextWinds type="content_bold" color="white">
            할일 등록중입니다. 창을 닫지 말고 잠시만 기다려주세요.
          </TextWinds>
        </div>
      )}
      <header>
        <TextWinds type="title_h3">할 일 등록</TextWinds>
      </header>

      <section className="flex flex-col justify-between gap-3 px-0 py-2.5 lg:flex-row">
        <div className="flex gap-2.5">
          <TextWinds type="title_h4" color="purple5">
            1. 학생 선택
          </TextWinds>
          <TextWinds type="title_h5">진행 할 학생을 등록해주세요.</TextWinds>
        </div>
        <div className="flex flex-col items-center gap-3 sm:flex-row">
          <LmsMultiSelect
            label={'반 선택'}
            options={toLmsSelectOptions(classGroup, 'id', 'name', 'name')}
            onChange={(list) => setSelectedClassGroup(list)}
            className="w-[200px]"
          />
          <TextWinds type="content_bold" color="gray7">
            {selectedRowKeys.length}명 선택됨
          </TextWinds>
        </div>
      </section>

      <section>
        <Table
          columns={columnsStudent}
          dataSource={
            selectedClassGroup.length > 0
              ? students.filter((x) => {
                  const StudentGroupList = x.classInfos.map(
                    (x) => x.classGroup?.name,
                  );

                  return StudentGroupList.every((x) =>
                    selectedClassGroup.includes(x),
                  );
                })
              : students
          }
          rowKey={(record) => record.id}
          rowSelection={{ type: 'checkbox', ...rowSelection }}
          pagination={false}
          scroll={{ y: '30vh' }}
          size="small"
        />
      </section>

      <section className="flex flex-col gap-3">
        <TextWinds type="title_h4" color="purple5">
          2.기간 설정
        </TextWinds>

        <div className="flex w-full flex-col gap-3 sm:flex-row">
          <div className="flex items-center gap-3">
            <div>시작일</div>
            <DatePicker
              placeholder="시작일"
              value={startDate}
              onChange={startDateHandler}
              allowClear={false}
            />
          </div>
          <div className="flex items-center gap-3">
            <div>종료일</div>
            <DatePicker
              placeholder="종료일"
              value={endDate}
              onChange={endDateHandler}
              allowClear={false}
            />
          </div>
        </div>
      </section>

      <section>
        <div className="mb-3 text-center">
          ※ 학생에게 부여한 할 일은 등록일로부터 최대 1년간 유효합니다.
        </div>

        <div className="flex gap-2">
          <HFbutton type="Outlined" onClick={() => gotoList()}>
            취소
          </HFbutton>
          <HFbutton onClick={() => submitHandler()}>등록하기</HFbutton>
        </div>
      </section>
    </div>
  );
};

export default RegisterTodoDetail;
