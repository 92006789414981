import React, { useEffect } from 'react';
import StudentHomeworkItem from './StudentHomeworkItem';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import { useParams } from 'react-router-dom';
import instance from '../../../../api/axios/utils.ts/instance';
import {
  TodoMgmtSubTypeName,
  TodoOrderBy,
  ToDoStatusEnum,
} from '../../../../enum';
import { ApiResponse } from '../../../../constants/types';

type HomeworkFilterParam = {
  page: number;
  pageSize: number;
  todoType?: string | string[];
  status?: string | string[];
  orderBy?: string;
};
interface StudentHomeworkListProps {
  max?: number;
  todoType?: TodoMgmtSubTypeName | '';
  status?: ToDoStatusEnum | ToDoStatusEnum[];
  orderBy?: TodoOrderBy | '';
  setTotalCount?: (totalCount: number) => void;
  setCompletedCount?: (completedCount: number) => void;
}

const StudentHomeworkList = ({
  max,
  todoType,
  status,
  orderBy,
  setTotalCount,
  setCompletedCount,
}: StudentHomeworkListProps) => {
  const { classId } = useParams();
  const PAGESIZE = 10;
  const [page, setPage] = React.useState<number>(1);
  const [data, setData] = React.useState<myLearningTodoType[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const observer = React.useRef<IntersectionObserver>();
  const setTodoView = React.useState<Window>(undefined)[1];

  const getHomework = async (page: number) => {
    const params = getParams(page, todoType, status, orderBy);
    const result = await instance.get<
      ApiResponse<{
        todos: myLearningTodoType[];
        completedCount: number;
        totalCount: number;
      }>
    >('/v1/student/class/' + classId + '/todo', {
      params,
    });
    if (result.data.statusCode === 200) {
      if (status === ToDoStatusEnum.ALL) {
        setTotalCount(result.data.data.totalCount);
        setCompletedCount(result.data.data.completedCount);
      }
      return result.data.data;
    } else {
      console.error(result.data.message);
    }
  };

  const getParams = (
    page: number,
    todoType: TodoMgmtSubTypeName | '',
    status: ToDoStatusEnum | ToDoStatusEnum[],
    orderBy: TodoOrderBy | ''
  ) => {
    const params: HomeworkFilterParam = {
      page: page,
      pageSize: PAGESIZE,
    };
    if (status) {
      switch (status) {
        case ToDoStatusEnum.NOT_STARTED:
          params.status = ToDoStatusEnum.NOT_STARTED;
          break;
        case ToDoStatusEnum.IN_PROGRESS:
          if (todoType !== TodoMgmtSubTypeName.JEBS_AI_RECITATION) {
            params.status = ToDoStatusEnum.IN_PROGRESS;
          } else {
            break;
          }
          break;
        case ToDoStatusEnum.COMPLETED:
          params.status = [ToDoStatusEnum.COMPLETED, ToDoStatusEnum.CLOSED];
          break;
        case ToDoStatusEnum.PROCESSING_RESULT:
          if (todoType === TodoMgmtSubTypeName.JEBS_AI_RECITATION) {
            params.status = ToDoStatusEnum.PROCESSING_RESULT;
          } else {
            break;
          }
          break;
        default:
          break;
      }
    }
    if (todoType) {
      params.todoType = Object.keys(TodoMgmtSubTypeName).find(
        (key) =>
          TodoMgmtSubTypeName[key as keyof typeof TodoMgmtSubTypeName] ===
          todoType
      );
    }
    if (orderBy) {
      params.orderBy = Object.keys(TodoOrderBy).find(
        (key) => TodoOrderBy[key as keyof typeof TodoOrderBy] === orderBy
      );
    }
    return params;
  };

  useEffect(() => {
    setData([]);
    setHasMore(true);
    loadMoreItems(1); // Initial load
  }, [todoType, status, orderBy]);

  const loadMoreItems = React.useCallback(
    async (page: number) => {
      const newItems = await getHomework(page);
      setData((prev) => [...prev, ...newItems.todos]);
      setPage(page + 1);

      if (newItems.todos.length === 0) {
        setHasMore(false);
      }
    },
    [todoType, status, orderBy]
  );

  const lastItemRef = React.useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreItems(page);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, page, loadMoreItems]
  );

  return (
    <div className="mt-4 grid grid-cols-1 gap-4">
      {data && data.length > 0 ? (
        data
          .slice(0, max)
          .map((item, index) => (
            <StudentHomeworkItem
              data={item}
              key={item.id}
              setTodoView={setTodoView}
              lastItemRef={
                max !== undefined
                  ? undefined
                  : index === data.slice(0, max).length - 1
                    ? lastItemRef
                    : undefined
              }
            />
          ))
      ) : (
        <div>할 일이 없습니다.</div>
      )}
    </div>
  );
};

export default StudentHomeworkList;
