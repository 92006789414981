import React from 'react';
import GodDealWeekSection from '../../../../../components/course/churchschool/event/GodDealWeekSection';
import JepsplSection from '../../../../../components/course/churchschool/JepsplSection';
import WarningSection from '../../../../../components/course/churchschool/WarningSection';
import HFHJSection8b from '../../../../../components/course/churchschool/hfhj/HFHJSection8b';
import HFHJSection14 from '../../../../../components/course/churchschool/hfhj/HFHJSection14';

const BoostupHJBookMain = () => {
  return (
    <>
      <GodDealWeekSection />
      <HFHJSection8b />
      <WarningSection />
      <JepsplSection />
      <HFHJSection14 />
    </>
  );
};

export default BoostupHJBookMain;
