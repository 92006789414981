import React from 'react';
import { TodoMgmtSubTypeName } from '../../../enum';
import TicketExchangeModal from './TicketExchangeModal';
import { openModal } from '../../../store/features/modalSlice';
import { useAppDispatch } from '../../../store/hook';
import { TicketExchangeProvider } from './TicketExchangeContext';

export interface ExchangeRate {
  from: number;
  to: number;
}

export interface UseTicketExchangeProps {
  // A 티켓 타입
  typeA: TodoMgmtSubTypeName;
  // B 티켓 타입
  typeB: TodoMgmtSubTypeName;
  // classID
  classId: string;
  // courseID
  courseId: string;
  // 교환 비율
  exchangeRate: number | ExchangeRate;
}

const useTicketExchange = (props: UseTicketExchangeProps) => {
  const dispatch = useAppDispatch();

  const openTicketExchangeModal = () => {
    dispatch(
      openModal({
        body: (
          <TicketExchangeProvider initialData={props}>
            <TicketExchangeModal />
          </TicketExchangeProvider>
        ),
      })
    );
  };

  return { openTicketExchangeModal };
};

export default useTicketExchange;
