import TextWinds from '../../../components/common/TextWinds';
import '../../../styles/mypage/mypage.css';
import { orderDetailType } from '../../../constants/types/mypage-type';
import { numberWithCommas } from '../../../api/utils/format/numbers';
import { getLatestTransaction } from '../../../api/utils/order-item-transaction';
import { TransactionStatusLabelEnum } from '../../../enum';

const PaymentDetail = ({ orderDetail }: { orderDetail: orderDetailType }) => {
  const getPaytype = () => {
    switch (orderDetail.payment_method) {
      case 'CREDIT_CARD':
        return '신용카드 결제';
      case 'DEPOSIT':
        return '무통장 입금';
      default:
        console.error(orderDetail.payment_method);
        return '결제수단 확인 불가';
    }
  };
  const latestTransaction = getLatestTransaction(orderDetail.transactions);

  return (
    <div className="flex flex-col gap-y-4 px-3 py-[14px] bg-gray0">
      <TextWinds type="sub_title01">결제 상세</TextWinds>
      <div className="flex justify-between items-center">
        <TextWinds type="content_body4" color="gray7">
          수단
        </TextWinds>
        <TextWinds type="content_body4">{getPaytype()}</TextWinds>
      </div>

      <div className="flex justify-between items-center">
        <TextWinds type="content_body4" color="gray7">
          결제 금액
        </TextWinds>
        {numberWithCommas(
          orderDetail.template.total_price -
            (orderDetail.point_used ? orderDetail.point_used : 0)
        )}{' '}
        원
      </div>
      <div className="flex justify-between items-center">
        <TextWinds type="content_body4" color="gray7">
          처리 상태
        </TextWinds>
        <TextWinds type="content_body4">
          {latestTransaction.method === 'DEPOSIT' ? '입금 ' : '승인 '}
          {TransactionStatusLabelEnum[latestTransaction.status]}
        </TextWinds>
      </div>

      {latestTransaction.status === 'DEPOSIT_REQUESTED' && (
        <>
          <div className="flex justify-between items-center">
            <TextWinds type="content_body4" color="gray7">
              예금주
            </TextWinds>
            <TextWinds type="content_body4">
              {latestTransaction.depositName ?? '-'}
            </TextWinds>
          </div>
          <div className="flex justify-between items-center">
            <TextWinds type="content_body4" color="gray7">
              입금계좌
            </TextWinds>
            <TextWinds type="content_body4">
              {latestTransaction.bankName ?? '-'} {latestTransaction.accountNo}
            </TextWinds>
          </div>
          <div className="flex justify-between items-center">
            <TextWinds type="content_body4" color="gray7">
              입금기한
            </TextWinds>
            <TextWinds type="content_body4">
              {latestTransaction.expiryDate ?? '-'}
            </TextWinds>
          </div>
        </>
      )}
      <div className="flex justify-between items-center">
        <TextWinds type="content_body4" color="gray7">
          포인트 사용
        </TextWinds>
        <TextWinds type="content_body4">
          {numberWithCommas(
            orderDetail.point_used ? orderDetail.point_used : 0
          )}{' '}
          포인트
        </TextWinds>
      </div>
    </div>
  );
};

export default PaymentDetail;
