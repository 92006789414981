import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
const GoogleTagManager = ({ gtmId }: { gtmId: string }) => {
  const location = useLocation();
  useEffect(() => {
    TagManager.initialize({ gtmId });
  }, [gtmId]);

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: pagePath,
      },
    });
  }, [location]);

  return <></>; // Empty fragment since this component doesn't render anything
};
export default GoogleTagManager;
