import Cookies from 'js-cookie';

// 쿠키 이름(key) 변경
export enum CookieKey {
  JEBS_AI_RECITATION = 'JEBS_AI_RECITATION',
  USER_INFO = 'USER_INFO',
  JEBS_AI_RECITATION_RESULT = 'JEBS_AI_RECITATION_RESULT',
}
type sameSite = 'Lax' | 'None' | 'Strict';
export const createCookie = <GData>(key: CookieKey) => {
  const config = {
    // INFO: 도메인이 다를 경우 쿠키 생성이 안됨 (로컬에서 코드를 실행해서 쿠키 생성하려면 config에서 damain 제거해야함)
    domain: 'jebs.kr',
    path: '/',
    secure: true,
    sameSite: 'None' as sameSite,
  };

  return {
    setCookie: (value: GData, days?: number) => {
      Cookies.set(key, JSON.stringify(value), {
        ...config,
        expires: days,
      });
    },
    getCookie: (): GData => JSON.parse(Cookies.get(key)),
    removeCookie: () => {
      Cookies.remove(key, {
        ...config,
      });
    },
  };
};
