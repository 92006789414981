import { Link, useNavigate } from 'react-router-dom';
import { layout } from '../constants/urlconfig';
import { Typography } from '@material-tailwind/react';
import { FooterLinkIcon, FooterLinkString } from './footerLink';
import MediaQuery from 'react-responsive';
import useUserHook from '../controllers/useUserHook';
import ChannelTalk from './ChannelTalk';
import ChannelService from '../api/ChannelService';
import { useAppDispatch } from '../store/hook';
import { AgreementModal, AgreementType } from './common/Agreement';
import { openModal } from '../store/features/modalSlice';

const Footer = () => {
  return (
    // <footer className='w-full mx-auto px-14 py-7 block bg-opacity-80'>
    <footer className="m-auto max-w-[1440px] lg:px-14">
      <div className="mb-4 flex flex-col justify-between gap-4 lg:my-14 lg:flex-row-reverse">
        <div>
          <CS />
          <FooterLinks />
        </div>
        <Info />
      </div>
    </footer>
  );
};
const Info = () => (
  <div className="text-gray-07 text-sm">
    <div className="px-4 py-2">
      <Typography variant="h5" className="text-blue-gray-900 opacity-50">
        (주)캐더
      </Typography>
      <Typography variant="h6" className="mt-2 font-normal">
        서울시 강남구 선릉로111길 6 성암아트센터 5층
      </Typography>
      <Typography variant="h6" className="mb-8 font-normal">
        cs@jebs.kr / 1800-0588
      </Typography>
      <Typography variant="h6" className="font-normal">
        대표자명 : 정학영 / 사업자등록번호 : 220-87-79868
      </Typography>
      <Typography variant="h6" className="font-normal">
        통신판매업신고번호 : 제2014-서울서초-1004호
      </Typography>
      <Typography variant="h6" className="font-medium">
        ⓒ Cather All rights reserved.
      </Typography>
    </div>
    <div className="lg:px-4 lg:py-2">
      <Agreements />
    </div>
  </div>
);
const Agreements = () => {
  const dispatch = useAppDispatch();
  const AgreementList = [
    AgreementType.TERAMS_CONDITION,
    AgreementType.PERSONAL_POLICY,
    AgreementType.REFUND,
  ];
  const onClick = (type: AgreementType) => {
    dispatch(openModal({ body: <AgreementModal agreement={type} /> }));
  };
  return (
    <ul className="flex flex-wrap items-center justify-center gap-x-8 gap-y-2 lg:justify-start">
      {AgreementList.map((item, index) => (
        <li
          key={index}
          onClick={() => onClick(item)}
          className="block cursor-pointer font-sans text-base font-bold leading-relaxed text-blue-gray-900 antialiased transition-colors hover:text-blue-500 focus:text-blue-500"
        >
          {item}
        </li>
      ))}
    </ul>
  );
};
const CS = () => {
  const nav = useNavigate();
  const { checkAndPageMove } = useUserHook();

  const cxhandler = () => {
    checkAndPageMove('/mypage/customer');
  };
  return (
    <div className="bg-purple-00 p-5 lg:bg-white lg:p-0">
      <Typography variant="h6" className="py-1 font-bold">
        고객센터 1800-0588
      </Typography>
      <Typography variant="h6" className="py-1 font-normal">
        평일 10:00~17:00 (점심시간 13:00~14:00)
      </Typography>
      <Typography
        variant="h6"
        className="mt-4 hidden cursor-pointer font-normal lg:block"
        onClick={() => cxhandler()}
      >
        고객센터 바로가기
      </Typography>
      <Typography
        variant="h6"
        className="mt-4 hidden cursor-pointer font-normal lg:block"
        onClick={() => {
          ChannelService.openChat();
        }}
      >
        1:1 문의하기
      </Typography>
    </div>
  );
};
const FooterLinks = () => (
  <div className="mt-6 px-4 py-2">
    <div className="flex flex-row items-center justify-center gap-x-5">
      <FooterLinkIcon
        to={layout.kakaochannel}
        view={'/svg/icon-kakaochannel.svg'}
      />
      <FooterLinkIcon to={layout.blog} view={'/svg/icon-blog.svg'} />
      <FooterLinkIcon to={layout.youtube} view={'/svg/icon-youtube.svg'} />
      <FooterLinkIcon to={layout.instagram} view={'/svg/icon-instagram.svg'} />
    </div>
  </div>
);

export default Footer;
