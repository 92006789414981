import { ApiResponse } from '../../../constants/types';
import { studentBadgeType } from '../../../constants/types/student-type';
import instance from '../../axios/utils.ts/instance';

/**
 * 학생 뱃지 조회
 */
export const getStudentBadge = async () => {
  const result = await instance.get<ApiResponse<studentBadgeType[]>>(
    // '/v1/student/badge'
    '/v1/recitation/badge'
  );
  return result.data.data;
};

/**
 * 학생 할일 상태 변경
 * @param classId
 * @param todoId
 * @param status
 */
export const patchTodoStatus = async (
  classId: string,
  todoId: number,
  status: string
) => {
  try {
    const result = await instance.patch(
      `/v1/student/class/${classId}/todo/${todoId}`,
      { status: status }
    );
  } catch (e) {
    console.error(e);
  }
};

/**
 * 인증제 시도 횟수 체크
 * @param learningId
 * @param todoId
 * @returns
 */
export const getRecitationAttemptCount = async (
  learningId: string,
  todoId: number
) => {
  const result = await instance.get(
    `/v1/student/class/${learningId}/todo/${todoId}/recitation-attempt-count`
  );
  return result.data.data;
};

/**
 * 결과 보기 체크
 * @param learningId
 * @param todoId
 */
export const patchViewdResult = async (
  learningId: string,
  todoId: number,
  viewed: boolean
) => {
  const result = await instance.patch(
    `/v1/student/class/${learningId}/todo/${todoId}/viewed-result`,
    { viewed }
  );
  return result.data.data;
};
