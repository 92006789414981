import dayjs from 'dayjs';
import { carouselItem } from './types';

export const IMAGE_LIST: carouselItem[] = [
  {
    img: 'images/home/banner/bannerD-25.03.04-2.jpg',
    mobile: 'images/home/banner/bannerM-25.02.28.jpg',
    title: '리뉴얼 홈페이지 배너- 3월 홈스쿨 이벤트',
    link: '/courses/homeschool/details/DAvFDd_N0nbFgISL',
    date: {
      start: dayjs('2025-03-01'),
      end: dayjs('2025-03-31'),
    },
  },
  {
    img: 'images/home/banner/bannerD-25.03.26-1.jpg',
    mobile: 'images/home/banner/bannerM-25.03.26-1.jpg',
    title: '리뉴얼 홈페이지 배너- 4월 홈스쿨 이벤트',
    link: '/courses/homeschool/details/DAvFDd_N0nbFgISL',
    date: {
      start: dayjs('2025-04-01'),
      end: dayjs('2025-04-30'),
    },
  },
  {
    img: 'images/home/banner/bannerD-25.03.26-2.jpg',
    mobile: 'images/home/banner/bannerM-25.03.26-2.jpg',
    title: '리뉴얼 홈페이지 배너- 4월 갓딜위크',
    link: 'https://cafe.naver.com/jcebs/26406',
    type: '_blank',
    date: {
      start: dayjs('2025-04-01'),
      end: dayjs('2025-04-14'),
    },
  },
  {
    img: 'images/home/banner/bannerD-24.09.12-2.jpg',
    mobile: 'images/home/banner/bannerM-24.09.12-2.jpg',
    title: '리뉴얼 홈페이지 배너- 김PD가간다-광명교회',
    link: 'https://youtu.be/Cqt7cDnZVNc?si=i9ontV0V1sNtOM3V',
    type: '_blank',
  },
  {
    img: 'images/home/banner/bannerD-24.12.17.jpg',
    mobile: 'images/home/banner/bannerM-24.12.17.jpg',
    title: '리뉴얼 홈페이지 배너- A.D 영어학습법',
    link: '/shop/details/KwSCZEaDWmHeUzku',
  },
  {
    img: 'images/home/banner/bannerD-24.08.19.jpg',
    mobile: 'images/home/banner/bannerM-24.08.19.jpg',
    title: '리뉴얼 홈페이지 배너- 영어로 그림전도 출간',
    link: '/shop/details/U9NHpfT92l_A4zTE',
  },
];
