import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
} from "react";
import { TodoMgmtSubTypeName } from "../../../enum";
import { ExchangeRate, UseTicketExchangeProps } from "./useTicketExchange";
import {
  getTodoVoucher,
  postExchangeTicket,
} from "../../../api/utils/lms/lmsApi";

interface TicketExchangeState {
  fromTicket: {
    type: TodoMgmtSubTypeName;
    amount: number;
    selected: number;
  };
  toTicket: {
    type: TodoMgmtSubTypeName;
    amount: number;
    selected: number;
  };
  exchangeRate: ExchangeRate;
  // 초기화중, 교환, 교환중, 완료, 오류
  state: "init" | "exchange" | "loading" | "done" | "error";
  error: string | null;
}

type TicketExchangeAction =
  | { type: "SET_FROM_AMOUNT"; payload: number }
  | { type: "SET_FROM_SELECTED"; payload: number }
  | { type: "SET_ERROR"; payload: string }
  | { type: "SET_LOADING"; payload: boolean };

const initialState: TicketExchangeState = {
  fromTicket: {
    type: TodoMgmtSubTypeName.RECITATION,
    amount: 0,
    selected: 0,
  },
  toTicket: {
    type: TodoMgmtSubTypeName.JEBS_AI_RECITATION,
    amount: 0,
    selected: 0,
  },
  exchangeRate: { from: 0, to: 0 },
  state: "init",
  error: null,
};

const ticketExchangeReducer = (
  state: TicketExchangeState,
  action: TicketExchangeAction,
): TicketExchangeState => {
  switch (action.type) {
    case "SET_FROM_AMOUNT":
      return {
        ...state,
        fromTicket: {
          ...state.fromTicket,
          amount: action.payload,
        },
        state: "exchange",
      };
    case "SET_FROM_SELECTED":
      return {
        ...state,
        fromTicket: {
          ...state.fromTicket,
          selected: action.payload,
        },
        toTicket: {
          ...state.toTicket,
          selected: Math.floor(
            action.payload * (state.exchangeRate.to / state.exchangeRate.from),
          ),
        },
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
        state: "error",
      };
    case "SET_LOADING":
      return {
        ...state,
        state: action.payload ? "loading" : "done",
      };
    default:
      return state;
  }
};

interface TicketExchangeContextType {
  state: TicketExchangeState;
  setFromSelected: (amount: number) => void;
  exchangeTickets: (classId: string) => Promise<void>;
}

const TicketExchangeContext = createContext<
  TicketExchangeContextType | undefined
>(undefined);

export const TicketExchangeProvider = ({
  children,
  initialData,
}: {
  children: ReactNode;
  initialData: UseTicketExchangeProps;
}) => {
  // const { message } = App.useApp();

  const rate: ExchangeRate =
    typeof initialData.exchangeRate === "number"
      ? { from: initialData.exchangeRate, to: 2 }
      : initialData.exchangeRate;

  const [state, dispatch] = useReducer(ticketExchangeReducer, {
    ...initialState,
    fromTicket: {
      type: initialData.typeA,
      amount: 0,
      // selected: Math.floor(initialData.amountA / rate.from) * rate.from,
      selected: 0,
    },
    toTicket: {
      type: initialData.typeB,
      amount: 0,
      // selected: Math.floor(
      //   (Math.floor(initialData.amountA / rate.from) * rate.from * rate.to) /
      //     rate.from
      // ),
      selected: 0,
    },
    exchangeRate: rate,
  });

  useEffect(() => {
    getTodoVoucher(initialData.classId, {
      courseId: initialData.courseId,
    }).then((res) => {
      const recitationVoucher = res.find((item) => item.type === "RECITATION");
      dispatch({
        type: "SET_FROM_AMOUNT",
        payload: recitationVoucher?.voucherCount ?? 0,
      });
      dispatch({
        type: "SET_FROM_SELECTED",
        payload: 0,
        // payload:
        //   Math.floor((recitationVoucher?.voucherCount ?? 0) / rate.from) *
        //   rate.from,
      });
    });
  }, [initialData]);

  const setFromSelected = (amount: number) => {
    dispatch({ type: "SET_FROM_SELECTED", payload: amount });
  };

  const exchangeTickets = async (classId: string): Promise<void> => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      await postExchangeTicket(classId, {
        fromQuantity: state.fromTicket.selected,
      });
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({
        type: "SET_ERROR",
        payload: "⛔️ 티켓 교환에 실패했습니다.",
      });
    }
  };

  return (
    <TicketExchangeContext.Provider
      value={{
        state,
        setFromSelected,
        exchangeTickets,
      }}
    >
      {children}
    </TicketExchangeContext.Provider>
  );
};

export const useTicketExchange = () => {
  const context = useContext(TicketExchangeContext);
  if (!context) {
    throw new Error(
      "useTicketExchange must be used within a TicketExchangeProvider",
    );
  }
  return context;
};
