import { useNavigate, useParams } from 'react-router-dom';
import TextWinds from '../../../components/common/TextWinds';
import Buttons from '../../../components/common/Buttons2';
import OrderInfo from '../../../components/mypage/purchase/OrderInfo';
import PaymentDetail from '../../../components/mypage/purchase/PaymentDetail';
import RefundCancelInfo from '../../../components/mypage/purchase/RefundCancelInfo';
import DeliveryInfo from '../../../components/mypage/purchase/DeliveryInfo';
import '../../../styles/mypage/mypage.css';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import {
  getMyOrderDetail,
  selectMyOrderDetail,
} from '../../../store/features/mySlice';
import { useContext, useEffect } from 'react';
import { openModal } from '../../../store/features/modalSlice';
import PurchaseReceipt from './PurchaseReceipt';
import { MypageContext } from '../membership/MypageContext';

const MypagePurchaseDetail = () => {
  const { userInfo } = useContext(MypageContext);
  const detailId = useParams().detailId;
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(-1);
  };

  const dispatch = useAppDispatch();
  const orderDetail = useAppSelector(selectMyOrderDetail);

  useEffect(() => {
    dispatch(
      getMyOrderDetail({ url: '/v1/mypage/orders', params: detailId })
    );
  }, [dispatch, detailId]);

  const openReceipt = () => {
    dispatch(
      openModal({
        body: (
          <PurchaseReceipt
            orderDetail={orderDetail}
            userName={userInfo?.name}
          />
        ),
      })
    );
  };

  return (
    <section className="px-5 py-[25px] lg:p-0">
      <div className="hidden mb-6 lg:block lg:mb-6">
        <TextWinds type="title_h2">구매 내역 상세</TextWinds>
      </div>
      <div className="flex flex-col gap-y-6 lg:gap-y-4">
        {orderDetail && <OrderInfo orderDetail={orderDetail} />}
        {orderDetail && <PaymentDetail orderDetail={orderDetail} />}
        {orderDetail?.status === 'REFUND_COMPLETE' && (
          <RefundCancelInfo orderDetail={orderDetail} />
        )}
        {orderDetail && <DeliveryInfo orderDetail={orderDetail} />}
      </div>
      <div className="hidden lg:flex justify-center items-center mt-8">
        <img
          className="h-6"
          src={process.env.PUBLIC_URL + '/svg/icon-arrow-left-purple.svg'}
          alt="Calendar"
        />
        <Buttons
          variant="text"
          color="purple5"
          type="text"
          onClick={() => clickHandler()}
        >
          <TextWinds type="button2" color="purple5">
            주문 목록으로 이동
          </TextWinds>
        </Buttons>
      </div>
      <div className="flex flex-col mt-16 lg:mt-6 gap-2">
        <Buttons
          variant="outlined"
          color="purple5"
          onClick={() => openReceipt()}
        >
          <TextWinds type="button" color="purple5">
            영수증 출력하기
          </TextWinds>
        </Buttons>
      </div>
    </section>
  );
};

export default MypagePurchaseDetail;
