import {
  ApiResponse,
  Class,
  CourseLesson,
  LmsStudentProps,
  PaginationResponse,
} from '../../../constants/types';
import { myLearningLessonInfoType } from '../../../constants/types/learning-type';
import {
  LmsClassGroupType,
  LmsStudentType,
  LmsTodoStatusByIdType,
  LmsTodoStatusByTodoType,
  assignedLmsTodosById,
  assignedLmsTodosByTodo,
  recitationLevelType,
  recitationResultType,
} from '../../../constants/types/lms-type';
import { studentType } from '../../../constants/types/student-type';
import { ToDoStatusEnum } from '../../../enum';
import instance from '../../axios/utils.ts/instance';
import { sortStrArrContainingNumbers } from '../data/sort';
import { getErrorMessages } from '../util-func';

/**
 * lms 수업하기 북/챕터 리스트 가져오기
 * @param classId
 * @returns
 */
export const getBookChapterList = async (classId: string) => {
  const response = await instance.get<Class>(
    `/v1/class/chapter-list/${classId}`
  );
  return response.data;
};

/**
 * lms 수업하기 레슨 목록 가져오기
 * @param classId
 * @param bookId 북/챕터 아이디. 필터링을 위한 값
 * @returns
 */
export const getLessonList = async (classId: string, bookId: string) => {
  const response = await instance.get<{ lessons: CourseLesson[] }>(
    `/v1/class/${classId}/chapter/${bookId}`
  );
  return response.data.lessons;
};

/**
 * lms 수업하기 콘텐츠 목록 가져오기
 * @param classId
 * @param params 타입 지정 필요. 필터링을 위한 값
 * @returns
 */
export const getTakeClassList = async (classId: string, params: any) => {
  try {
    type ResponseType = ApiResponse<
      PaginationResponse<myLearningLessonInfoType[]>
    >;
    const response = await instance.get<ResponseType>(
      `/v1/class/${classId}/contents`,
      { params }
    );

    return response.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일 목록 가져오기
 * @param classId
 * @param type
 * @param page
 * @param pageSize
 * @param book
 * @param lesson
 * @returns
 */
export const getTodoLists = async (
  classId: string,
  type: string,
  page: number,
  pageSize: number,
  book?: string,
  lesson?: string
) => {
  let params = {
    page: page,
    pageSize: pageSize,
    type: type,
    book,
    lesson,
  };

  try {
    const result = await instance.get<ApiResponse<PaginationResponse<any>>>(
      `/v1/lms/${classId}/todo`,
      { params }
    );
    return result.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일 목록 북/챕터 리스트 가져오기
 * @param classId
 * @param type
 * @returns
 */
export const getTodoBookLists = async (classId: string, type: string) => {
  try {
    const params = {
      type: type,
    };

    const response = await instance.get<ApiResponse<string[]>>(
      `/v1/lms/${classId}/todo/book-filter`,
      {
        params,
      }
    );
    return sortStrArrContainingNumbers(response.data.data);
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일 목록 레슨 리스트 가져오기
 * @param classId
 * @param type
 * @param book
 * @returns
 */
export const getTodoLessonLists = async (
  classId: string,
  type: string,
  book: string
) => {
  try {
    const result = await instance.get<ApiResponse<string[]>>(
      `/v1/lms/${classId}/todo/lesson-filter`,
      {
        params: {
          type,
          book,
        },
      }
    );
    return sortStrArrContainingNumbers(result.data.data);
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 이용권 목록 가져오기
 * @param courseId
 * @returns
 */
export const getTodoVoucher = async (
  classId: string,
  params: { courseId: string }
) => {
  try {
    const result = await instance.get<
      ApiResponse<
        {
          id: string;
          voucherCount: number;
          teacherId: string;
          name: string;
          type: string;
        }[]
      >
    >(`/v1/lms/${classId}/todo/voucher`, {
      params,
    });
    return result.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일 등록용 반 필터 목록
 * @param classId
 * @returns
 */
export const getClassGroupFilter = async (classId: string) => {
  try {
    const result = await instance.get<ApiResponse<LmsClassGroupType[]>>(
      `/v1/lms/${classId}/todo/class-group-filter`
    );
    return result.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일 등록용 학생 리스트 가져오기
 * @param classId
 * @returns
 */
export const getLmsStudnet = async (classId: string) => {
  try {
    const result = await instance.get<ApiResponse<LmsStudentProps[]>>(
      `/v1/lms/${classId}/todo/student`
    );
    return result.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일 등록 할일 추가
 * @param classId
 * @param params
 * @returns
 */
export const postTodoAssign = async (classId: string, params: any) => {
  console.log('params', params);
  try {
    const result = await instance.post<ApiResponse<any[]>>(
      `/v1/lms/${classId}/todo/assign`,
      params
    );
    return result.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일별 할일 현황 목록 가져오기
 * @param classId
 * @param page
 * @param pageSize
 * @param toDoStatus
 * @param toDoType
 * @param orderBy
 * @param searchOption
 * @param searchValue
 * @returns
 */
export const getTodoStateByTodo = async (
  classId: string,
  page: number,
  pageSize: number,
  toDoStatus: string,
  toDoType: string,
  orderBy: string,
  searchOption?: string,
  searchValue?: string
) => {
  try {
    const params = {
      page: page,
      pageSize: pageSize,
      category: 'BY_TODO',
      toDoStatus: toDoStatus,
      toDoType: toDoType,
      orderBy: orderBy,
      [searchOption]: searchValue,
    };
    const result = await instance.get<
      ApiResponse<PaginationResponse<LmsTodoStatusByTodoType[]>>
    >(`/v1/lms/${classId}/todo/state`, { params });
    // if (result.status === 401) window.location.href = '/';
    return result.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 학생별 할일 현황 목록 가져오기
 * @param classId
 * @param page
 * @param pageSize
 * @param toDoStatus
 * @param toDoType
 * @param orderBy
 * @param searchOption
 * @param searchValue
 * @returns
 */
export const getTodoStateById = async (
  classId: string,
  page: number,
  pageSize: number,
  toDoStatus: string,
  toDoType: string,
  orderBy: string,
  searchOption?: string,
  searchValue?: string
) => {
  try {
    const params = {
      page: page,
      pageSize: pageSize,
      category: 'BY_ID',
      toDoStatus: toDoStatus,
      toDoType: toDoType,
      orderBy: orderBy,
      [searchOption]: searchValue,
    };
    const result = await instance.get<
      ApiResponse<PaginationResponse<LmsTodoStatusByIdType[]>>
    >(`/v1/lms/${classId}/todo/state`, { params });
    return result.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일 현황 할일 삭제
 * @param classId
 * @param txId 할일 아이디
 * @returns
 */
export const deleteTodoAssign = async (
  classId: string,
  txId: string,
  todoStatus: string
) => {
  try {
    const result = await instance.delete<ApiResponse<any>>(
      `/v1/lms/${classId}/todo/assign/${txId}`,
      { params: { todoStatus } }
    );
    return result.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 할일별 할일 현황 상세보기
 * @param classId
 * @param toDoType
 * @param toDoStatus
 * @param txId
 * @returns
 */
export const getTodoStateDetailByTodo = async (
  classId: string,
  toDoType: string,
  toDoStatus: string,
  txId: string,
  search?: {
    searchOption: string;
    searchValue: string;
  }
) => {
  try {
    const params = {
      toDoType,
      toDoStatus,
      category: 'BY_TODO',
      [search?.searchOption]: search?.searchValue,
    };
    const result = await instance.get<ApiResponse<assignedLmsTodosByTodo[]>>(
      `/v1/lms/${classId}/todo/state/${txId}`,
      { params }
    );
    return result.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 학생별 할일 현황 상세보기
 * @param classId
 * @param toDoType
 * @param toDoStatus
 * @param id
 * @returns
 */
export const getTodoStateDetailById = async (
  classId: string,
  toDoType: string,
  toDoStatus: string,
  id: number
) => {
  try {
    const params = {
      toDoType,
      toDoStatus,
      category: 'BY_ID',
    };
    const result = await instance.get<ApiResponse<assignedLmsTodosById[]>>(
      `/v1/lms/${classId}/todo/state/${id}`,
      { params }
    );
    return result.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

export const putTodoAssign = async (
  classId: string,
  txId: string,
  // studentIds: number[],
  fromDate: Date,
  toDate: Date,
  todoStatus: ToDoStatusEnum
) => {
  try {
    const params = {
      fromDate,
      toDate,
      todoStatus,
    };
    const result = await instance.put<ApiResponse<any[]>>(
      `/v1/lms/${classId}/todo/assign/${txId}`,
      params
    );
    return result.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 수업하기 콘텐츠 링크 가져오기
 * @param classId
 * @param contentId
 * @returns
 */
export const getContentsDatas = async (classId: string, contentId: string) => {
  try {
    const path = `/v1/class/${classId}/contents/${contentId}`;
    const rtn = await instance.get(path);
    return rtn.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 학생 관리 학생 리스트
 * @param classId
 * @param status
 * @param page
 * @param pageSize
 * @returns
 */
export const getStudentList = async (
  classId: string,
  status: string,
  page?: number,
  pageSize?: number
) => {
  try {
    const path = `/v1/lms/${classId}/student`;
    const rtn = await instance.get<
      ApiResponse<PaginationResponse<studentType[]>>
    >(path, { params: { page, pageSize, status } });
    return rtn.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 학생 관리 학생 상태 업데이트 [ 활성화, 비활성화, 대기 ]
 * @param classId
 * @param studentIds
 * @param status
 * @returns
 */
export const putStudentStatus = async (
  classId: string,
  studentIds: number[],
  status: string
) => {
  try {
    const path = `/v1/lms/${classId}/student/status`;
    const rtn = await instance.put(path, { studentIds, status });
    return rtn.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 학생 관리 학생 삭제
 * @param classId
 * @param ids
 * @returns
 */
export const deleteStudent = async (classId: string, ids: number[]) => {
  try {
    const path = `/v1/lms/${classId}/student`;
    const rtn = await instance.delete(path, { data: { ids } });
    return rtn.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 학생 관리 반 리스트
 * @param classId
 * @returns
 */
export const getClassGroupList = async (classId: string) => {
  try {
    const params = {
      page: 1,
      pageSize: 1000,
    };
    const path = `/v1/lms/${classId}/class-group`;
    const rtn = await instance.get<
      ApiResponse<PaginationResponse<LmsClassGroupType[]>>
    >(path, {
      params,
    });
    return rtn.data.data;
  } catch (e) {
    console.error(getErrorMessages(e));
  }
};

/**
 * lms 학생 관리 반 추가
 * @param classId
 * @param params
 * @returns
 */
export const axiosPostClass = async (
  classId: string,
  params: {
    name: string;
    description: string;
  }
) => {
  const result = await instance.post(`/v1/lms/${classId}/class-group`, params);
  return result.data;
};

/**
 * lms 학생 관리 반 삭제
 * @param classId
 * @param id
 * @returns
 */
export const axiosDeleteClass = async (classId: string, id: number) => {
  const result = await instance.delete(`/v1/lms/${classId}/class-group/${id}`);
  return result.data;
};

/**
 * lms 학생 관리 반 수정
 * @param classId
 * @param id
 * @param params
 * @returns
 */
export const axiosPutClass = async (
  classId: string,
  id: number,
  params: { name: string; description: string }
) => {
  const result = await instance.put(
    `/v1/lms/${classId}/class-group/${id}`,
    params
  );
  return result.data;
};

/**
 * lms 학생 관리 반 배정
 * @param classId
 * @param params
 * @returns
 */
export const putClass = async (
  classId: string,
  params: {
    classGroupId: number;
    studentIds: number[];
  }
) => {
  const result = await instance.put(
    `/v1/lms/${classId}/student/assign-class-group`,
    params
  );
  return result.data;
};

/**
 * lms 학생 관리 반 해제
 * @param classId
 * @param studentId
 * @param classGroupId
 * @returns
 */
export const deleteClassForStudent = async (
  classId: string,
  studentId: number,
  classGroupId: number
) => {
  const result = await instance.delete(
    `/v1/lms/${classId}/student/${studentId}/assign-class-group/${classGroupId}`
  );
  return result;
};

/**
 * lms 학생관리 학생 등록
 * @param classId
 * @param params
 * @returns
 */
export const postStudent = async (classId: string, params: LmsStudentType) => {
  const result = await instance.post(`/v1/lms/${classId}/student`, params);
  return result.data;
};

/**
 * lms 학생관리 학생 uid 중복체크
 * @param params
 * @returns
 */
export const getUid_check = async (params: { uid: string }) => {
  const result = await instance.get(`v1/user/uid-check?uid=${params.uid}`);
  return result.data.data as { existUser: boolean };
};

/**
 * lms 인증제 내역 학생 목록
 * @param classId
 * @returns
 */
export const getRecitationResult = async (
  classId: string,
  params: {
    page: number;
    pageSize: number;
  },
  searchOption?: string,
  searchValue?: string
) => {
  const result = await instance.get<
    ApiResponse<PaginationResponse<recitationResultType[]>>
  >(`/v1/recitation/summaries`, {
    params: { ...params, classId, [searchOption]: searchValue },
  });
  return result.data.data;
};

export const getRecitationResultDetail = async (
  classId: string,
  studentId: string
) => {
  const result = await instance.get<ApiResponse<recitationLevelType[]>>(
    `/v1/lms/${classId}/recitation-result/${studentId}`
  );
  return result.data.data;
};

export const postExchangeTicket = async (
  classId: string,
  params: {
    fromQuantity: number;
  }
) => {
  const result = await instance.post(
    `/v1/lms/${classId}/todo/voucher/exchange-recitation`,
    params
  );
  return result.data;
};
