import BannerAward2024 from '../img/banner/Award2024.png';
import icon1 from '../img/event/icon1.png';
import icon2 from '../img/event/icon2.png';
import icon3 from '../img/event/icon3.png';
import Title from '../img/event/25_04_title.png';
import Sprout from '../img/event/25_04_sprout.png';
import Conference from '../img/event/25_04_online.png';
import React, { ReactNode } from 'react';
import cursor from '../img/event/cursor.svg';
import Polygon from './Polygon';
const HomeschoolEvent_25_4 = () => {
  return (
    <>
      {/* 배너 영역 */}
      <img src={BannerAward2024} alt="Award2024" className="m-auto w-full" />

      {/* 프로모션 섹션 1 */}
      <div className="flex items-center justify-center bg-[#E2FDCD]">
        <div className="m-auto flex max-w-[720px] flex-col gap-10 px-5 py-20">
          {/* 상단 타이틀 섹션 */}
          <section className="flex flex-col items-center justify-center gap-5">
            <div className="flex items-center justify-center">
              <img src={Sprout} alt="Sprout" className="w-20" />
            </div>
            <div className="text-center text-xl font-semibold text-[#007D2C]">
              4월, 습관을 완성하는 최적의 타이밍!
            </div>
            <div className="m-auto flex max-w-[480px]">
              <img src={Title} alt="영어&신앙 습관을 위한 4가지 힘을 기르자!" />
            </div>
          </section>

          {/* 말풍선 섹션 */}
          <div className="flex flex-col items-center justify-center gap-5 text-lg font-bold">
            <ImogiChildrenBox
              icon={icon1}
              text="공부의 지속력이 부족한 우리 아이, 어떻게 꾸준히 공부하는 습관을 들이죠?"
              bgColor="bg-[#47B815]"
              textColor="text-white"
              iconBgColor="bg-[#E2FDCD]"
            >
              <>
                “공부의 <span className="text-[#FFD04F]">지속력</span>이 부족한
                우리 아이,
                <br className="hidden sm:block" /> 어떻게 꾸준히 공부하는 습관을
                들이죠?“
              </>
            </ImogiChildrenBox>
            <ImogiChildrenBox
              icon={icon2}
              text="영어 공부와 말씀 읽기만 시작하면 흐려지는 우리 아이 집중력과 몰입력! 어떻게 해결해야 할까요?"
              bgColor="bg-[#47B815]"
              textColor="text-white"
              iconBgColor="bg-[#E2FDCD]"
              align="right"
            >
              <>
                “영어 공부와 말씀 읽기만 시작하면 흐려지는
                <br className="hidden sm:block" />
                우리 아이 <span className="text-[#FFD04F]">집중력</span>과{' '}
                <span className="text-[#FFD04F]">몰입력</span>! 어떻게 해결해야
                할까요?”
              </>
            </ImogiChildrenBox>
            <ImogiChildrenBox
              icon={icon3}
              text="왜 배운 영어를 활용하지 못할까요? 우리 아이 공부 응용력을 깨우쳐 주고 싶어요!"
              bgColor="bg-[#47B815]"
              textColor="text-white"
              iconBgColor="bg-[#E2FDCD]"
            >
              <>
                “왜 배운 영어를 활용하지 못할까요?
                <br className="hidden sm:block" />
                우리 아이 공부<span className="text-[#FFD04F]"> 응용력</span>을
                깨우쳐 주고 싶어요!”
              </>
            </ImogiChildrenBox>
          </div>

          {/* 설명회 섹션 */}
          <section className="mt-10 text-center">
            <div className="text-[26px] font-extrabold sm:text-4xl">
              온라인 설명회 시청하고
              <br />
              4가지 힘의 비밀을 알아가세요!
            </div>
          </section>

          <div className="relative m-auto w-full max-w-[480px]">
            <img src={Conference} alt="conference_thumbnail" />
          </div>
          <DoubleLineButton
            onClick={() => {
              window.open('https://forms.gle/hYg6Q4YQ4tMcBFb58');
            }}
          >
            <div className="p-3 text-xl font-bold sm:p-5 sm:text-4xl">
              무료 설명회 신청하기
            </div>
          </DoubleLineButton>
        </div>
      </div>

      {/* 프로모션 섹션 2 */}
      <div className="flex items-center justify-center bg-[#71CE46]">
        <div className="flex max-w-[720px] flex-col gap-10 px-5 py-20">
          <section className="flex flex-col gap-3">
            {/* 타이틀 */}
            <div className="text-center text-3xl font-extrabold text-white sm:text-4xl">
              환경과 조건 상관없이
              <br />
              몰입하는 공부 습관으로
              <br />
              영어와 신앙 한 번에 꽉 잡기!
            </div>
          </section>

          {/* 박스 */}
          <section className="flex w-full flex-col gap-5">
            <TextBox>
              <div className="font-bold text-black">
                집에서 엄마와 함께 영어와 신앙의
                <br />
                <span className="text-[#32A900]">
                  지속력, 집중력, 몰입력, 응용력 UP!
                </span>
                <br />
              </div>
            </TextBox>
            <TextBox>
              <div className="font-bold text-black">
                정철 교수법&성경에 기반한 교재로
                <br />
                <span className="text-[#32A900]">
                  믿고 시작하는 영어&신앙 올인원 솔루션!
                </span>
              </div>
            </TextBox>
            <TextBox>
              <div className="font-bold text-black">
                <span className="text-[#32A900]">홈스쿨</span>로 시작해{' '}
                <span className="text-[#32A900]">공부방</span>으로 무한 확장되는
                <br />
                영어 교육 선교의 비전!
              </div>
            </TextBox>
          </section>

          <div className="text-center text-xl font-bold text-white sm:text-2xl">
            우리 아이 영어와 신앙 습관에 꼭 필요한
            <br />
            <span className="bg-[#FFD973] text-[#47B815]">4가지 힘</span>을
            기르는 방법!
            <br />
            <span className="bg-[#FFD973] text-[#47B815]">
              [젭스홈스쿨 4월 한정 혜택]
            </span>
            으로
            <br /> 그 비밀을 선물합니다!⭐️
          </div>

          <DoubleWhiteLineButton
            onClick={() => {
              window.open('https://forms.gle/hYg6Q4YQ4tMcBFb58');
            }}
          >
            <div className="p-3 text-xl font-bold sm:p-5 sm:text-4xl">
              4월 홈스쿨 등록 혜택 받기
            </div>
          </DoubleWhiteLineButton>
        </div>
      </div>
    </>
  );
};

interface ImogiChildBoxProps {
  children: ReactNode;
  icon: string;
  text: string;
  align?: 'left' | 'right';
  bgColor?: string;
  textColor?: string;
  iconBgColor?: string;
}

const ImogiChildrenBox = ({
  children,
  icon,
  text,
  align = 'left',
  bgColor = 'bg-white/65',
  textColor = 'text-black',
  iconBgColor = 'bg-white/65',
}: ImogiChildBoxProps) => {
  const margin = '16%'; //크기에 따른 조정 필요
  const isRightAligned = align === 'right';

  return (
    <div className="relative w-full">
      <div
        className={`flex items-center gap-2 shadow-inner shadow-green-800 ${
          isRightAligned ? 'justify-end' : 'justify-start'
        } ${bgColor} rounded-3xl px-3 py-2 sm:px-5 sm:py-3 ${textColor} text-xs sm:text-sm`}
        style={{
          textAlign: align,
          boxSizing: 'border-box',
          marginLeft: isRightAligned ? margin : 0,
          marginRight: isRightAligned ? 0 : margin,
        }}
      >
        <div style={{ order: isRightAligned ? 1 : 2 }}>{children}</div>
        <img
          src={icon}
          alt={text}
          className={`h-10 w-10 p-2 sm:h-14 sm:w-14 ${iconBgColor} rounded-full`}
          style={{ order: isRightAligned ? 2 : 1 }}
        />
      </div>
      <div
        className={`absolute ${
          isRightAligned
            ? 'bottom-0 right-0 -translate-x-10 translate-y-6'
            : 'bottom-0 left-0 translate-x-10 translate-y-6'
        }`}
      >
        <Polygon color="#47B815" />
      </div>
    </div>
  );
};

const DoubleLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => {
  return (
    <div
      className={`cursor-pointer rounded-[24px] border-[2px] border-[#2b492a] p-1 sm:rounded-[41px] sm:border-[3px] sm:p-[7px]`}
      style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
      onClick={onClick}
    >
      <div
        className={`rounded-[17px] bg-black text-center text-white hover:bg-black/50 sm:rounded-[30px]`}
      >
        {children}
      </div>
    </div>
  );
};

const DoubleWhiteLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <div
    className="relative cursor-pointer rounded-[24px] border-[2px] border-white/70 p-1 sm:rounded-[41px] sm:border-[3px] sm:p-[7px]"
    style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    onClick={onClick}
  >
    <div
      className={`rounded-[17px] border-4 border-white bg-[#ffb218] text-center text-black hover:bg-[#e6a016] sm:rounded-[30px]`} // hover : #ffb218의 90%
    >
      {children}
    </div>
    <div className="absolute right-[5%] top-[65%] h-10 w-10 sm:h-14 sm:w-14">
      <img src={cursor} alt="cursor" className="h-10 w-10 sm:h-14 sm:w-14" />
    </div>
  </div>
);

const TextBox = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className="w-full rounded-[40px] bg-white px-7 py-5 text-center text-base font-bold sm:px-10 sm:py-7 sm:text-2xl"
      style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    >
      {children}
    </div>
  );
};

export default HomeschoolEvent_25_4;
