import React from 'react';
import GodDealWeekSection from '../../../../../components/course/churchschool/event/GodDealWeekSection';
import WmwSection8b from '../../../../../components/course/churchschool/wmw/WmwSection8b';
import JepsplSection from '../../../../../components/course/churchschool/JepsplSection';
import WarningSection from '../../../../../components/course/churchschool/WarningSection';
import WmwSection14 from '../../../../../components/course/churchschool/wmw/WmwSection14';

const BoostupJSBookMain = () => {
  return (
    <>
      <GodDealWeekSection />
      <WmwSection8b />
      <WarningSection />
      <JepsplSection />
      <WmwSection14 />
    </>
  );
};

export default BoostupJSBookMain;
