import React from 'react';
import GodDealWeekSection from '../../../../../components/course/churchschool/event/GodDealWeekSection';
import WmwSection8a from '../../../../../components/course/churchschool/wmw/WmwSection8a';
import JepsplSection from '../../../../../components/course/churchschool/JepsplSection';
import WarningSection from '../../../../../components/course/churchschool/WarningSection';
import WmwSection14 from '../../../../../components/course/churchschool/wmw/WmwSection14';

const BoostupWmwBookMain = () => {
  return (
    <>
      <GodDealWeekSection />
      <WmwSection8a />
      <WarningSection />
      <JepsplSection />
      <WmwSection14 />
    </>
  );
};

export default BoostupWmwBookMain;
