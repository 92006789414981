import React from 'react';
import RecitationResult from '../../../components/recitation/RecitationResult';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'antd';
import { openNewWindow } from '../../../api/utils/util-func';
import TextWinds from '../../../components/common/TextWinds';

const StudentCertificatioinPage = () => {
  const userid = localStorage.getItem('userId');
  if (!userid) throw new Error('userid is null');

  return (
    <div className=" bg-[#f3f0e7] py-16">
      <div className="w-full max-w-[1176px] m-auto px-5">
        <header className="flex gap-2 items-center">
          <TextWinds type={'title_h1'} children={'암송인증제 합격 현황'} />
          <Tooltip title="🗣️ 암송인증제란?">
            <InformationCircleIcon
              className="w-6 h-6 text-gray-500 cursor-pointer"
              onClick={() =>
                openNewWindow(
                  'https://alluring-viper-412.notion.site/58dc51de03a848889ee3904f3e941271?pvs=4'
                )
              }
            />
          </Tooltip>
        </header>
        <div className="mt-10">
          <RecitationResult studentUid={userid} />
        </div>
      </div>
    </div>
  );
};

export default StudentCertificatioinPage;
