import React from 'react';
import HFHJSection1 from './HFHJSection1';
import HFHJSection2 from './HFHJSection2';
import HFHJSection3 from './HFHJSection3';
import HFHJSection4 from './HFHJSection4';
import HFHJSection5 from './HFHJSection5';
import HFHJSection6 from './HFHJSection6';
import HFHJSection7 from './HFHJSection7';
import HFHJSection8a from './HFHJSection8a';
import HFHJSection8b from './HFHJSection8b';
import HFHJSection9 from './HFHJSection9';
import HFHJSection10 from './HFHJSection10';
import HFHJSection11 from './HFHJSection11';
import HFHJSection12 from './HFHJSection12';
import HFHJSection13 from './HFHJSection13';
import HFHJSection14 from './HFHJSection14';
import GodDealWeekSection from '../event/GodDealWeekSection';
const HFHJMain = () => {
  return (
    <div className="flex flex-col">
      {/* 어린이 전도에 최적화된 주일학교 프로그램이 있다? */}
      <HFHJSection1 />

      <HFHJSection2 />

      {/* 이런 분들이라면, 반드시 도입하세요! */}
      <HFHJSection3 />

      {/* 왜 HFHJ인지 알 수 있는 동역자들의 수 많은 실제후기! */}
      <HFHJSection4 />

      {/* 유튜브 영상, 지금 바로 도입 문의하기 버튼 */}
      <HFHJSection5 />

      {/* 정답은, Hello, Father! Hello, Jesus! */}
      <HFHJSection6 />

      {/* HFHJ는 이렇게 구성되었어요! 버튼, HFHJ 커리큘럼 과정 */}
      <HFHJSection7 />

      {/* 교재 구성 */}
      <HFHJSection8a />
      <HFHJSection8b />

      {/* 갓딜링크 이벤트 */}
      <GodDealWeekSection />

      {/* HFHJ, 어떻게 도입할까요? */}
      <HFHJSection9 />

      {/* 프로그램 구성&가격 */}
      <HFHJSection10 />

      {/* 성공하는 교회와 아닌 교회의 차이, 젭스플이란? */}
      <HFHJSection11 />

      {/* HFHJ는 [교사대학] 수료 후 도입 가능해요!   */}
      <HFHJSection12 />

      {/* 교사 코스는 이렇게 구성되어 있어요! */}
      <HFHJSection13 />

      {/* 환불 규정 */}
      <HFHJSection14 />
    </div>
  );
};

export default HFHJMain;
