import React from 'react';
import {
  createCookie,
  CookieTypeJebsAiRecitationResult,
  CookieKey,
} from '../../../../util/cookie';

const JebsAiRecitationResult = () => {
  // const size = {
  //   largest: '1440px',
  //   large: '768px',
  //   small: '350px',
  // };

  const { getCookie: getJebsAiRecitationResultCookie } =
    createCookie<CookieTypeJebsAiRecitationResult>(
      CookieKey.JEBS_AI_RECITATION_RESULT,
    );

  const { recitation } = getJebsAiRecitationResultCookie();

  let resultData = {
    imgSrc: '',
    imgAlt: '',
    title: '',
    description: '',
  };

  switch (recitation.result) {
    case 'PASS':
      resultData = {
        imgSrc: `${process.env.PUBLIC_URL}/images/student/jebs-ai-recitation/result/pass_v2.svg`,
        imgAlt: 'Congratulations',
        title: 'Congratulations!',
        description: '합격을 축하합니다!',
      };
      break;
    case 'FAIL':
    default:
      resultData = {
        imgSrc: `${process.env.PUBLIC_URL}/images/student/jebs-ai-recitation/result/fail_v2.svg`,
        imgAlt: 'No Pass',
        title: 'No Pass!',
        description: '불합격입니다. 다음 기회에 만나요!',
      };
      break;
  }

  const handleExit = () => {
    window.close();
  };

  const ExitButton = ({ className }: { className: string }) => {
    return (
      <div className={`${className} w-full max-w-[451px]`} onClick={handleExit}>
        <div className="inline-flex h-[79px] w-full max-w-[451px] cursor-pointer items-center justify-center gap-2 rounded-[100px] bg-[#b1b1b1] px-10 py-4 shadow-[0px_4px_10px_0px_rgba(140,103,55,0.16),inset_0px_4px_4px_0px_rgba(255,255,255,0.32)] hover:brightness-90 hover:drop-shadow-[2px_2px_2px_rgba(0,0,0,0.2)]">
          <div className="relative justify-center text-center font-['Pretendard'] text-[32px] font-bold capitalize leading-[38.40px] tracking-wide text-white">
            나가기
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* 대지 */}
      <div className="flex h-screen w-screen flex-col items-center justify-center bg-[#ffeeb3]">
        {/* 헤더 - 젭스로고, 레벨북*/}
        <div className="flex h-full w-full flex-col items-center justify-center gap-5 p-5 md:max-w-[1180px] md:gap-10 md:p-10">
          <div className="flex w-full justify-between">
            <img
              src={`${process.env.PUBLIC_URL}/images/student/jebs-ai-recitation/result/JEBS.png`}
              alt="jebs logo"
              className="h-auto w-10 object-contain md:w-16"
            />
            <div className="grid grid-cols-2">
              <div className="inline-flex items-center justify-center gap-2 rounded-l-lg bg-[#ff9103] px-1 py-2 md:px-2 md:py-3">
                <img
                  src={`${process.env.PUBLIC_URL}/images/student/jebs-ai-recitation/result/Level.svg`}
                  alt="level icon"
                  className="h-4 w-4 md:h-6 md:w-6"
                />
                <div className="relative justify-center text-center text-xl font-semibold capitalize leading-normal tracking-wide text-white md:text-2xl">
                  {recitation.level}
                </div>
              </div>
              <div className="inline-flex items-center justify-center gap-4 rounded-r-lg border-2 border-[#ff9103] bg-white py-2 pl-3 pr-4 md:py-3 md:pl-6 md:pr-6">
                <div className="relative justify-center text-center text-xl font-semibold capitalize leading-normal tracking-wide text-[#ff9103] md:text-2xl">
                  {recitation.book}
                </div>
              </div>
            </div>
          </div>

          {/* 흰색 콘텐츠 영역 */}
          <div className="relative inline-flex h-full w-full flex-col items-center justify-center gap-5 overflow-auto rounded-[20px] border-2 border-[#f5c92a] bg-white p-5 md:gap-10 xl:h-[662px]">
            {/* <div className="block md:hidden bg-red-500">sm</div> */}
            {/* <div className="hidden md:block lg:hidden bg-blue-500">md</div> */}
            {/* <div className="hidden lg:block xl:hidden bg-green-500">lg</div> */}
            {/* <div className="hidden xl:block bg-yellow-500">xl</div> */}

            <div className="text-center text-3xl font-extrabold text-[#ff9103] md:text-6xl">
              {resultData.title}
            </div>
            <div className="text-center text-xl font-bold text-black md:text-4xl">
              {resultData.description}
            </div>
            <img
              src={resultData.imgSrc}
              alt={resultData.imgAlt}
              className={`aspect-auto w-full max-w-[200px] md:max-w-[300px]`}
            />
            <ExitButton className="hidden xl:block" />
          </div>

          <ExitButton className="block xl:hidden" />
        </div>
      </div>
    </>
  );
};

export default JebsAiRecitationResult;
