import BannerAward2024 from '../img/banner/Award2024.png';
import title01 from '../img/event/25_03_title_1.png';
import title02 from '../img/event/25_03_title_2.png';
import titleFlower from '../img/event/25_03_title_flower.png';
import youtube from '../img/event/25_03_youtube.png';
import flowerImogi from '../img/event/25_03_flower_imogi.png';
import bgFlower01 from '../img/event/25_03_bg_flower_1.png';
import bgFlower02 from '../img/event/25_03_bg_flower_2.png';
import bgFlower03 from '../img/event/25_03_bg_flower_3.png';
import bgFlower04 from '../img/event/25_03_bg_flower_4.png';
import icon1 from '../img/event/icon1.png';
import icon2 from '../img/event/icon2.png';
import icon3 from '../img/event/icon3.png';
import React, { ReactNode } from 'react';
import cursor from '../img/event/cursor.svg';

const HomeschoolEvent_25_3 = () => {
  return (
    <>
      {/* 배너 영역 */}
      <img src={BannerAward2024} alt="Award2024" className="w-full m-auto" />

      {/* 프로모션 섹션 1 */}
      <div className="bg-[#fbe2df] flex justify-center items-center relative">
        <img
          src={bgFlower01}
          alt="bg_flower_1"
          className="absolute top-0 left-0 w-[30%] "
        />
        <img
          src={bgFlower02}
          alt="bg_flower_2"
          className="absolute top-72 right-0 h-[10%] "
        />
        <img
          src={bgFlower04}
          alt="bg_flower_3"
          className="absolute bottom-[550px] right-0 h-[10%] hidden sm:block "
        />
        <img
          src={bgFlower03}
          alt="bg_flower_4"
          className="absolute bottom-10 left-0 h-[20%] "
        />
        <div className="flex flex-col gap-10 py-20 px-5 max-w-[720px] m-auto">
          {/* 타이틀 */}
          <div>
            <div className="text-2xl font-bold text-[#ff5160] text-center">
              <div className="flex justify-center items-center">
                <span className="font-extrabold">젭스홈스쿨</span>로 웃음꽃
                활짝!
              </div>
              <div>영어도 신앙도 활짝!</div>
            </div>
            <div className="flex flex-col max-w-[480px] mt-5 sm:mt-10 m-auto relative">
              <img
                src={titleFlower}
                alt="flower"
                className="absolute top-[-20px] sm:top-[-40px] right-[-15px] sm:right-[-30px] w-[15%] sm:w-[18%]"
              />
              <img
                src={title01}
                alt="3월 봄맞이 혜택으로"
                className="relative"
              />
              <img src={title02} alt="모두가 활짝 웃어봄" />
            </div>
          </div>

          {/* 컨텐츠 */}
          <div className="flex flex-col justify-center items-center gap-5 font-bold text-lg">
            <ImogiChildrenBox
              icon={icon1}
              text="“새 학기만 되면 공부하기 싫다고
              울상이 되는 아이 때문에 정말 걱정이에요..! “"
              bgColor="bg-[#ffd9d7]"
              textColor="text-[#e25d6c]"
              iconBgColor="bg-[#fbafb3]"
              shadow="inset 0px 0px 20px 5px rgba(255,167,170,1.00)"
            >
              <>
                “새 학기만 되면 공부하기 싫다고
                <br className="hidden sm:block" /> 울상이 되는 아이 때문에 정말
                걱정이에요..! “
              </>
            </ImogiChildrenBox>

            <ImogiChildrenBox
              icon={icon3}
              text="“워킹맘이라 늘 아이 교육 때문에 걱정이 많은데,
              이젠 걱정없이 아이와 함께 활짝 웃고 싶어요! “"
              bgColor="bg-[#ffe9e7]"
              textColor="text-[#e25d6c]"
              iconBgColor="bg-[#ffd1c8]"
              align="right"
              shadow="inset 0px 0px 20px 5px rgba(253,179,170,1.00)"
            >
              <div className="text-[11px] sm:text-base">
                “워킹맘이라 늘 아이 교육 때문에 걱정이 많은데,
                <br className="hidden sm:block" />
                이젠 걱정없이 아이와 함께 활짝 웃고 싶어요! “
              </div>
            </ImogiChildrenBox>
            <ImogiChildrenBox
              icon={icon2}
              text="“영어와 신앙 두 가지 모두 활짝 만개할 봄날을
              기다리고 있습니다. 언젠간 꽃이 피겠죠?“"
              bgColor="bg-[#ffd9d7]"
              textColor="text-[#e25d6c]"
              iconBgColor="bg-[#fbafb3]"
              shadow="inset 0px 0px 20px 5px rgba(255,167,170,1.00)"
            >
              <>
                “영어와 신앙 두 가지 모두 활짝 만개할 봄날을
                <br className="hidden sm:block" />
                기다리고 있습니다. 언젠간 꽃이 피겠죠?“
              </>
            </ImogiChildrenBox>
          </div>

          <section className="text-center">
            <div className="text-lg sm:text-2xl font-semibold">
              활짝 피어날 3월을 기대하며 알차게 준비한
            </div>
            <div
              className="text-[26px] sm:text-4xl font-extrabold
            underline decoration-[#FFD973] decoration-[10px] underline-offset-[-2px] [box-decoration-break:clone] [text-decoration-skip-ink:none]"
            >
              젭스홈스쿨 3월 온라인 설명회
            </div>
          </section>

          <div className="relative w-full max-w-[480px] m-auto">
            <img src={youtube} alt="conference_thumbnail" />
          </div>

          <DoubleLineButton
            onClick={() => {
              window.open('https://forms.gle/hYg6Q4YQ4tMcBFb58');
            }}
          >
            <div className="text-xl sm:text-4xl font-bold p-3 sm:p-5">
              온라인 설명회 신청하기
            </div>
          </DoubleLineButton>
        </div>
      </div>

      {/* 프로모션 섹션 2 */}
      <div className="bg-[#ffbebf] flex justify-center items-center">
        <div className="flex flex-col gap-10 px-5 py-20 max-w-[720px] ">
          <section className="flex flex-col gap-3">
            {/* 타이틀 */}
            <div className="font-extrabold text-[28px] sm:text-4xl text-[#ff5d6d] text-center">
              3월엔 엄마도 아이도
              <br />
              웃음꽃이 활짝 피어날 거예요!
            </div>

            {/* 텍스트 */}
            <div className="text-base sm:text-xl font-medium text-center text-black">
              워킹맘도, 학원 가기 싫은 아이도 함께
              <br />
              웃음꽃이 활짝 피어나는 봄이 되도록
              <br />
              <span className="bg-[#ff9ba5]">젭스홈스쿨만의 3월 특별 혜택</span>
              을 선물합니다!
            </div>
          </section>

          {/* 박스 */}
          <section className="flex flex-col gap-5 w-full">
            <TextBox>
              <div className="text-black font-bold">
                집에서{' '}
                <span className="text-[#ff5d6d]">
                  엄마와 함께하는 재미 위주의
                </span>
                <br />
                <span className="text-[#ff5d6d]">학습</span>으로 영어와 신앙을
                교육!
              </div>
            </TextBox>
            <TextBox>
              <div className="text-black font-bold">
                정철 교수법&성경에 기반한 교재로
                <br />
                <span className="text-[#ff5d6d]">
                  믿고 시작하는 영어&신앙 올인원 솔루션
                </span>
              </div>
            </TextBox>
            <TextBox>
              <div className="text-black font-bold">
                우리 아이와 함께하는
                <span className="text-[#ff5d6d]">홈스쿨</span>로 시작해
                <br />
                <span className="text-[#ff5d6d]">공부방</span>으로도 언제든 확장
                가능!
              </div>
            </TextBox>
          </section>

          <div className="text-xl sm:text-2xl font-bold text-[#323232] text-center">
            ⭐️3월 봄맞이 특별 혜택으로⭐️
            <br /> 엄마도 아이도, 우리 아이 영어&신앙도!
            <br />
            <div className="flex justify-center items-center">
              웃음꽃 활짝 피어나 봄!
              <img src={flowerImogi} alt="flower" className="w-7 h-7" />
            </div>
          </div>

          <DoubleWhiteLineButton
            onClick={() => {
              window.open('https://forms.gle/hYg6Q4YQ4tMcBFb58');
            }}
          >
            <div className="text-xl sm:text-4xl font-bold p-3 sm:p-5">
              3월 홈스쿨 등록 혜택 받기
            </div>
          </DoubleWhiteLineButton>
        </div>
      </div>
    </>
  );
};

interface ImogiChildBoxProps {
  children: ReactNode;
  icon: string;
  text: string;
  align?: 'left' | 'right';
  bgColor?: string;
  textColor?: string;
  iconBgColor?: string;
  shadow?: string;
}

const ImogiChildrenBox = ({
  children,
  icon,
  text,
  align = 'left',
  bgColor = 'bg-white/65',
  textColor = 'text-black',
  iconBgColor = 'bg-white/65',
  shadow = 'shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]',
}: ImogiChildBoxProps) => {
  // const margin = '18%';
  const margin = '15%';
  const isRightAligned = align === 'right';

  return (
    <div className={`w-full`}>
      <div
        className={`flex items-center gap-2  ${
          isRightAligned ? 'justify-end' : 'justify-start'
        }
        ${bgColor} rounded-full p-3 sm:p-5 
        ${textColor} text-xs sm:text-base
        `}
        style={{
          textAlign: align,
          boxSizing: 'border-box',
          marginLeft: isRightAligned ? margin : 0,
          marginRight: isRightAligned ? 0 : margin,
          boxShadow: shadow,
        }}
      >
        <div style={{ order: isRightAligned ? 1 : 2 }}>{children}</div>
        <img
          src={icon}
          alt={text}
          className={`w-10 sm:w-14 h-10 sm:h-14 p-2 ${iconBgColor} rounded-full`}
          style={{ order: isRightAligned ? 2 : 1 }}
        />
      </div>
    </div>
  );
};

const DoubleLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => {
  return (
    <div
      className={`border-[2px] sm:border-[3px] border-[#ff7c9d] rounded-[24px] sm:rounded-[41px] p-1 sm:p-[7px] cursor-pointer relative`}
      style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
      onClick={onClick}
    >
      <div
        className={`rounded-[17px] sm:rounded-[30px] text-center bg-[#ff7c9d] hover:bg-[#e67089] text-white`} // hover : #2b492a의 90%
      >
        {children}
      </div>
    </div>
  );
};

const DoubleWhiteLineButton = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <div
    className="border-[2px] sm:border-[3px] border-white/70 rounded-[24px] sm:rounded-[41px] p-1 sm:p-[7px] cursor-pointer relative"
    style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    onClick={onClick}
  >
    <div
      className={`rounded-[17px] sm:rounded-[30px] text-center bg-[#ff5d6d] border-4 border-white hover:bg-[#e65462] text-black`} // hover : #ff5d6d의 90%
    >
      {children}
    </div>
    <div className="absolute top-[65%] right-[5%] w-10 sm:w-14 h-10 sm:h-14">
      <img src={cursor} alt="cursor" className="w-10 sm:w-14 h-10 sm:h-14" />
    </div>
  </div>
);

const TextBox = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className="rounded-3xl bg-white text-center font-bold text-base sm:text-2xl w-full sm:px-10 px-7 sm:py-7 py-5"
      style={{ boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)' }}
    >
      {children}
    </div>
  );
};

export default HomeschoolEvent_25_3;
