import React, { Fragment } from 'react';
import { OrderMaster } from '../../../../constants/types';
import TextWinds from '../../../../components/common/TextWinds';
import TextBox from '../../../../components/layout/TextBox';
import Buttons from '../../../../components/common/Buttons';
import { numberWithCommas } from '../../../../api/utils/format/numbers';
import { useNavigate } from 'react-router-dom';
import {
  getItemName,
  handleCopyClipBoard,
} from '../../../../api/utils/util-func';
import CompleteRow from './CompleteRow';
import IconCopy from '../../../lms/studentmgmt/popup/IconCopy';
import { getLatestTransaction } from '../../../../api/utils/order-item-transaction';

interface DepositProps {
  data: OrderMaster;
}
const Deposit = ({ data }: DepositProps) => {
  const navigate = useNavigate();
  const onClickHandler = (path: string) => {
    navigate(path);
  };

  const copyHandler = (text: string) => {
    handleCopyClipBoard(text).then(() => {
      alert('주문번호가 복사되었습니다.');
    });
  };

  const latestTransaction = getLatestTransaction(data.transactions);

  return (
    <div className="flex flex-col gap-4 items-center w-full max-w-[480px] mx-auto p-5 bg-white ">
      <TextWinds type="title_h2">주문 접수</TextWinds>

      <div className="relative w-[100px] h-[100px] shrink-0">
        <img
          className="absolute left-[16px] top-[9px]"
          width="67"
          height="81"
          src={process.env.PUBLIC_URL + '/images/payment_complete.png'}
        />
      </div>

      <TextWinds type="content_body2" color="purple5">
        주문이 정상적으로 접수되었습니다.
        <br />
        입금이 완료되면 주문이 완료됩니다.
      </TextWinds>

      <CompleteRow>
        <TextWinds type="sub_title01">입금계좌</TextWinds>
        <div
          className="flex items-center gap-2 cursor-pointer text-purple5 group"
          onClick={() =>
            copyHandler(
              `${latestTransaction.bankName} ${latestTransaction.accountNo}`
            )
          }
        >
          <TextWinds type="content" className="group-hover:text-purple5">
            {latestTransaction.bankName} {latestTransaction.accountNo}
          </TextWinds>
          <div className="animate-bounce">
            <IconCopy />
          </div>
        </div>
      </CompleteRow>

      <CompleteRow>
        <TextWinds type="sub_title01">예금주</TextWinds>
        <TextWinds type="content">{latestTransaction.depositName}</TextWinds>
      </CompleteRow>

      <CompleteRow>
        <TextWinds type="sub_title01">입금기한</TextWinds>
        <TextWinds type="content">{latestTransaction.expiryDate}</TextWinds>
      </CompleteRow>

      <CompleteRow>
        <TextWinds type="sub_title01">입금금액</TextWinds>
        <TextWinds type="content">
          {numberWithCommas(latestTransaction.balance_added)} 원
        </TextWinds>
      </CompleteRow>

      <div className="self-stretch h-0 shrink-0 border-[1px] border-solid border-[#adb5bd]" />

      <CompleteRow>
        <TextWinds className="w-[100px]" type="content_rel">
          상품명
        </TextWinds>
        <TextWinds type="content_rel">
          {getItemName(data.template.order_items)}
        </TextWinds>
      </CompleteRow>

      <CompleteRow>
        <TextWinds className="w-[100px]" type="content_rel">
          주문번호
        </TextWinds>
        <TextWinds type="content_rel">
          {latestTransaction.order_master_id}
        </TextWinds>
      </CompleteRow>

      <CompleteRow>
        <TextWinds className="w-[100px]" type="content_rel">
          주문자 성함
        </TextWinds>
        <TextWinds type="content_rel">{data.user.name}</TextWinds>
      </CompleteRow>
      <CompleteRow>
        <TextWinds className="w-[100px]" type="content_rel">
          주문일자
        </TextWinds>
        <TextWinds type="content_rel">
          {latestTransaction?.created_at
            ? new Date(latestTransaction.created_at).toLocaleString('ko-KR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: true,
            })
            : ''}
        </TextWinds>
      </CompleteRow>
      <CompleteRow>
        <TextWinds className="w-[100px]" type="content_rel">
          배송지
        </TextWinds>
        <TextWinds type="content_rel" className="text-right">
          {data.address_1} {data.address_2}
        </TextWinds>
      </CompleteRow>

      <CompleteRow className="gap-3">
        <Buttons
          type="filled"
          color="purple5"
          onClick={() => onClickHandler('/mypage/purchase')}
        >
          <TextWinds type="button" color="white">
            결제 상세 페이지 이동
          </TextWinds>
        </Buttons>
        <Buttons
          type="outlined"
          color="purple5"
          onClick={() => onClickHandler('/shop')}
        >
          <TextWinds type="button" color="purple5">
            SHOP메인으로 이동
          </TextWinds>
        </Buttons>
      </CompleteRow>
      {/* <section className="bg-gray1 rounded-[4px]">
            <div className="w-[395px] flex flex-col items-start justify-start gap-[4px] p-[12px]">
              <TextWinds type="content_bold" color="gray8">
                결제 완료 안내 (혹은 유의사항)
              </TextWinds>
              <TextWinds type="title_h6" color="gray8">
                섬, 도서지역 배송 안내
              </TextWinds>
            </div>
          </section> */}
      {/* </div> */}
    </div>
  );
};

export default Deposit;
