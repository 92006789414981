import { TransactionBase } from '../../constants/types';

const formatDate = (dateString: string) => {
  if (!dateString || dateString.length !== 14) {
    return dateString;
  }

  // YYYYMMDDHHMMSS → YYYY-MM-DDTHH:MM:SS 변환 후 Date 객체 생성
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  const hour = dateString.substring(8, 10);
  const minute = dateString.substring(10, 12);
  const second = dateString.substring(12, 14);

  // const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:00`;
  const dateObj = new Date(formattedDate);

  return dateObj.toLocaleString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });
};

/**
 * order의 transactions 중 updated_at이 가장 최근인 내역 조회
 * @param transactions 거래 내역 배열
 * @returns 최신 거래 내역 또는 null(거래 내역이 없는 경우)
 */
export function getLatestTransaction(
  transactions: TransactionBase[]
): TransactionBase | null {
  if (!transactions || transactions?.length === 0) return null;

  let latestTx = transactions.reduce((latest, current) => {
    return +new Date(current.updated_at) > +new Date(latest.updated_at)
      ? current
      : latest;
  });

  return {
    ...latestTx,
    expiryDate: formatDate(latestTx.expiryDate),
  };
}
