import React from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { LmsVoucherProps } from '../../../constants/types';
import Buttons from '../../../components/common/Buttons2';
import { useAppDispatch } from '../../../store/hook';
import { numberWithCommas } from '../../../api/utils/format/numbers';

interface TodoTicketStatusProps {
  voucherLists: LmsVoucherProps[];
  purchaseTicket: (data: any) => void;
}
const TodoTicketStatus: React.FC<TodoTicketStatusProps> = ({
  voucherLists,
  purchaseTicket,
}) => {
  const getTicketName = (type: string) => {
    switch (type) {
      case 'RECITATION':
        return '인증제 이용권';
      case 'HJ':
        return '젭스플 HJ 이용권';
      case 'HF':
        return '젭스플 HF 이용권';
      case 'WMW':
        return '젭스플 WMW 이용권';
      case 'JS1':
        return '젭스플 JS1 이용권';
      case 'JS2':
        return '젭스플 JS2 이용권';
      default:
        return type;
    }
  };

  return (
    <div className="flex w-full min-w-fit flex-col border-b border-t border-none border-gray5">
      {voucherLists &&
        voucherLists.map((row: any) => (
          <div
            key={row.id}
            className="mx-2 flex items-center justify-start border-t border-gray4 p-2 first:border-t-0"
          >
            <div className="flex flex-row gap-2">
              <img
                src={process.env.PUBLIC_URL + '/svg/icon/sidebar-gray.svg'}
                alt="ticket"
              />
              <span className="text-black">
                {getTicketName(row.name)}
                <span className="px-2 text-blue-500">
                  {numberWithCommas(row.voucherCount)}개
                </span>
              </span>
            </div>
            <div className="ml-auto">
              {row.type !== 'RECITATION' ? (
                <Buttons
                  variant="filled"
                  type="text"
                  color="purple5"
                  className="h-[38px] w-[69px]"
                  onClick={() => purchaseTicket(row)}
                >
                  <TextWinds
                    type="button_text1"
                    color="white"
                    className="font-bold"
                  >
                    구매하기
                  </TextWinds>
                </Buttons>
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default TodoTicketStatus;
