import { Tab, Tabs, TabsHeader } from '@material-tailwind/react';
import React, { useEffect, useMemo, useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import Buttons from '../../../../components/common/Buttons';
import { Pagination, Table, Tooltip } from 'antd';
import {
  LmsTodoStatusByIdType,
  LmsTodoStatusByTodoType,
} from '../../../../constants/types/lms-type';
import { stateColumnsById, stateColumnsByTodo } from './columns';
import { useParams, useSearchParams } from 'react-router-dom';
import { PaginationProps } from '../../../../constants/types';
import {
  getTodoStateById,
  getTodoStateByTodo,
} from '../../../../api/utils/lms/lmsApi';
import TodoExpandedRowByTodo from '../../../../components/lms/todo/TodoExpandedRowByTodo';
import TodoExpandedRowById from '../../../../components/lms/todo/TodoExpandedRowById';
import TodoStatusSearch from '../../../../components/lms/todo/TodoStatusSearch';
import { openContentsView } from '../../../../api/utils/util-func';
import { useAppDispatch } from '../../../../store/hook';
import { openConfirm, openModal } from '../../../../store/features/modalSlice';
import TodoEditDateModal from '../../../../components/lms/todo/TodoEditDateModal';
import { ToDoStatusEnum } from '../../../../enum';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  DEFAULT_PAGINATION,
  PAGE,
  PAGE_SIZE,
} from '../../../../constants/constants';

const TodoStatusPage = () => {
  type TodoStatusCatetory = 'BY_ID' | 'BY_TODO';

  const dispatch = useAppDispatch();
  const [params, setParams] = useSearchParams();
  const { classId, subType } = useParams();
  const { page, pageSize, search } = useMemo(() => {
    const page = +params.get('page') || PAGE;
    const pageSize = +params.get('pageSize') || PAGE_SIZE;
    const searchOption = params.get('searchOption');
    const searchValue = params.get('searchValue');
    return { page, pageSize, search: { searchOption, searchValue } };
  }, [params]);

  // RECITATION, JEBSON, JEBSPL, JEBS_AI_RECITATION
  const toDoType = subType.toUpperCase();

  const TABS_MENU: { label: string; value: ToDoStatusEnum }[] =
    toDoType === 'JEBS_AI_RECITATION'
      ? [
          { label: '진행 전', value: ToDoStatusEnum.NOT_STARTED },
          { label: '진행 중', value: ToDoStatusEnum.IN_PROGRESS },
          { label: '마감', value: ToDoStatusEnum.CLOSED },
          { label: 'AI 분석 중', value: ToDoStatusEnum.PROCESSING_RESULT },
        ]
      : [
          { label: '진행 전', value: ToDoStatusEnum.NOT_STARTED },
          { label: '진행 중', value: ToDoStatusEnum.IN_PROGRESS },
          { label: '마감', value: ToDoStatusEnum.CLOSED },
        ];

  // 진행전 NOT_STARTED, 진행중 IN_PROGRESS, 마감 CLOSED, 결과처리 PROCESSING_RESULT
  const [activeTab, setActiveTab] = useState<ToDoStatusEnum>(
    ToDoStatusEnum.NOT_STARTED
  );

  // 할일 별 보기 BY_TODO, 학생 별 보기 BY_ID
  const [category, setCategory] = useState<TodoStatusCatetory>('BY_TODO');

  const [pageInfo, setPageInfo] = useState<PaginationProps>(DEFAULT_PAGINATION);

  // 할일 미리보기 창
  const [todoView, setTodoView] = useState(undefined);

  const [dataListById, setDataListById] = useState<LmsTodoStatusByIdType[]>([]);
  const [data, setData] = useState<LmsTodoStatusByTodoType[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    if (category === 'BY_TODO') {
      getTodoStateByTodo(
        classId,
        page,
        pageSize,
        activeTab,
        toDoType,
        'DESC',
        search.searchOption,
        search.searchValue
      ).then((data) => {
        if (data.result.length === 0 && data.page.page > 1) {
          params.set('page', (data.page.page - 1).toString());
          setParams(params);
          return;
        }
        setData(data.result);
        if (data.result.length > 0) {
          setExpandedRowKeys([data.result[0].txInfo.id]);
        }

        setPageInfo({
          page: data?.page.page,
          pageSize: data?.page.pageSize,
          total: data?.page.total,
          lastPage: data?.page.lastPage,
        });
        setIsLoading(false);
      });
    } else {
      getTodoStateById(
        classId,
        page,
        pageSize,
        activeTab,
        toDoType,
        'DESC',
        search.searchOption,
        search.searchValue
      ).then((data) => {
        if (data.result.length === 0 && data.page.page > 1) {
          params.set('page', (data.page.page - 1).toString());
          setParams(params);
          return;
        }
        setDataListById(data.result);
        if (data.result.length > 0) {
          setExpandedRowKeys([data.result[0].id]);
        }
        setPageInfo({
          page: data?.page.page,
          pageSize: data?.page.pageSize,
          total: data?.page.total,
          lastPage: data?.page.lastPage,
        });
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    getData();
  }, [
    category,
    activeTab,
    toDoType,
    page,
    pageSize,
    search.searchOption,
    search.searchValue,
  ]);

  const todoViewWarningHandler = (title: string, url: string) => {
    dispatch(
      openConfirm({
        message: (
          <div className="flex flex-col gap-5 text-center md:my-3">
            <div className="text-2xl font-bold">
              결과가 반영되지 않는 <br />
              교사용 미리보기 화면입니다
            </div>
            <div className="text-base">
              학생 할 일은 학생 계정을 통해 진행해주세요.
            </div>
          </div>
        ),
        confirmText: '보기',
        cancelText: '닫기',
        onConfirm: () => {
          if (title.includes('Actual Test') || title.includes('Daily Test')) {
            window.open(url, 'TestView', 'location=no, menubar=no,');
          } else {
            contentsOpenHandler(url);
          }
        },
      })
    );
  };

  const contentsOpenHandler = (url: string) => {
    const dataToSend = {
      type: 'todoView',
      contents: {
        url,
      },
    };
    openContentsView(dataToSend, setTodoView);
  };

  // const contentsOpenHandler = (title: string, url: string) => {
  //   if (title.includes('Actual Test') || title.includes('Daily Test')) {
  //     window.open(url, 'TestView', 'location=no, menubar=no,');
  //   } else {
  //     const dataToSend = {
  //       type: 'todoView',
  //       contents: {
  //         url,
  //       },
  //     };
  //     openContentsView(dataToSend, setTodoView);
  //   }
  // };

  const openEditModal = (data: LmsTodoStatusByTodoType) => {
    dispatch(
      openModal({
        body: (
          <TodoEditDateModal
            classId={classId}
            todoStatus={activeTab}
            data={data}
            onSubmit={() => getData()}
          />
        ),
      })
    );
  };

  const ExpandHead = (
    <Tooltip
      title="상세 보기 (+)를 클릭하시면 학생별 진행률과 진행상태를 확인하실 수 있습니다."
      className="flex items-center gap-1  cursor-pointer"
    >
      상세 보기
      <InformationCircleIcon className="h-6 w-6 text-gray6" />
    </Tooltip>
  );

  return (
    <div>
      <Tabs value={activeTab} className="w-full">
        <TabsHeader
          className="rounded-none border-b border-gray6 bg-transparent p-0 mx-5 xl:m-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-purple5 shadow-none rounded-none',
          }}
        >
          {TABS_MENU.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={`w-[142px] h-[48px]`}
            >
              <TextWinds
                type={`${activeTab === value ? 'button2' : 'content_body3'}`}
                color={`${activeTab === value ? 'purple5' : 'gray7'}`}
                xlType={`${activeTab === value ? 'button' : 'content_body2'}`}
              >
                {label}
              </TextWinds>
            </Tab>
          ))}
        </TabsHeader>
      </Tabs>
      <div className="flex gap-x-6 p-5">
        <Buttons
          type="text"
          className="gap-x-1 p-0"
          color="gray6"
          onClick={() => setCategory('BY_TODO')}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              `${
                category === 'BY_TODO'
                  ? '/svg/icon-check-on.svg'
                  : '/svg/icon-check.svg'
              }`
            }
            alt=""
          />
          <TextWinds
            type="button2"
            color={`${category === 'BY_TODO' ? 'purple5' : 'gray7'}`}
          >
            할일 별 보기
          </TextWinds>
        </Buttons>
        <Buttons
          type="text"
          className="gap-x-1 p-0"
          color="gray7"
          onClick={() => setCategory('BY_ID')}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              `${
                category === 'BY_ID'
                  ? '/svg/icon-users-on.svg'
                  : '/svg/icon-users.svg'
              }`
            }
            alt=""
          />
          <TextWinds
            type="button2"
            color={`${category === 'BY_ID' ? 'purple5' : 'gray7'}`}
          >
            학생 별 보기
          </TextWinds>
        </Buttons>
      </div>
      <div className="px-5">
        <TextWinds type="content_body3" color="gray7">
          {category === 'BY_TODO'
            ? '학생별 할일 진행률과 상태는 [ 학생명 ] 또는 [ 학생 ID ]를 선택 후 해당 학생을 검색하시면 학생별 과제 진행 상태를 간편하게 확인하실 수 있습니다.'
            : '[학생 별 보기] 기능은 현재 개편 작업 중입니다. 학생 별 과제 진행률과 상태는 할일 별 보기에서 확인 가능합니다.'}
        </TextWinds>
      </div>
      <TodoStatusSearch category={category} subType={subType} tab={activeTab} />
      {category === 'BY_TODO' ? (
        <Table
          expandable={{
            columnTitle: ExpandHead,
            columnWidth: '120px',
            expandIcon: ({ expanded, record }) =>
              expanded ? (
                <Tooltip title="상세접기">
                  <MinusCircleOutlined
                    onClick={() =>
                      setExpandedRowKeys(
                        expandedRowKeys.filter(
                          (key) => key !== record.txInfo.id
                        )
                      )
                    }
                  />
                </Tooltip>
              ) : (
                <Tooltip title="상세보기">
                  <PlusCircleOutlined
                    onClick={() =>
                      setExpandedRowKeys([...expandedRowKeys, record.txInfo.id])
                    }
                  />
                </Tooltip>
              ),
            expandedRowRender: (record) => (
              <TodoExpandedRowByTodo
                classId={classId}
                params={{
                  toDoType,
                  toDoStatus: activeTab,
                  category: 'BY_TODO',
                  search,
                }}
                txId={record.txId}
                subType={subType}
                contentsHandler={contentsOpenHandler}
                isTest={
                  record.txInfo.title?.includes('Test') &&
                  (activeTab === ToDoStatusEnum.CLOSED ||
                    activeTab === ToDoStatusEnum.COMPLETED)
                }
              />
            ),
            expandedRowKeys,
          }}
          columns={stateColumnsByTodo({
            classId: classId,
            page: pageInfo.page,
            pageSize: pageInfo.pageSize,
            todoState: activeTab,
            editHandler: openEditModal,
            openPrev: todoViewWarningHandler,
            reload: getData,
            subType: subType,
          })}
          dataSource={data}
          rowKey={(record) => record.txInfo.id}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      ) : (
        <Table
          expandable={{
            columnTitle: '상세보기',
            columnWidth: '120px',
            expandedRowRender: (record) => {
              console.log(record);
              return (
                <TodoExpandedRowById
                  studentId={record.id}
                  studentUid={record.uid}
                  classId={classId}
                  toDoType={toDoType}
                  toDoStatus={activeTab}
                  id={record.id}
                  subType={subType}
                />
              );
            },
            expandedRowKeys,
            expandIcon: ({ expanded, record }) =>
              expanded ? (
                <Tooltip title="상세접기">
                  <MinusCircleOutlined
                    onClick={() =>
                      setExpandedRowKeys(
                        expandedRowKeys.filter((key) => key !== record.id)
                      )
                    }
                  />
                </Tooltip>
              ) : (
                <Tooltip title="상세보기">
                  <PlusCircleOutlined
                    onClick={() =>
                      setExpandedRowKeys([...expandedRowKeys, record.id])
                    }
                  />
                </Tooltip>
              ),
          }}
          columns={stateColumnsById(pageInfo)}
          dataSource={dataListById}
          rowKey={(record) => record.id}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      )}
      <Pagination
        current={pageInfo.page}
        pageSize={pageInfo.pageSize}
        total={pageInfo.total}
        onChange={(page, pageSize) => {
          params.set('page', page.toString());
          params.set('pageSize', pageSize.toString());
          setParams(params);
        }}
        className="mt-5 justify-center"
      />
    </div>
  );
};

export default TodoStatusPage;
