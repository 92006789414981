import { StudentHomeworkItemProps } from '../StudentHomeworkItem';
import { useParams } from 'react-router-dom';
import { openContentsView } from '../../../../../api/utils/util-func';
import React from 'react';
import { myLearningTodoType } from '../../../../../constants/types/student-type';
import { ToDoStatusEnum } from '../../../../../enum';
import {
  CookieTypeJebsAiRecitationResult,
  CookieTypeUserInfo,
  createCookie,
} from '../../../../../util/cookie/';
import dayjs from 'dayjs';
import {
  CookieKey,
  CookieTypeJebsAiRecitation,
} from '../../../../../util/cookie/';
import {
  patchTodoStatus,
  getRecitationAttemptCount,
  patchViewdResult,
} from '../../../../../api/utils/api/studentApi';
import createNewWindow from '../../../../../controllers/createNewWindow';
import { ProgressProps } from 'antd';
import {
  openAlert,
  openConfirm,
} from '../../../../../store/features/modalSlice';
import { Dispatch } from 'redux';
export const useHomeworkItem = ({
  data,
  setTodoView,
}: StudentHomeworkItemProps) => {
  const { classId } = useParams();

  const [todo, setTodo] = React.useState<myLearningTodoType>(data);

  // 시작일이 현재 날짜보다 이후인지 확인 (true: 아직 시작 전, false: 시작 가능)
  const isBeforeStartDay = dayjs(todo.fromDate).isAfter(dayjs());

  const PASSING_SCORE = 50;

  const {
    setCookie: setJebsAiRecitationCookie,
    removeCookie: removeJebsAiRecitationCookie,
  } = createCookie<CookieTypeJebsAiRecitation>(CookieKey.JEBS_AI_RECITATION);

  const { getCookie: getUserInfoCookie } = createCookie<CookieTypeUserInfo>(
    CookieKey.USER_INFO
  );

  const {
    removeCookie: removeJebsAiRecitationResultCookie,
    setCookie: setJebsAiRecitationResultCookie,
  } = createCookie<CookieTypeJebsAiRecitationResult>(
    CookieKey.JEBS_AI_RECITATION_RESULT
  );

  // @param isTest
  // @description
  // 연습하기 -> isTest = false
  // 응시하기 -> isTest = true
  const startHandler = (isTest: boolean = true) => {
    if (todo.name.includes('Actual Test') || todo.name.includes('Daily Test')) {
      window.open(todo.link, 'TestView', 'location=no, menubar=no,');
    } else {
      // 뉴인증제의 경우에 쿠키저장
      if (todo.type === 'JEBS_AI_RECITATION') {
        try {
          // 기존 AI인증제 쿠키 삭제
          removeJebsAiRecitationCookie();
        } catch (error) {
          // 쿠키가 없는 경우 무시
          // console.error(error);
        }

        // 유저 인포 쿠키 가져옴
        const { studentId, uid } = getUserInfoCookie();

        // 새로운 뉴인증제 쿠키 생성
        setJebsAiRecitationCookie(
          {
            student: {
              id: studentId,
              uid: uid,
            },
            teacher: {
              id: '',
              uid: '',
              classId: classId,
            },
            todo: {
              id: todo.id,
              type: 'JEBS_AI_RECITATION',
            },
            accessToken: localStorage.getItem('accessToken') || '',
          },
          1 / 24
        );

        // 뉴인증제 창 열기
        createNewWindow(
          `${todo.link}?isTest=${isTest ? 'true' : 'false'}`,
          1440,
          1440 / 1080
        );
      } else {
        // 뉴인증제 외 할일 처리
        const dataToSend = {
          type: 'todoView',
          contents: {
            url: todo.link,
          },
        };
        openContentsView(dataToSend, setTodoView);
      }
    }

    if (todo.status === 'COMPLETED') {
      return;
    }

    if (todo.type !== 'JEBS_AI_RECITATION') {
      patchTodoStatus(classId, todo.id, 'IN_PROGRESS');
      setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.IN_PROGRESS }));
    }
  };

  const getButtonLabel = (status: ToDoStatusEnum) => {
    if (todo.type === 'JEBS_AI_RECITATION') {
      if (isBeforeStartDay) {
        return '응시 대기';
      }
      switch (status) {
        case 'IN_PROGRESS':
          return '진행 중';
        case 'COMPLETED':
          return data.name.includes('Test') ? '결과 보기' : '완료';
        case 'CLOSED':
          return '마감';
        case 'PROCESSING_RESULT':
          return 'AI 분석 중';
        default:
          return '응시하기';
      }
    } else {
      if (isBeforeStartDay) {
        return '할 일 대기';
      }
      switch (status) {
        case 'IN_PROGRESS':
          return '진행 중';
        case 'COMPLETED':
          return data.name.includes('Test') ? '결과 보기' : '완료';
        case 'CLOSED':
          return '마감';
        // FIXME: todo !== 'JEBS_AI_RECITATION' 이면 'PROCESSING_RESULT'일 수 없음
        case 'PROCESSING_RESULT':
          return 'AI 분석 중';
        default:
          return '시작하기';
      }
    }
  };

  /**
   * 타입별 태그 텍스트 컬러 반환
   * [JEMM Basic Class]
   * JEBSON 파랑, JEBS_AI_RECITATION 빨강, RECITATION 초록
   *
   * [Who made the world? Series Class]
   * WMW 파랑, JS1 빨강, JS2 초록
   *
   * [Hello Father! Hello Jesus! Class]
   * HF 파랑, HJ 빨강
   * @param type
   * @returns
   */
  const getSubTypeColor = (type: string) => {
    switch (type) {
      case 'JEBSON':
      case 'WMW':
      case 'HF':
        return 'blue-500';
      case 'JEBS_AI_RECITATION':
      case 'JS1':
      case 'HJ':
        return 'red-500';
      case 'RECITATION':
      case 'JS2':
        return 'green-500';
      default:
        return 'skyblue';
    }
  };
  /**
   * 타입별 프로그래스바 strokeColor 반환
   * @param type
   * @returns
   */
  const getSubTypeStrokeColor = (
    type: string
  ): ProgressProps['strokeColor'] => {
    switch (type) {
      case 'JEBSON':
      case 'WMW':
      case 'HF':
        return {
          '0%': '#21C1FF',
          '100%': '#5E6EFF',
        };
      case 'JEBS_AI_RECITATION':
      case 'JS1':
      case 'HJ':
        return {
          '0%': '#FFA985',
          '100%': '#FF373F',
        };
      case 'RECITATION':
      case 'JS2':
        return {
          '0%': '#C2F6A8',
          '100%': '#78DC47',
        };
      default:
        return {
          '0%': 'skyblue',
          '100%': 'skyblue',
        };
    }
  };

  /**
   * 타입별 태그 배경색 반환
   * @param type
   * @returns
   */
  const getSubTypeBgColor = (type: string) => {
    switch (type) {
      case 'JEBSON':
      case 'WMW':
      case 'HF':
        return 'bg-[#e6f2ff]';
      case 'JEBS_AI_RECITATION':
      case 'JS1':
      case 'HJ':
        return 'bg-[#ffe7dd]';
      case 'RECITATION':
      case 'JS2':
        return 'bg-[#D2FFBA]/30';
      default:
        return 'skyblue';
    }
  };

  /**
   * AI인증제 결과보기 버튼 함수
   * @description
   * 결과보기 체크 함수 실행 후, 쿠키 설정 후 결과 창 열기
   */
  const handleResultView = async () => {
    const book = todo.name;
    const level = todo.bookLevel;

    try {
      const result = await patchViewdResult(classId, todo.id, true);
      // result를 사용하여 추가 작업 수행
      const { submissionInfo } = result;
      const latestSubmissionInfo = submissionInfo.reduce(
        (latest: any, current: any) => {
          return new Date(current.updatedAt) > new Date(latest.updatedAt)
            ? current
            : latest;
        },
        submissionInfo[0]
      );

      const passFail =
        latestSubmissionInfo.status === 'COMPLETED' &&
        Number(latestSubmissionInfo.overallScore) >= PASSING_SCORE
          ? 'PASS'
          : 'FAIL';

      console.log('passFail', passFail);

      // 기존 AI인증제 결과 쿠키 삭제
      try {
        removeJebsAiRecitationResultCookie();
      } catch (error) {
        // 쿠키가 없는 경우 무시
        // console.error(error);
      }

      const { studentId, uid } = getUserInfoCookie();
      setJebsAiRecitationResultCookie({
        student: {
          id: studentId,
          uid: uid,
        },
        recitation: {
          id: todo.id,
          level: level,
          book: book,
          result: passFail,
        },
      });

      createNewWindow(
        `${process.env.REACT_APP_JEBS_APP_URL}/student/jebs-ai-recitation/result`,
        1440,
        1440 / 840
      );

      // 통신 후 새로고침
      window.location.reload();
    } catch (error) {
      console.error('Error in patchViewdResult:', error);
    }
  };

  /**
   * AI인증제 응시기회 체크
   * @param dispatch
   * @description
   * Attempt 버튼 클릭 시 실행 함수,
   * 응시 결과가 합격이면 결과 창 열기,
   * 1회 응시했으면 마지막 기회 모달 열기,
   * 2회 응시했으면 남은 응시기회 없음 모달 열기,
   * 응시 기회가 남았으면(0회) 응시 시작
   */
  const checkRecitationAttemptCount = async (dispatch: Dispatch) => {
    const { attemptCount, submissionInfo } = await getRecitationAttemptCount(
      classId,
      todo.id
    );

    /* submissionInfo : [] */
    const latestSubmissionInfo = submissionInfo.reduce(
      (latest: any, current: any) => {
        return new Date(current.updatedAt) > new Date(latest.updatedAt)
          ? current
          : latest;
      },
      submissionInfo[0]
    );

    const book = parseInt(todo.name.match(/^Book(\d+)/)?.[1] || '0');
    const level = Math.floor((book - 1) / 4) + 1;

    /* 응시 0회, 제출이력배열 비어있으면 응시 시작 */
    // if (attemptCount == 0 && submissionInfo.length === 0) {
    //   startHandler(true);
    // }

    /* 응시 결과가 합격이면 결과 창 열기 */
    if (
      latestSubmissionInfo &&
      latestSubmissionInfo.status === 'COMPLETED' &&
      Number(latestSubmissionInfo.overallScore) >= PASSING_SCORE
    ) {
      await handleResultView();
    } else if (attemptCount == 1) {
      /* 응시 결과 불합격 */
      /*  1회 응시했으면 마지막 기회 모달 열기 */
      dispatch(
        openConfirm({
          message: (
            <div className="text-center">
              이번이 마지막 기회입니다.
              <br />
              계속 도전할까요?
            </div>
          ),
          onConfirm: () => {
            startHandler(true);
          },
          confirmText: '계속하기',
        })
      );
    } else if (attemptCount > 1) {
      /* 2회 응시했으면 남은 응시기회 없음 모달 열기 */
      dispatch(openAlert({ message: '남은 응시기회가 없습니다.' }));
      // window.location.reload();
    } else {
      /* 응시 기회가 남았으면(0회) 응시 시작 */
      startHandler(true);
    }
  };

  return {
    startHandler,
    getButtonLabel,
    getSubTypeColor,
    getSubTypeBgColor,
    getSubTypeStrokeColor,
    isBeforeStartDay,
    todo,
    handleResultView,
    checkRecitationAttemptCount,
  };
};
