import React, { useEffect } from 'react';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import { Dispatch } from 'redux';
import HFbutton from '../../../../components/common/input/HFbutton';
import { useAppDispatch } from '../../../../store/hook';
import dayjs from 'dayjs';
import { Divider } from 'antd';
import createNewWindow from '../../../../controllers/createNewWindow';
interface StudentHomeworkAiRecitationButtonsProps {
  isBeforeStartDay: boolean;
  todo: myLearningTodoType;
  startHandler: (isAttempt: boolean) => void;
  checkRecitationAttemptCount: (dispatch: Dispatch) => void;
  handleResultView: () => void;
}
const StudentHomeworkAiRecitationButtons = ({
  isBeforeStartDay,
  todo,
  startHandler,
  checkRecitationAttemptCount,
  handleResultView,
}: StudentHomeworkAiRecitationButtonsProps) => {
  const dispatch = useAppDispatch();

  /**
   * 연습하기
   * @param isFullWidth 버튼 너비 조절 여부
   * @returns
   */
  const Practice = ({ isFullWidth }: { isFullWidth?: boolean }) => {
    return (
      <div className={`w-full ${isFullWidth ? 'col-span-2' : ''}`}>
        <HFbutton
          onClick={() => startHandler(false)}
          disabled={todo.status !== 'NOT_STARTED'}
          height={40}
        >
          연습하기
        </HFbutton>
      </div>
    );
  };

  /**
   * 응시하기
   * @param retry 재응시 여부
   * @returns
   */
  const Attempt = ({ retry }: { retry?: boolean }) => {
    return (
      <>
        <HFbutton
          type="red-Filled"
          onClick={() => checkRecitationAttemptCount(dispatch)}
          height={40}
        >
          {retry ? '재응시' : '응시하기'}
        </HFbutton>
      </>
    );
  };

  /**
   * 결과보기
   * @param isFullWidth 버튼 너비 조절 여부
   * @returns
   */
  const Result = ({ isFullWidth }: { isFullWidth?: boolean }) => {
    return (
      <div className={`w-full ${isFullWidth ? 'col-span-2' : ''}`}>
        <HFbutton type="Filled" onClick={() => handleResultView()} height={40}>
          결과 보기
        </HFbutton>
      </div>
    );
  };

  /**
   * 합격/불합격
   * @param detail 상세 보기 여부
   * @param isFullWidth 버튼 너비 조절 여부
   * @returns
   */
  const PassFail = ({
    detail,
    isFullWidth,
  }: {
    detail?: boolean;
    isFullWidth?: boolean;
  }) => {
    return (
      <div
        className={`w-full ${isFullWidth ? 'col-span-2' : ''}  content-center`}
      >
        {isFullWidth && detail && (
          <Divider className="block xl:hidden mt-0 mb-2" />
        )}
        <div
          className={`w-full text-center font-bold text-lg ${
            todo.passDate ? 'text-green-400' : 'text-red5'
          }`}
        >
          {todo.passDate ? '합격' : '불합격'}
        </div>
        {detail && (
          <div className="text-xs text-center mt-2">
            최근 응시 날짜 :{' '}
            {todo.passDate
              ? dayjs(todo.passDate).format('YYYY-MM-DD')
              : dayjs(todo.failDate).format('YYYY-MM-DD')}
          </div>
        )}
      </div>
    );
  };

  /**
   * 마감
   * @returns
   */
  const Closed = () => {
    return (
      <div className="w-full col-span-2">
        <Divider className="block xl:hidden mt-0 mb-2" />
        <div className="text-red5 font-bold text-center text-lg">마감</div>
      </div>
    );
  };

  /**
   * AI 분석 중
   * @returns
   */
  const AiProcessing = () => {
    return (
      <div className="w-full col-span-2">
        <Divider className=" block xl:hidden mt-0 mb-4" />
        <div className="w-full text-center font-bold text-lg text-gray-700">
          AI 분석 중
        </div>
        {todo.recentlySubmissionAt && (
          <div className="text-xs  text-center mt-2">
            최근 응시 날짜 :{' '}
            {dayjs(todo.recentlySubmissionAt).format('YYYY-MM-DD')}
          </div>
        )}
      </div>
    );
  };

  /**
   * 진행 중
   * @returns
   */
  const InProgress = () => {
    return (
      <div className="flex col-span-2">
        <HFbutton
          type="red-Filled"
          onClick={() => startHandler(true)}
          height={40}
          disabled={isBeforeStartDay}
        >
          진행 중
        </HFbutton>
      </div>
    );
  };
  /**
   * NOT_STARTED 시작전
   * - [ 연습하기 ] : 응시 대기
   * - [ 연습하기 ] [ 응시하기 ] : 응시 가능
   *
   * COMPLETED 완료
   * - [ 결과보기 ] (결과 안봄)
   * - 합격 : 결과봄, 합격
   * - 불합격 [ 재응시 ] : 결과봄, 1번 불합격
   * - 불합격 : 결과봄, 2번 불합격
   *
   * CLOSED 마감
   * - 마감 : 응시 안함
   * - [ 결과보기 ] : 응시 함, 결과 안봄
   * - 합격 : 응시 함, 결과 봄
   * - 불합격 : 응시 함, 결과 봄
   * @returns
   */
  const getButtons = () => {
    // FIXME: 추후 수정 필요
    switch (todo.status) {
      // 시작전
      case 'NOT_STARTED':
        // 응시 전이지만 합격/불합격 날짜가 있는 데이터 (오류데이터)
        if (todo.passDate || todo.failDate)
          return <PassFail isFullWidth detail />;
        else {
          // 응시가능기간 전
          if (isBeforeStartDay) return <Practice isFullWidth />;
          // 응시가능기간 후
          else if (todo.attemptCount === 0)
            return [<Practice key="practice" />, <Attempt key="attempt" />];
          else if (todo.attemptCount === 1)
            return [
              <Practice key="practice" />,
              <Attempt retry key="attempt" />,
            ];
          // 혹시 모르는 오류 처리
          else return <Result isFullWidth />;
        }

      // 정상종료
      case 'COMPLETED':
        // [ 결과보기 ] 이미 봤음
        if (todo.viewedResult) {
          // 합격
          if (todo.passDate) return <PassFail detail isFullWidth />;
          // 불합격
          else {
            if (todo.attemptCount === 1)
              return (
                <>
                  <PassFail />
                  <Attempt retry />
                  <div className="text-xs text-center mt-2 col-span-2">
                    최근 응시 날짜 : {dayjs(todo.failDate).format('YYYY-MM-DD')}
                  </div>
                </>
              );
            else {
              return <PassFail detail isFullWidth />;
            }
          }
        } else {
          // [ 결과보기 ] 안봤음
          return <Result isFullWidth />;
        }

      // 응시기간 이후
      case 'CLOSED':
        if (!todo.passDate && !todo.failDate) return <Closed />;
        else {
          if (!todo.viewedResult) return <Result isFullWidth />;
          else return <PassFail detail isFullWidth />;
        }

      case 'PROCESSING_RESULT':
        // AI 분석 중
        return <AiProcessing />;

      case 'IN_PROGRESS':
        // 응시중
        return <InProgress />;
    }
  };

  return (
    <div className="grid grid-cols-2 gap-2 w-full xl:w-[220px]">
      {getButtons()}
    </div>
  );
};

export default StudentHomeworkAiRecitationButtons;
