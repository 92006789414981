import React, { useContext, useEffect } from 'react';
import NormalPage from '../../../../components/layout/NormalPage';
import LearningTabs from '../../../jebs/learning/component/LearningTabs';
import TextWinds from '../../../../components/common/TextWinds';
import StudentHomeworkList from './StudentHomeworkList';
import {
  TodoMgmtSubTypeName,
  TodoOrderBy,
  ToDoStatusEnum,
} from '../../../../enum';
import instance from '../../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../../constants/types';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import { useParams } from 'react-router-dom';
import { StudentClassDetailContext } from '../StudentClassDetailContext';
import { Segmented, Select } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
const StudentHomeworkPage = () => {
  const { classId } = useParams();
  const [todoType, setTodoType] = React.useState<TodoMgmtSubTypeName | ''>('');
  const [status, setStatus] = React.useState<ToDoStatusEnum | ToDoStatusEnum[]>(
    ToDoStatusEnum.ALL,
  );
  const [orderBy, setOrderBy] = React.useState<TodoOrderBy | ''>('');

  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [completedCount, setCompletedCount] = React.useState<number>(0);

  const { getClassDetail, classDetail } = useContext(StudentClassDetailContext);
  const [classType, setClassType] = React.useState<
    | 'JEMM Basic Class'
    | 'Who made the world? Series Class'
    | 'Hello Father! Hello Jesus! Class'
  >('JEMM Basic Class');
  const [listKey, setListKey] = React.useState<number>(0);

  useEffect(() => {
    if (classId) {
      getClassDetail(classId);
    }
  }, [classId]);

  // classDetail이 변경될 때 isBasicClass 상태 업데이트
  useEffect(() => {
    if (classDetail) {
      const classType = classDetail.course.name;
      setClassType(classType);
    }
  }, [classDetail]);

  const headData = (() => {
    switch (classType) {
      case 'JEMM Basic Class':
        return [
          {
            label: '전체',
            value: '',
          },
          {
            label: '젭스온',
            value: 'JEBSON',
          },
          {
            label: 'AI 인증제',
            value: 'JEBS_AI_RECITATION',
          },
          {
            label: '인증제',
            value: 'RECITATION',
          },
        ];
      case 'Who made the world? Series Class':
        return [
          {
            label: '전체',
            value: '',
          },
          {
            label: 'WMW',
            value: 'WMW',
          },
          {
            label: 'JS1',
            value: 'JS1',
          },
          {
            label: 'JS2',
            value: 'JS2',
          },
        ];
      case 'Hello Father! Hello Jesus! Class':
        return [
          {
            label: '전체',
            value: '',
          },
          {
            label: 'HF',
            value: 'HF',
          },
          {
            label: 'HJ',
            value: 'HJ',
          },
        ];
      default:
        return [];
    }
  })();

  useEffect(() => {
    getHomework();
  }, []);

  const setTodoTypeHandler = (type: string) => {
    setStatus(ToDoStatusEnum.ALL);
    switch (type) {
      case 'JEBSON':
        setTodoType(TodoMgmtSubTypeName.JEBSON);
        break;
      case 'JEBS_AI_RECITATION':
        setTodoType(TodoMgmtSubTypeName.JEBS_AI_RECITATION);
        break;
      case 'RECITATION':
        setTodoType(TodoMgmtSubTypeName.RECITATION);
        break;
      case 'WMW':
        setTodoType(TodoMgmtSubTypeName.WMW);
        break;
      case 'JS1':
        setTodoType(TodoMgmtSubTypeName.JS1);
        break;
      case 'JS2':
        setTodoType(TodoMgmtSubTypeName.JS2);
        break;
      case 'HF':
        setTodoType(TodoMgmtSubTypeName.HF);
        break;
      case 'HJ':
        setTodoType(TodoMgmtSubTypeName.HJ);
        break;
      default:
        setTodoType('');
        break;
    }
  };

  const statusHandler = (value: string) => {
    switch (value) {
      case ToDoStatusEnum.NOT_STARTED:
        setStatus(ToDoStatusEnum.NOT_STARTED);
        break;
      case ToDoStatusEnum.IN_PROGRESS:
        setStatus(ToDoStatusEnum.IN_PROGRESS);
        break;
      case ToDoStatusEnum.COMPLETED:
        setStatus(ToDoStatusEnum.COMPLETED);
        break;
      case ToDoStatusEnum.CLOSED:
        setStatus(ToDoStatusEnum.CLOSED);
        break;
      case ToDoStatusEnum.PROCESSING_RESULT:
        setStatus(ToDoStatusEnum.PROCESSING_RESULT);
        break;
      default:
        setStatus(ToDoStatusEnum.ALL);
        break;
    }
  };

  const orderHandler = (value: string) => {
    console.log(value);
    switch (value) {
      case TodoOrderBy.REMAIN_DAYS_ASC:
        setOrderBy(TodoOrderBy.REMAIN_DAYS_ASC);
        break;
      case TodoOrderBy.DESC:
        setOrderBy(TodoOrderBy.DESC);
        break;
      case TodoOrderBy.ASC:
        setOrderBy(TodoOrderBy.ASC);
        break;
    }
  };

  const getHomework = async () => {
    const params = {
      page: 1,
      pageSize: 10,
    };
    try {
      const result = await instance.get<
        ApiResponse<{
          todos: myLearningTodoType[];
          completedCount: number;
          totalCount: number;
        }>
      >('/v1/student/class/' + classId + '/todo', { params });

      if (result.data.statusCode === 200) {
        setTotalCount(result.data.data.totalCount);
        setCompletedCount(result.data.data.completedCount);
      } else {
        console.error(result.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const statusOptions =
    todoType === TodoMgmtSubTypeName.JEBS_AI_RECITATION
      ? [
          {
            label: '전체',
            value: '',
          },
          {
            label: '진행 전',
            value: ToDoStatusEnum.NOT_STARTED,
          },
          {
            label: 'AI 분석 중',
            value: ToDoStatusEnum.PROCESSING_RESULT,
          },
          {
            label: '완료',
            value: ToDoStatusEnum.COMPLETED,
          },
        ]
      : [
          {
            label: '전체',
            value: '',
          },
          {
            label: '진행 전',
            value: ToDoStatusEnum.NOT_STARTED,
          },
          {
            label: '진행 중',
            value: ToDoStatusEnum.IN_PROGRESS,
          },
          {
            label: '완료',
            value: ToDoStatusEnum.COMPLETED,
          },
        ];

  const orderOptions = [
    {
      label: '최신과제순',
      value: TodoOrderBy.DESC,
    },
    {
      label: '오래된과제순',
      value: TodoOrderBy.ASC,
    },
    {
      label: '남은날짜순',
      value: TodoOrderBy.REMAIN_DAYS_ASC,
    },
  ];

  const reloadList = () => {
    setListKey((prevKey) => prevKey + 1);
    getHomework();
  };

  return (
    <>
      <NormalPage xlBgColor="gray1" bgColor="gray1" paddingY={0} paddingX={0}>
        <LearningTabs
          classDetail={classDetail}
          data={headData}
          onChange={(tabValue) => setTodoTypeHandler(tabValue)}
        >
          <div className="flex flex-col items-start gap-[6px] px-5 pt-4 xl:px-0 xl:pt-0">
            <Segmented
              options={statusOptions}
              value={status === ToDoStatusEnum.ALL ? '' : String(status)}
              onChange={(value) => statusHandler(value)}
              size="large"
            />
            <div className="flex w-full items-center justify-between">
              <TextWinds type="content" color="gray6">
                {totalCount}개 중 {totalCount - completedCount}개 미완료
              </TextWinds>
              <div className="flex items-center gap-3">
                <Select
                  options={orderOptions}
                  defaultValue={TodoOrderBy.DESC}
                  className="w-[120px]"
                  onChange={(value) => orderHandler(value)}
                  size="middle"
                />
                <ReloadIcon onClick={reloadList} />
              </div>
            </div>
          </div>
          <div className="mx-auto w-full px-[20px] pb-4 pt-0 xl:px-[2px]">
            <StudentHomeworkList
              key={listKey}
              todoType={todoType}
              status={status}
              orderBy={orderBy}
              setTotalCount={setTotalCount}
              setCompletedCount={setCompletedCount}
            />
          </div>
        </LearningTabs>
      </NormalPage>
    </>
  );
};

export default StudentHomeworkPage;

const ReloadIcon = ({ onClick }: { onClick: () => void }) => (
  <div
    onClick={onClick}
    className="cursor-pointer rounded-full p-2 hover:bg-[#EFEAFB] active:bg-[#DFD6F7]"
  >
    <svg
      className="group"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="transition-colors group-active:stroke-[#6032DB]"
        d="M16.416 10.125C15.873 13.7332 12.7598 16.5 9 16.5C4.85775 16.5 1.5 13.1422 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C12.075 1.5 14.7187 3.351 15.876 6"
        stroke="#343A40"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        className="transition-colors group-active:stroke-[#6032DB]"
        d="M12.75 6H16.05C16.1091 6 16.1676 5.98836 16.2222 5.96575C16.2768 5.94313 16.3264 5.90998 16.3682 5.8682C16.41 5.82641 16.4431 5.7768 16.4657 5.72221C16.4884 5.66761 16.5 5.60909 16.5 5.55V2.25"
        stroke="#343A40"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);
