import React, { useEffect } from 'react';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import { myLearingInfoDetailType } from '../../../constants/types/student-type';

export const StudentClassDetailContext = React.createContext<any | null>(null);

export const StudentClassDetailContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [classDetail, setClassDetail] =
    React.useState<myLearingInfoDetailType | null>(null);
  const [classId, setClassId] = React.useState<string>('');

  const getClassDetail = async (classId: string) => {
    try {
      const result = await instance.get<ApiResponse<myLearingInfoDetailType>>(
        '/v1/student/class/' + classId
      );
      if (result.data.statusCode === 200) {
        const data = result.data.data;
        setClassDetail(data);
      } else {
        throw new Error(result.data.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (classId !== '') {
      getClassDetail(classId);
    }
  }, [classId]);

  return (
    <StudentClassDetailContext.Provider value={{ classDetail, getClassDetail }}>
      {children}
    </StudentClassDetailContext.Provider>
  );
};
