import { Pagination, Table } from 'antd';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  columnNoLesson,
  columnsBase,
  columnsFull,
  columnsRECITATION,
  TodoListType,
} from './columns';
import {
  getTodoBookLists,
  getTodoLessonLists,
  getTodoLists,
} from '../../../../api/utils/lms/lmsApi';
import { ToDoMgmtSubType } from '../../../../enum';
import LmsSelect, {
  LmsSelectOption,
  toLmsSelectOptions,
} from '../../../../components/lms/LmsSelect';
import { TODO_SUBTYPE, TODO_TYPE } from '../../../../data/statics-datas';
import { selectLmsLists } from '../../../../store/features/lmsSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import HFbutton from '../../../../components/common/input/HFbutton';
import CheckBoxes from '../../../../components/common/CheckBoxes';
import TextWinds from '../../../../components/common/TextWinds';
import { openContentsView } from '../../../../api/utils/util-func';
import {
  DEFAULT_PAGINATION,
  PAGE,
  PAGE_SIZE,
} from '../../../../constants/constants';
import { openConfirm } from '../../../../store/features/modalSlice';
import {
  CookieKey,
  CookieTypeJebsAiRecitation,
  createCookie,
} from '../../../../util/cookie/';
import createNewWindow from '../../../../controllers/createNewWindow';

const RegisterTodoPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { classId, subType } = useParams();
  const lmsLists = useAppSelector(selectLmsLists);

  const [pageInfo, setPageInfo] = useState(DEFAULT_PAGINATION);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  // 정리 필요. 서브타입에 따라 타입 디테일을 가져오는 함수
  const getType = (subType: string) => {
    if (ToDoMgmtSubType.JEBSPL === subType.toUpperCase()) {
      if ('VY6Yl4kxQsb3sYJZ' === lmsLists.course?.id) {
        // WMW
        return TODO_SUBTYPE.find(
          (item) => item.courseId === lmsLists.course?.id,
        );
      } else {
        // HFHJ
        return TODO_SUBTYPE.find(
          (item) =>
            item.courseId === '' && item.subType === subType.toUpperCase(),
        );
      }
    } else {
      // JEBSON, JEBSPL
      return TODO_SUBTYPE.find(
        (item) => item.subType === subType.toUpperCase(),
      );
    }
  };

  // 첫번째 셀렉트 : 타입 디테일 [ WMW, JS1, JS2, HF, HJ, RECITATION, JEBSON ]
  const typeList = useMemo(() => getType(subType).types, [subType]);
  // 두번째 셀렉트 : 북/챕터
  const [todoBookLists, setTodoBookLists] = useState<string[]>();
  // 세번째 셀렉트 : 레슨
  const [todoLessonLists, setTodoLessonLists] = useState<string[]>();

  const { type, book, lesson, page, pageSize } = useMemo(() => {
    return {
      type: (searchParams.get('type') as TODO_TYPE) || typeList[0]?.key,
      book: searchParams.get('book') || undefined,
      lesson: searchParams.get('lesson') || undefined,
      page: +searchParams.get('page') || PAGE,
      pageSize: +searchParams.get('pageSize') || PAGE_SIZE,
    };
  }, [typeList, searchParams]);

  // 화면에 뿌려지는 데이터
  const [todoLists, setTodoLists] = useState(undefined);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // 할일 미리보기 창
  const setTodoView = useState<Window>(undefined)[1];

  const {
    setCookie: setJebsAiRecitationCookie,
    removeCookie: removeJebsAiRecitationCookie,
  } = createCookie<CookieTypeJebsAiRecitation>(CookieKey.JEBS_AI_RECITATION);

  const todoViewWarningHandler = (row: TodoListType) => {
    dispatch(
      openConfirm({
        message: (
          <div className="flex flex-col gap-5 text-center md:my-3">
            <div className="text-2xl font-bold">
              결과가 반영되지 않는 <br />
              교사용 미리보기 화면입니다
            </div>
            <div className="text-base">
              학생 할 일은 학생 계정을 통해 진행해주세요.
            </div>
          </div>
        ),
        confirmText: '보기',
        cancelText: '닫기',
        onConfirm: () => {
          if (
            row.title.includes('Actual Test') ||
            row.title.includes('Daily Test')
          ) {
            window.open(row.link, 'TestView', 'location=no, menubar=no,');
          } else {
            contentsOpenHandler(row);
          }
        },
      }),
    );
  };

  const contentsOpenHandler = (row: TodoListType) => {
    if (row.title.includes('Actual Test') || row.title.includes('Daily Test')) {
      window.open(row.link, 'TestView', 'location=no, menubar=no,');
    } else {
      if (row.type === 'JEBS_AI_RECITATION') {
        /**
         * AI인증제 : 연습모드로 AI인증제 열기
         * 기존 AI인증제 쿠키 삭제
         */
        try {
          removeJebsAiRecitationCookie();
        } catch (error) {
          // 쿠키가 없는 경우 무시
          // console.error(error);
        }
        setJebsAiRecitationCookie(
          {
            student: {
              id: 0,
              uid: '',
            },
            teacher: {
              id: '',
              uid: '',
              classId: classId,
            },
            todo: {
              id: 0,
              type: 'JEBS_AI_RECITATION',
            },
            accessToken: localStorage.getItem('accessToken') || '',
          },
          1 / 24,
        );

        createNewWindow(`${row.link}?isTest=false`, 1440, 1440 / 1080);
      } else {
        const dataToSend = {
          type: 'todoView',
          contents: {
            url: row.link,
          },
        };
        openContentsView(dataToSend, setTodoView);
      }
    }
  };

  const columns = useMemo(() => {
    switch (type) {
      case TODO_TYPE.RECITATION:
      case TODO_TYPE.JEBS_AI_RECITATION:
        return columnsRECITATION(todoViewWarningHandler, type);
      case TODO_TYPE.WMW:
      case TODO_TYPE.JS1:
      case TODO_TYPE.JS2:
        return columnNoLesson(todoViewWarningHandler);
      case TODO_TYPE.HF:
      case TODO_TYPE.HJ:
      case TODO_TYPE.JEBSON:
        return columnsFull(todoViewWarningHandler);
      default:
        return columnsBase(todoViewWarningHandler);
    }
  }, [type]);

  useEffect(() => {
    if (!type) return;

    // 타입 디테일에 따른 북/챕터 가져오기
    getTodoBookLists(classId, type).then((todoBookLists) => {
      setTodoBookLists(todoBookLists);
      setTodoLessonLists(undefined);
    });
  }, [type]);

  useEffect(() => {
    if (type === TODO_TYPE.JEBSON) {
      if (!book) {
        setTodoLessonLists(undefined);
        return;
      }

      getTodoLessonLists(classId, type, book).then((todoLessonLists) => {
        setTodoLessonLists(todoLessonLists);
      });
    }
  }, [book]);

  useEffect(() => {
    loadData(type, book, lesson);
  }, [type, book, lesson, page, pageSize]);

  const loadData = (type: string, book?: string, lesson?: string) => {
    if (!type) return;

    setIsLoading(true);

    getTodoLists(classId, type, page, pageSize, book, lesson).then(
      (todoLists) => {
        if (page > 1 && todoLists.result.length === 0) {
          searchParams.set('page', todoLists.page.lastPage.toString());
          setSearchParams(searchParams);
          return;
        }
        setPageInfo({
          page: page,
          pageSize: pageSize,
          total: todoLists.page.total,
          lastPage: todoLists.page.lastPage,
        });
        setTodoLists(todoLists.result);
        setIsLoading(false);
      },
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    columnWidth: 120,
    columnTitle: (originNode: ReactNode) => (
      <>
        <CheckBoxes
          type="full"
          id={`all-999`}
          value={'전체 선택'}
          labelValue={
            <TextWinds type="content_bold" className="px-[6px] py-1">
              전체 선택
            </TextWinds>
          }
          checked={selectedRowKeys?.length === todoLists?.length}
          // borderWidth={2}
          onChange={(e) => {
            if (!e.target.checked) {
              setSelectedRowKeys([]);
            } else {
              setSelectedRowKeys(todoLists.map((item: any) => item.id));
            }
          }}
        />
      </>
    ),
    renderCell(
      checked: boolean,
      record: any,
      index: number,
      originNode: ReactNode,
    ) {
      return (
        <CheckBoxes
          type="full"
          checked={selectedRowKeys.includes(record.id)}
          // borderWidth={2}
          onChange={(e) => {
            const rows = selectedRowKeys.includes(record.id)
              ? selectedRowKeys.filter((key) => key !== record.id)
              : [...selectedRowKeys, record.id];
            setSelectedRowKeys(rows);
          }}
        />
      );
    },
  };

  const registAll = () => {
    if (selectedRowKeys.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }

    searchParams.set('stage', 'regist');
    searchParams.set('type', type);
    searchParams.set('ids', selectedRowKeys.join(','));
    setSearchParams(searchParams);
    // nav(`?stage=regist&type=${type}&ids=${selectedRowKeys}`);
  };

  return (
    <div className="flex flex-col gap-3 p-3">
      <div className="flex flex-col justify-between gap-3 md:flex-row">
        <div className="flex flex-col gap-2 md:flex-row">
          {typeList.length > 0 && (
            <LmsSelect
              label={'자료유형'}
              options={toLmsSelectOptions(typeList, 'key', 'key', 'name')}
              onChange={(v) => {
                searchParams.delete('book');
                searchParams.delete('lesson');
                if (v === undefined) {
                  searchParams.delete('type');
                } else {
                  searchParams.set('type', v);
                }
                setSearchParams(searchParams);
                // setType(v as TODO_TYPE);
              }}
              value={type}
              clearVisible={false}
              className="w-full md:w-[200px]"
            />
          )}
          <LmsSelect
            label={'북/챕터'}
            options={
              todoBookLists
                ? todoBookLists.reduce<LmsSelectOption[]>(
                    (acc, cur) => [
                      ...acc,
                      { key: cur, value: cur, label: cur },
                    ],
                    [],
                  )
                : []
            }
            value={book}
            onChange={(v) => {
              searchParams.delete('lesson');
              if (v === undefined) {
                searchParams.delete('book');
              } else {
                searchParams.set('book', v);
              }
              setSearchParams(searchParams);
            }}
            className="w-full md:w-[200px]"
          />
          {type === TODO_TYPE.JEBSON && (
            <LmsSelect
              label={'레슨'}
              options={
                todoLessonLists
                  ? todoLessonLists.reduce<LmsSelectOption[]>(
                      (acc, cur) => [
                        ...acc,
                        { key: cur, value: cur, label: cur },
                      ],
                      [],
                    )
                  : []
              }
              value={lesson}
              onChange={(v) => {
                if (v === undefined) {
                  searchParams.delete('lesson');
                } else {
                  searchParams.set('lesson', v);
                }
                setSearchParams(searchParams);
              }}
              notFoundContent="북/챕터를 선택해주세요."
              className="w-full md:w-[200px]"
            />
          )}
        </div>
        <div className="flex w-full flex-row items-center md:w-[170px]">
          <div className="w-[60px]">{selectedRowKeys.length} 개</div>
          <HFbutton
            height={34}
            type="orange-Filled"
            onClick={() => registAll()}
          >
            선택 일괄 등록
          </HFbutton>
        </div>
      </div>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={todoLists}
        rowKey={(row) => row.id}
        scroll={{ x: 'max-content' }}
        pagination={false}
        size="small"
        loading={isLoading}
      />
      <Pagination
        current={page}
        pageSize={pageSize}
        total={pageInfo.total}
        showSizeChanger
        onChange={(page, pageSize) => {
          setSelectedRowKeys([]);
          searchParams.set('page', page.toString());
          searchParams.set('pageSize', pageSize.toString());
          setSearchParams(searchParams);
        }}
        className="justify-center"
      />
    </div>
  );
};

export default RegisterTodoPage;
