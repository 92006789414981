import {
  Tabs as MaterialTabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import { useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import { Divider } from 'antd';

interface TabData {
  classDetail?: any;
  label: string;
  value: string;
  labelNum?: number;
  totalNum?: number;
  list?: Array<{
    value: string;
    title: string;
    date?: string;
    completeData?: string;
    result?: number;
  }>;
}

interface TabsProps {
  classDetail?: any;
  data: TabData[];
  children: React.ReactNode;
  onChange: (tabValue: string) => void; // onChange 콜백 함수 정의
}

const LearningTabs: React.FC<TabsProps> = ({
  classDetail,
  data,
  children,
  onChange,
}) => {
  const [activeTab, setActiveTab] = useState(data[0].value);

  const handleChangeTab = (tabValue: string) => {
    setActiveTab(tabValue);
    onChange(tabValue); // 탭 변경 시 onChange 콜백 호출
  };

  const ClassCover = ({
    classDetail,
    isMobile,
    className,
  }: {
    classDetail: any;
    isMobile: boolean;
    className: string;
  }) => {
    return (
      <div className={`${className}`}>
        <div className="flex flex-col p-5 bg-opacity-60 bg-white xl:bg-opacity-100 xl:bg-transparent xl:p-0">
          <img
            src={classDetail.course.thumbnail}
            alt={classDetail.course.name}
            className="h-fit object-cover rounded-lg"
          />
          <div className="flex flex-col gap-2 pl-2 mt-4">
            <TextWinds type="title_h2" children={classDetail.course.name} />
            <TextWinds
              type="content"
              children={`${classDetail.teacherName} 선생님`}
            />
          </div>
        </div>
        <Divider className="hidden xl:block" />
      </div>
    );
  };

  return (
    <MaterialTabs
      value={activeTab}
      className="relative w-full min-h-screen xl:flex xl:w-[976px] mx-auto xl:pt-[30px] xl:gap-[24px]"
    >
      <div className="flex flex-col">
        {classDetail && (
          <ClassCover
            classDetail={classDetail}
            isMobile={true}
            className="xl:hidden"
          />
        )}
        <TabsHeader
          className="p-0 bg-white border-y rounded-none border-gray6 xl:flex-col xl:w-[276px] xl:p-[14px] xl:rounded-[8px] xl:shrink-0 xl:border-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-purple5 shadow-none rounded-none xl:border-0 ',
          }}
        >
          {classDetail && (
            <ClassCover
              classDetail={classDetail}
              className="hidden xl:block"
              isMobile={false}
            />
          )}
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => handleChangeTab(value)} // onClick 이벤트 핸들러 변경
              className={`text-content_bold h-[48px] xl:h-[56px] xl:justify-start xl:rounded-[4px] xl:font-bold ${
                activeTab === value ? 'text-purple5 xl:bg-purple0 ' : ''
              }`}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
      </div>
      <TabsBody className="xl:w-[676px] xl:shrink-0">{children}</TabsBody>
    </MaterialTabs>
  );
};

export default LearningTabs;
