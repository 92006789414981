import React from 'react';
import { orderDetailType } from '../../../constants/types/mypage-type';
import { OrderItemVariant, OrderTemplate } from '../../../constants/types';
import { numberWithCommas } from '../../../api/utils/format/numbers';
import html2canvas from 'html2canvas';
import HFbutton from '../../../components/common/input/HFbutton';
import { useAppDispatch } from '../../../store/hook';
import { closeModal } from '../../../store/features/modalSlice';

interface PurchaseReceiptProps {
  orderDetail: orderDetailType;
  userName: string;
}

const PurchaseReceipt = ({ orderDetail, userName }: PurchaseReceiptProps) => {
  const dispatch = useAppDispatch();
  if (!orderDetail) return null;

  const template = orderDetail.template as OrderTemplate;

  const Td = ({
    className,
    children,
  }: {
    className?: string;
    children: React.ReactNode;
  }) => {
    return (
      <td className={`border border-black py-1 ${className}`}>{children}</td>
    );
  };

  const printImg = (id: string) => {
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule(
      'body > div:last-child img { display: inline-block; }'
    );

    const imageDiv = document.getElementById(id);
    imageDiv!.style.display = 'block';

    const img = html2canvas(imageDiv, { scale: 10 }).then((canvas) => {
      style.remove();

      const imgData = canvas.toDataURL('image/png');
      // **다운 받지 않고 브라우저 확인용**
      // sample.current!.src = imgData;
      const link = document.createElement('a');
      link.href = imgData;
      link.download = `거래명세서-${userName} ${new Date(
        orderDetail.created_at
      ).toLocaleDateString()}.png`;
      link.click();
    });
  };

  return (
    <div className="max-w-[1000px] w-[100%] m-5 bg-white overflow-x-auto rounded-lg p-3">
      <div id="receipt" className="min-w-[720px] pb-10">
        <img
          className="block"
          src={
            process.env.PUBLIC_URL +
            '/images/mypage/receipt/receipt_header_cather.png'
          }
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
        />
        <div className="px-[7%]">
          <div>
            <span className="border-b border-black  font-bold">
              {userName} 귀하
            </span>
          </div>
          <div className=" font-bold">
            금액 : ₩ {numberWithCommas(template.total_price)}
          </div>
          <table className="w-full text-center mt-2 border-collapse">
            <thead className="font-bold bg-gray4 ">
              <tr className="bg-white">
                <td colSpan={4} />
                <Td>작성일</Td>
                <Td>{new Date().toLocaleDateString()}</Td>
              </tr>
              <tr className="bg-white h-2">
                <td></td>
              </tr>
              <tr>
                <Td className="w-[90px]">날짜</Td>
                <Td>내용</Td>
                <Td>정가</Td>
                <Td>구입가</Td>
                <Td>수량</Td>
                <Td>계</Td>
              </tr>
            </thead>
            <tbody>
              {/* 물품 */}
              {getPurchaseItem(orderDetail).map((item, index) => (
                <tr key={index}>
                  <Td>{item.purchase_date.toLocaleDateString()}</Td>
                  <Td>{item.name}</Td>
                  <Td>₩ {numberWithCommas(item.origin_price)}</Td>
                  <Td>₩ {numberWithCommas(item.current_price)}</Td>
                  <Td>{item.quantity}</Td>
                  <Td>
                    ₩ {numberWithCommas(item.current_price * item.quantity)}
                  </Td>
                </tr>
              ))}
              {/* 배송비 */}
              {template.shipping_fee > 0 && (
                <tr>
                  <Td>
                    {new Date(orderDetail.created_at).toLocaleDateString()}
                  </Td>
                  <Td>배송비</Td>
                  <Td> </Td>
                  <Td> </Td>
                  <Td> </Td>
                  <Td>₩ {numberWithCommas(template.shipping_fee)} </Td>
                </tr>
              )}
              {/* 계 */}
              <tr>
                <td></td>
              </tr>
              <tr className="font-bold bg-gray4">
                <Td>합계</Td>
                <Td> </Td>
                <Td> </Td>
                <Td> </Td>
                <Td> </Td>
                <Td>₩ {numberWithCommas(template.total_price)}</Td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <HFbutton onClick={() => printImg('receipt')}>다운로드</HFbutton>
        <HFbutton onClick={() => dispatch(closeModal())}>닫기</HFbutton>
      </div>
    </div>
  );
};

type orderItem = {
  purchase_date: Date;
  name: string;
  origin_price: number;
  current_price: number;
  quantity: number;
};

const getPurchaseItem = (orderDetail: orderDetailType) => {
  const order_items = (orderDetail.template as OrderTemplate).order_items;
  const purchase_date = new Date(orderDetail.created_at);
  const order_item: orderItem[] = [];

  const makeOption = (
    option: OrderItemVariant,
    origin_price: number,
    current_price: number
  ) => {
    return {
      purchase_date: purchase_date,
      name: option.variant.name,
      origin_price: origin_price + option.variant.additional_price,
      current_price: current_price,
      quantity: option.quantity,
    };
  };

  order_items.map((item) => {
    // Shop Item 계산
    if (item.chosen_variants.length > 0) {
      item.chosen_variants.map((variant) => {
        order_item.push(
          makeOption(
            variant,
            item.product.origin_price,
            item.product.current_price
          )
        );
      });
    } else if (item.enroll_options.length > 0) {
      // 기본 코스 계산
      order_item.push({
        purchase_date: purchase_date,
        name: item.product.name,
        origin_price: item.product.origin_price,
        current_price: item.product.current_price,
        quantity: 1, // 코스 상품의 수량을 1로 고정
      });

      // 추가된 옵션 상품 계산
      item.chosen_options.map((option) => {
        option.chosen_variants.map((variant) => {
          order_item.push(
            makeOption(
              variant,
              option.product.origin_price,
              option.product.current_price
            )
          );
        });
      });
    }
  });
  return order_item;
};

export default PurchaseReceipt;
