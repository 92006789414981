import React from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import HFbutton from '../../../../components/common/input/HFbutton';
import dayjs from 'dayjs';
import { getSubTypeName } from '../../../../api/utils/util-func';
import { useHomeworkItem } from './hooks/useHomeworkItem';
import { ToDoStatusEnum } from '../../../../enum';
import { Divider, Progress } from 'antd';
import StudentHomeworkAiRecitationButtons from './StudentHomeworkAiRecitationButtons';
export interface StudentHomeworkItemProps {
  data: myLearningTodoType;
  setTodoView: (value: React.SetStateAction<Window>) => void;
  lastItemRef?: (node: HTMLDivElement) => void;
}
const StudentHomeworkItem = ({
  data,
  setTodoView,
  lastItemRef,
}: StudentHomeworkItemProps) => {
  const {
    startHandler,
    getButtonLabel,
    getSubTypeColor,
    getSubTypeBgColor,
    getSubTypeStrokeColor,
    isBeforeStartDay,
    todo,
    handleResultView,
    checkRecitationAttemptCount,
  } = useHomeworkItem({
    data,
    setTodoView,
    lastItemRef,
  });

  const getRemainDaysText = (remainDays: number) => {
    if (remainDays <= 8 && remainDays > 2) {
      return `${remainDays - 1}일 남음`;
    } else if (remainDays == 1) {
      return '오늘 마감';
    } else if (remainDays == 2) {
      return '내일 마감';
    } else {
      return '';
    }
  };

  return (
    <div
      className="w-full bg-white rounded-lg shadow-card p-5 flex flex-col xl:flex-row gap-3 items-center"
      ref={lastItemRef}
    >
      <div className="w-full flex flex-col items-start">
        <div className="flex gap-2">
          {todo.remainDays <= 8 && todo.remainDays > 0 && (
            <Chip
              color={getSubTypeColor(todo.type)}
              bgColor={getSubTypeBgColor(todo.type)}
              text={getRemainDaysText(todo.remainDays)}
            />
          )}
          <TextWinds type={'caption1'} className="mt-2">
            {`${dayjs(todo.fromDate).format('M월 D일 ')} ~ 
            ${dayjs(todo.toDate).format('M월 D일 ')}까지`}
          </TextWinds>
        </div>
        <div>
          <TextWinds type={'title_h3'} children={todo.name} className="mt-2" />
        </div>
        <div>
          <TextWinds
            type={'content_body2'}
            children={getSubTypeName(todo.type)}
            className="mt-2"
          ></TextWinds>
        </div>
        {/* {todo.type !== 'JEBS_AI_RECITATION' &&
          todo.type !== 'RECITATION' &&
          (todo.status === 'IN_PROGRESS' || todo.status === 'COMPLETED') && (
            <div className="mt-2 w-full">
              <Progress
                type="line"
                percent={todo.progressRate ?? 0}
                strokeColor={getSubTypeStrokeColor(todo.type)}
              />
            </div>
          )} */}
      </div>

      {/* 오른쪽 버튼 영역 */}
      <div className="flex w-full xl:w-[220px] justify-center mt-2">
        {todo.type === 'JEBS_AI_RECITATION' ? (
          // 1. JEBS_AI_RECITATION 경우
          <StudentHomeworkAiRecitationButtons
            isBeforeStartDay={isBeforeStartDay}
            todo={todo}
            startHandler={startHandler}
            checkRecitationAttemptCount={checkRecitationAttemptCount}
            handleResultView={handleResultView}
          />
        ) : (
          // 2. JEBS_AI_RECITATION 아닌 경우 (JEBSON, JEBSPL)
          <div className="w-full xl:w-[220px]">
            {(() => {
              switch (todo.status) {
                case ToDoStatusEnum.NOT_STARTED:
                  // 할일기간 전
                  return isBeforeStartDay ? (
                    <>
                      <Divider className="xl:hidden mt-0" />
                      <div className="text-[#6032db] font-bold text-center text-lg">
                        할 일 대기중
                      </div>
                    </>
                  ) : (
                    // 진행 전
                    <HFbutton onClick={() => startHandler()} height={40}>
                      시작하기
                    </HFbutton>
                  );
                case ToDoStatusEnum.IN_PROGRESS:
                  // 진행중
                  return (
                    <div className="flex flex-col gap-2 w-full xl:w-[220px] justify-center items-center">
                      <HFbutton onClick={() => startHandler()} height={40}>
                        진행 중
                      </HFbutton>
                      {todo.recentlySubmissionAt && (
                        <div className="text-xs">
                          최근 학습 날짜 :{' '}
                          {dayjs(todo.recentlySubmissionAt).format(
                            'YYYY-MM-DD'
                          )}
                        </div>
                      )}
                    </div>
                  );
                case ToDoStatusEnum.COMPLETED:
                  return (
                    <div className="flex flex-col gap-2 w-full xl:w-[220px] justify-center items-center">
                      <HFbutton onClick={() => startHandler()} height={40}>
                        {todo.name.includes('Test') ? '결과 보기' : '완료'}
                      </HFbutton>
                      {todo.recentlySubmissionAt && (
                        <div className="text-xs">
                          최근 학습 날짜 :{' '}
                          {dayjs(todo.recentlySubmissionAt).format(
                            'YYYY-MM-DD'
                          )}
                        </div>
                      )}
                    </div>
                  );
                case ToDoStatusEnum.CLOSED:
                  // 최근학습날짜가 있으면 완료, 없으면 마감
                  return todo.recentlySubmissionAt ? (
                    <div className="flex flex-col gap-2 w-full xl:w-[220px] justify-center items-center">
                      <Divider className="xl:hidden mt-0" />
                      <div className="text-blue-500 font-bold text-center text-lg">
                        완료
                      </div>
                      <div className="text-xs">
                        최근 학습 날짜 :{' '}
                        {dayjs(todo.recentlySubmissionAt).format('YYYY-MM-DD')}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Divider className="xl:hidden mt-0" />
                      <div className="text-red5 font-bold text-center text-lg">
                        마감
                      </div>
                    </>
                  );
              }
            })()}
          </div>
        )}
      </div>
    </div>
  );
};

interface ChipProps {
  color: 'tangerine5' | 'skyblue' | string;
  bgColor: string;
  text: string;
}
const Chip = ({ color, bgColor, text }: ChipProps) => {
  return (
    <div
      className={`text-${color} ${bgColor} border border-${color} rounded-md text-center w-fit p-1 text-sm `}
    >
      {text}
    </div>
  );
};

export default StudentHomeworkItem;
