import React from 'react';
import {
  ADVANCED,
  Arrcodian,
  BASIC,
  BreadIcon,
  DiamondIcon,
  GradientButton,
  KIDS,
  PlusIcon,
  SnowFlower,
  SnowmanIcon,
} from './HomeschoolComp';
import { motion } from 'framer-motion';
import { numberWithCommas } from '../../../api/utils/format/numbers';
import { HOMESCHOOL_PRICES } from '../../../data/statics-datas';

const {
  KIDS: KIDS_PRICES,
  BASIC: BASIC_PRICES,
  ADVANCED: ADVANCED_PRICES,
  KIDS_BASIC: KIDS_BASIC_PRICES,
  BASIC_ADVANCED: BASIC_ADVANCED_PRICES,
  KIDS_BASIC_ADVANCED: KIDS_BASIC_ADVANCED_PRICES,
} = HOMESCHOOL_PRICES;

const HomeschoolSection6 = () => {
  return (
    <div className="relative flex flex-col items-center overflow-hidden bg-[#fff6e5] py-20">
      {/* <motion.section className="absolute left-0 top-0 w-full h-full">
        <motion.div
          className="absolute w-fit"
          initial={{ right: -50, y: 100 }}
          whileInView={{ y: 200 }}
          transition={{
            duration: 5,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'linear',
          }}
        >
          <SnowFlower width={100} color="white" opacity={1} />
        </motion.div>
        <motion.div
          className="absolute w-fit"
          initial={{ y: 500 }}
          whileInView={{ y: 300 }}
          transition={{
            duration: 10,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'linear',
          }}
        >
          <BreadIcon width={100} color="white" opacity={1} />
        </motion.div>
        <motion.div
          className="absolute w-fit"
          initial={{ right: 1, y: 800 }}
          whileInView={{ y: 1100 }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'linear',
          }}
        >
          <SnowmanIcon width={200} color="white" />
        </motion.div>
      </motion.section> */}
      <section className="flex flex-col items-center">
        <DiamondIcon color="black" width={24} />
        <div className="mt-4 text-center text-3xl font-bold">
          젭스홈스쿨
          <br />
          패키지 코스 구성
        </div>
      </section>
      <motion.section
        className="z-2 flex w-full max-w-[480px] flex-col p-5"
        whileInView={{ x: 0 }}
      >
        <Block color={'#bf4bff'} text={'단일코스'}>
          <Arrcodian
            body={
              <div className="m-auto flex w-fit items-center gap-5 text-xl font-bold">
                <div className="flex gap-1">
                  <KIDS />
                  코스
                </div>
              </div>
            }
          >
            <CourseInfo
              title="단일 코스 패키지"
              headBadge={'1년'}
              infoArr={[
                <>
                  키즈바/키즈톡
                  <br />
                  <div>
                    교재 <span className="text-[#eb5d1a]">12세트</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 1년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (키즈)
                </>,
                <div>
                  젭스펜 <span className="text-[#eb5d1a]">1개</span>
                </div>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/qux25BBx_82y3Web?palnItems=true'
                  }
                  originPrice={KIDS_PRICES.ONEYEAR.ORIGINAL_PRICE}
                  discountPrice={KIDS_PRICES.ONEYEAR.DISCOUNT_PRICE}
                  mothlyPrice={KIDS_PRICES.ONEYEAR.MONTHLY_PRICE}
                />
              }
            />
            <CourseInfo
              headBadge={'2년'}
              infoArr={[
                <>
                  키즈바/키즈톡
                  <br />
                  <div>
                    교재 <span className="text-[#eb5d1a]">24세트</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 2년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (키즈)
                </>,
                <div>
                  젭스펜 <span className="text-[#eb5d1a]">1개</span>
                </div>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/XbaPE1kO0FdHYgU5?palnItems=true'
                  }
                  originPrice={KIDS_PRICES.TWOYEAR.ORIGINAL_PRICE}
                  discountPrice={KIDS_PRICES.TWOYEAR.DISCOUNT_PRICE}
                  mothlyPrice={KIDS_PRICES.TWOYEAR.MONTHLY_PRICE}
                />
              }
            />
          </Arrcodian>
          <Arrcodian
            body={
              <div className="m-auto flex w-fit items-center gap-5 text-xl font-bold">
                <div className="flex gap-1">
                  <BASIC />
                  코스
                </div>
              </div>
            }
          >
            <CourseInfo
              title="단일 코스 패키지"
              headBadge={'1년'}
              infoArr={[
                <>
                  잉스바/잉포/잉크
                  <br />
                  <div>
                    교재 <span className="text-[#eb5d1a]">12세트</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 1년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (베이직)
                </>,
                <>
                  <div>
                    암송인증제 <span className="text-[#eb5d1a]">스타터팩</span>
                  </div>
                  (스티커보드/
                  <br />
                  패스포트/응시권)
                  <Bar />
                  <div>
                    젭스펜 <span className="text-[#eb5d1a]">1개</span>
                  </div>
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/F3j1H7AOUmL6Rxpb?palnItems=true'
                  }
                  originPrice={BASIC_PRICES.ONEYEAR.ORIGINAL_PRICE}
                  discountPrice={BASIC_PRICES.ONEYEAR.DISCOUNT_PRICE}
                  mothlyPrice={BASIC_PRICES.ONEYEAR.MONTHLY_PRICE}
                />
              }
            />
            <CourseInfo
              headBadge={'2년'}
              infoArr={[
                <>
                  잉스바/잉포/잉크
                  <br />
                  <div>
                    교재 <span className="text-[#eb5d1a]">24세트</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 2년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (베이직)
                </>,
                <>
                  <div>
                    암송인증제 <span className="text-[#eb5d1a]">스타터팩</span>
                  </div>
                  (스티커보드/
                  <br />
                  패스포트/응시권)
                  <Bar />
                  <div>
                    젭스펜 <span className="text-[#eb5d1a]">1개</span>
                  </div>
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/DAvFDd_N0nbFgISL?palnItems=true'
                  }
                  originPrice={BASIC_PRICES.TWOYEAR.ORIGINAL_PRICE}
                  discountPrice={BASIC_PRICES.TWOYEAR.DISCOUNT_PRICE}
                  mothlyPrice={BASIC_PRICES.TWOYEAR.MONTHLY_PRICE}
                />
              }
            />
          </Arrcodian>
          <Arrcodian
            body={
              <div className="m-auto flex w-fit items-center gap-5 text-xl font-bold">
                <div className="flex gap-1">
                  <ADVANCED />
                  코스
                </div>
              </div>
            }
          >
            <CourseInfo
              title="단일 코스 패키지"
              headBadge={'1년'}
              infoArr={[
                <>
                  마가복음/사도행전
                  <br />
                  <div>
                    교재 <span className="text-[#eb5d1a]">12권</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 1년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (어드밴스드)
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/URxr_HxtwXy5H-u_?palnItems=true'
                  }
                  originPrice={ADVANCED_PRICES.ONEYEAR.ORIGINAL_PRICE}
                  discountPrice={ADVANCED_PRICES.ONEYEAR.DISCOUNT_PRICE}
                  mothlyPrice={ADVANCED_PRICES.ONEYEAR.MONTHLY_PRICE}
                />
              }
            />
            <CourseInfo
              headBadge={'2년'}
              infoArr={[
                <>
                  마가복음/사도행전
                  <br />
                  <div>
                    교재 <span className="text-[#eb5d1a]">22권</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 2년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (어드밴스드)
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/7a0Ai0xhoqWnedG-?palnItems=true'
                  }
                  originPrice={ADVANCED_PRICES.TWOYEAR.ORIGINAL_PRICE}
                  discountPrice={ADVANCED_PRICES.TWOYEAR.DISCOUNT_PRICE}
                  mothlyPrice={ADVANCED_PRICES.TWOYEAR.MONTHLY_PRICE}
                />
              }
            />
          </Arrcodian>
        </Block>
        <Block color={'#8f47ff'} text={'결합코스'}>
          <Arrcodian
            body={
              <div className="m-auto flex w-fit items-center gap-5 text-lg font-bold">
                <div className="flex gap-1">
                  <KIDS />
                  코스
                </div>
                <CirclePlus />
                <div className="flex gap-1">
                  <BASIC />
                  코스
                </div>
              </div>
            }
          >
            <CourseInfo
              title="코스 결합 패키지"
              headBadge={'1년'}
              infoArr={[
                <>
                  키즈바/키즈톡
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">12세트</span>
                  </div>
                  <Bar />
                  잉스바/잉포/잉크
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">12세트</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 1년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (베이직/키즈)
                </>,
                <>
                  <div>
                    암송인증제{' '}
                    <span className="font-bold text-[#eb5d1a]">스타터팩</span>
                  </div>
                  (스티커보드/패스포트/응시권)
                  <Bar />
                  <div>
                    젭스펜 <span className="font-bold text-[#eb5d1a]">2개</span>
                  </div>
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/G9sAabCJyMZ73WVy?palnItems=true'
                  }
                  originPrice={KIDS_BASIC_PRICES.ONEYEAR.ORIGINAL_PRICE}
                  discountPrice={KIDS_BASIC_PRICES.ONEYEAR.DISCOUNT_PRICE}
                  mothlyPrice={KIDS_BASIC_PRICES.ONEYEAR.MONTHLY_PRICE}
                />
              }
            />
            <CourseInfo
              headBadge={'2년'}
              infoArr={[
                <>
                  키즈바/키즈톡
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">24세트</span>
                  </div>
                  <Bar />
                  잉스바/잉포/잉크
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">24세트</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 2년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (베이직/키즈)
                </>,
                <>
                  <div>
                    암송인증제{' '}
                    <span className="font-bold text-[#eb5d1a]">스타터팩</span>
                  </div>
                  (스티커보드/패스포트/응시권)
                  <Bar />
                  <div>
                    젭스펜 <span className="font-bold text-[#eb5d1a]">2개</span>
                  </div>
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/ePHSyDRLQ211coNI?palnItems=true'
                  }
                  originPrice={KIDS_BASIC_PRICES.TWOYEAR.ORIGINAL_PRICE}
                  discountPrice={KIDS_BASIC_PRICES.TWOYEAR.DISCOUNT_PRICE}
                  mothlyPrice={KIDS_BASIC_PRICES.TWOYEAR.MONTHLY_PRICE}
                />
              }
            />
          </Arrcodian>
          <Arrcodian
            body={
              <div className="w-full px-3">
                <div className="m-auto flex w-fit items-center gap-5 text-center text-lg font-bold">
                  <div>
                    <BASIC />
                    코스
                  </div>
                  <CirclePlus />
                  <div className="">
                    <ADVANCED />
                    코스
                  </div>
                </div>
              </div>
            }
          >
            <CourseInfo
              title="코스 결합 패키지"
              headBadge={'1년'}
              infoArr={[
                <>
                  잉스바/잉포/잉크
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">12세트</span>
                  </div>
                  <Bar />
                  마가복음/사도행전
                  <div>
                    교재 <span className="font-bold text-[#eb5d1a]">12권</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 1년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (베이직/어드밴스드)
                </>,
                <>
                  <div>
                    암송인증제{' '}
                    <span className="font-bold text-[#eb5d1a]">스타터팩</span>
                  </div>
                  (스티커보드/패스포트/응시권)
                  <Bar />
                  <div>
                    젭스펜 <span className="font-bold text-[#eb5d1a]">1개</span>
                  </div>
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/7Bh6k_VPJQstBvZ1?palnItems=true'
                  }
                  originPrice={BASIC_ADVANCED_PRICES.ONEYEAR.ORIGINAL_PRICE}
                  discountPrice={BASIC_ADVANCED_PRICES.ONEYEAR.DISCOUNT_PRICE}
                  mothlyPrice={BASIC_ADVANCED_PRICES.ONEYEAR.MONTHLY_PRICE}
                />
              }
            />
            <CourseInfo
              headBadge={'2년'}
              infoArr={[
                <>
                  잉스바/잉포/잉크
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">24세트</span>
                  </div>
                  <Bar />
                  마가복음/사도행전
                  <div>
                    교재 <span className="font-bold text-[#eb5d1a]">22권</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 2년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (베이직/어드밴스드)
                </>,
                <>
                  <div>
                    암송인증제{' '}
                    <span className="font-bold text-[#eb5d1a]">스타터팩</span>
                  </div>
                  (스티커보드/패스포트/응시권)
                  <Bar />
                  <div>
                    젭스펜 <span className="font-bold text-[#eb5d1a]">1개</span>
                  </div>
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/uA_-KA6aaYwYHG-L?palnItems=true'
                  }
                  originPrice={BASIC_ADVANCED_PRICES.TWOYEAR.ORIGINAL_PRICE}
                  discountPrice={BASIC_ADVANCED_PRICES.TWOYEAR.DISCOUNT_PRICE}
                  mothlyPrice={BASIC_ADVANCED_PRICES.TWOYEAR.MONTHLY_PRICE}
                />
              }
            />
          </Arrcodian>
          <Arrcodian
            body={
              <div className="w-full px-3">
                <div className="m-auto flex w-fit flex-col items-center gap-2 text-lg font-bold">
                  <div className="flex items-center gap-5">
                    <div className="flex">
                      <KIDS />
                      코스
                    </div>
                    <CirclePlus />
                    <div className="flex">
                      <BASIC />
                      코스
                    </div>
                  </div>
                  <div className="flex items-center gap-5">
                    <CirclePlus />
                    <div className="flex gap-1">
                      <ADVANCED />
                      코스
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            <CourseInfo
              title="코스 결합 패키지"
              headBadge={'1년'}
              infoArr={[
                <>
                  키즈바/키즈톡
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">12세트</span>
                  </div>
                  <Bar />
                  잉스바/잉포/잉크
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">12세트</span>
                  </div>
                  <Bar />
                  마가복음/사도행전
                  <div>
                    교재 <span className="font-bold text-[#eb5d1a]">12권</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 1년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (키즈/베이직/
                  <br />
                  어드밴스드)
                </>,
                <>
                  <div>
                    암송인증제{' '}
                    <span className="font-bold text-[#eb5d1a]">스타터팩</span>
                  </div>
                  (스티커보드/패스포트/응시권)
                  <Bar />
                  <div>
                    젭스펜 <span className="font-bold text-[#eb5d1a]">2개</span>
                  </div>
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/9lRXLvcfVXMreAM4?palnItems=true'
                  }
                  originPrice={
                    KIDS_BASIC_ADVANCED_PRICES.ONEYEAR.ORIGINAL_PRICE
                  }
                  discountPrice={
                    KIDS_BASIC_ADVANCED_PRICES.ONEYEAR.DISCOUNT_PRICE
                  }
                  mothlyPrice={KIDS_BASIC_ADVANCED_PRICES.ONEYEAR.MONTHLY_PRICE}
                />
              }
            />
            <CourseInfo
              headBadge={'2년'}
              infoArr={[
                <>
                  키즈바/키즈톡
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">24세트</span>
                  </div>
                  <Bar />
                  잉스바/잉포/잉크
                  <div>
                    교재{' '}
                    <span className="font-bold text-[#eb5d1a]">24세트</span>
                  </div>
                  <Bar />
                  마가복음/사도행전
                  <div>
                    교재 <span className="font-bold text-[#eb5d1a]">22권</span>
                  </div>
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">교사교육</span>
                  </div>
                  (최초교육 /<br /> 연 2회 역량강화 교육)
                </>,
                <>
                  <div>
                    온라인 <span className="text-[#eb5d1a]">서비스 2년</span>
                  </div>
                  & 교사용 메뉴얼
                  <br />
                  (키즈/베이직/
                  <br />
                  어드밴스드)
                </>,
                <>
                  <div>
                    암송인증제{' '}
                    <span className="font-bold text-[#eb5d1a]">스타터팩</span>
                  </div>
                  (스티커보드/패스포트/응시권)
                  <Bar />
                  <div>
                    젭스펜 <span className="font-bold text-[#eb5d1a]">2개</span>
                  </div>
                </>,
              ]}
              bottom={
                <LinkButton
                  link={
                    '/courses/homeschool/details/-z9gknEYqDqDygHc?palnItems=true'
                  }
                  originPrice={
                    KIDS_BASIC_ADVANCED_PRICES.TWOYEAR.ORIGINAL_PRICE
                  }
                  discountPrice={
                    KIDS_BASIC_ADVANCED_PRICES.TWOYEAR.DISCOUNT_PRICE
                  }
                  mothlyPrice={KIDS_BASIC_ADVANCED_PRICES.TWOYEAR.MONTHLY_PRICE}
                />
              }
            />
          </Arrcodian>
        </Block>
      </motion.section>
    </div>
  );
};

const Block = ({
  color,
  text,
  children,
}: {
  color: string;
  text: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="mt-10 w-full max-w-[720px]">
      <div
        className="rounded-t-xl p-2 text-center text-xl font-bold text-white"
        style={{ backgroundColor: color }}
      >
        {text}
      </div>
      <div className="flex flex-col gap-2 rounded-b-xl bg-gray2 p-2 md:gap-4 md:p-4">
        {children}
      </div>
    </div>
  );
};

const Card = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex items-center justify-center rounded-lg border border-gray4 bg-white py-3">
      {children}
    </div>
  );
};

const CirclePlus = ({ color = '#ffc06e' }: { color?: string }) => (
  <div
    className="h-fit w-fit rounded-full p-1"
    style={{ backgroundColor: color }}
  >
    <PlusIcon width={10} />
  </div>
);

const CourseInfo = ({
  title,
  headBadge,
  infoArr,
  bottom,
}: {
  title?: string;
  headBadge: React.ReactNode;
  infoArr: React.ReactNode[];
  bottom: React.ReactNode;
}) => {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };
  return (
    <div
      className="flex cursor-default flex-col items-center justify-center gap-3 border-t p-5"
      onClick={onClick}
    >
      {/* <div className="flex items-center gap-2 text-lg rounded-full border py-1 px-5 border-[#eb5d1a]">
    <KIDS />
    1년 <CirclePlus color="#d9d9d9" /> <BASIC />
    1년
  </div> */}
      {title && <div className="text-2xl font-bold">{title}</div>}
      <div className="relative mt-5 grid w-full grid-cols-2 gap-3 text-center text-sm font-bold">
        <div className="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 rounded-full bg-black p-1.5 px-10 text-white transition">
          {headBadge}
        </div>
        {infoArr?.map((info, idx) => (
          <div
            className="flex h-auto flex-col items-center justify-center rounded-lg bg-gray2 py-5"
            key={idx}
          >
            {info}
          </div>
        ))}
      </div>
      {bottom}
    </div>
  );
};

const Bar = () => <div className="my-1 h-[1px] w-1/3 bg-gray6" />;

const LinkButton = ({
  link,
  originPrice,
  discountPrice,
  mothlyPrice,
}: {
  link: string;
  originPrice: number;
  discountPrice: number;
  mothlyPrice: number;
}) => (
  <a href={link} target="_blank" className="w-full">
    <div
      className="max-md:px-5 max-md:mt-10 max-md:mr-1 max-md:max-w-full cursor-pointer rounded-xl px-3 py-2 text-xl font-bold tracking-tighter text-white hover:opacity-90"
      style={{
        background: 'linear-gradient(90deg, #5E6EFF 0%, #21C1FF 100%)',
      }}
    >
      <div className="pl-2">
        <div className="text-base text-gray4">
          정가{' '}
          <span className="line-through">
            {numberWithCommas(originPrice)}원
          </span>
        </div>
        <div className="text-lg">
          할인가 {numberWithCommas(discountPrice)}원
          <span className="text-base">
            {' '}
            (월{numberWithCommas(mothlyPrice)}원)
          </span>
        </div>
      </div>
      <div className="mt-2 border-t-2 border-gray4 pt-2 text-center text-base">
        구매하기
      </div>
    </div>
  </a>
);
export default HomeschoolSection6;
