import React, { ReactNode } from 'react';
import {
  AccordionColor,
  BreadIcon,
  DownCircleArrow,
  PlusIcon,
  SnowFlower,
} from './HomeschoolComp';
import HomeschoolSection3DropAD from './HomeschoolSection3DropAD';
import HomeschoolSection3DropPatent from './HomeschoolSection3DropPatent';
import book from './img/book-ad.png';
import patent from './img/patent.png';
import image1 from './img/section3-e.png';
import image2 from './img/section3-f.png';
import image3 from './img/section3-g.png';
import { motion } from 'framer-motion';

const HomeschoolSection3 = () => {
  const checkSize = 15;
  const boldcount = 10;
  const Part3Plus = () => {
    return (
      <div className="bg-[#ffd297] rounded-full w-fit h-fit p-1.5">
        <PlusIcon width={10} />
      </div>
    );
  };

  const Part3MiniBox = ({
    bgColor,
    children,
    delay,
  }: {
    bgColor: string;
    children: ReactNode;
    delay?: number;
  }) => {
    return (
      <motion.div
        className={`flex flex-col gap-1 rounded-lg items-center py-2 px-3 md:px-5`}
        style={{ backgroundColor: bgColor }}
        initial={{ y: -20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ delay: delay || 0 }}
      >
        {children}
      </motion.div>
    );
  };
  return (
    <div
      style={{
        backgroundImage: `
        linear-gradient(to right, rgba(0, 0, 100, 0.03) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(0, 0, 100, 0.03) 1px, transparent 1px),
        linear-gradient(to right, rgba(0, 0, 0, 0.07) 1.5px, transparent 1px),
        linear-gradient(to bottom, rgba(0, 0, 0, 0.07) 1.5px, transparent 1px)`,
        backgroundSize: `${checkSize}px ${checkSize}px,${checkSize}px ${checkSize}px, ${
          checkSize * boldcount
        }px ${checkSize * boldcount}px, ${checkSize * boldcount}px ${
          checkSize * boldcount
        }px`,
        backgroundColor: '#FFFCDF',
        backgroundPosition: '-6px 3px',
      }}
      className="relative flex flex-col items-center overflow-hidden"
    >
      {/* 움직이는 배경 */}
      <div className="absolute top-0 left-0 w-full h-full">
        <motion.div
          initial={{ y: 100 }}
          whileInView={{ y: 200 }}
          transition={{
            duration: 5,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'linear',
          }}
        >
          <svg
            width="100"
            viewBox="0 0 377 412"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g className="mix-blend-mode:hard-light" opacity="0.2">
              <path
                d="M377 206C377 185.502 369.175 165.004 353.526 149.335C352.454 148.262 351.382 147.189 350.203 146.223L226.402 23.342C195.104 -7.78067 143.761 -7.78067 112.463 23.342C84.3796 51.1378 81.4855 94.8169 103.566 126.047H23.6046C-20.7708 126.047 -57 161.999 -57 206C-57 250.001 -20.7708 285.953 23.6046 285.953H103.566C81.4855 317.076 84.3796 360.755 112.463 388.658C143.761 419.781 195.104 419.781 226.402 388.658L350.203 265.777C351.382 264.811 352.454 263.738 353.526 262.665C369.175 247.103 377 226.605 377 206Z"
                fill="#fab63a"
              />
            </g>
          </svg>
        </motion.div>

        <motion.div
          className="absolute top-0 right-0"
          initial={{ y: 400 }}
          whileInView={{ y: 300 }}
          transition={{
            duration: 14,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'linear',
          }}
        >
          <svg
            width="150"
            viewBox="0 0 335 420"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g className="mix-blend-mode:hard-light" opacity="0.2">
              <path
                d="M276.165 148.21C274.453 148.21 272.74 148.21 271.027 148.317C271.349 144.248 271.67 140.071 271.67 135.895C271.67 60.8261 210.87 0 135.835 0C60.7992 0 0 60.8261 0 135.895C0 210.964 60.7992 271.79 135.835 271.79C137.547 271.79 139.26 271.79 140.973 271.683C140.651 275.752 140.33 279.929 140.33 284.105C140.33 359.174 201.13 420 276.165 420C351.201 420 412 359.174 412 284.105C412 209.036 351.201 148.21 276.165 148.21Z"
                fill="#fab63a"
              />
            </g>
          </svg>
        </motion.div>

        <motion.div
          initial={{ y: 400 }}
          whileInView={{ y: 500 }}
          transition={{
            duration: 10,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'linear',
          }}
        >
          <BreadIcon color="#fab63a" width={200} />
        </motion.div>
      </div>

      {/* 본문 시작 */}
      <div className="flex flex-col w-full max-w-[480px] items-center p-5 pt-[80px] z-2">
        <SnowFlower color="#EB5D1A" opacity={1} width={24} />
        <div className="text-3xl md:text-4xl text-center mt-3">
          영어교육의 해답,
          <div className="font-bold">왜 젭스홈스쿨에 있을까?</div>
        </div>
        <div className="flex flex-col w-full gap-8 mt-[60px] ">
          <AccordionColor
            src={book}
            textArea={
              <>
                누구나 되는, 쉽고, 확실한
                <div className="font-bold">‘A.D.영어학습법’이 적용!</div>
              </>
            }
            color="#58b066"
          >
            <HomeschoolSection3DropAD />
          </AccordionColor>
          <AccordionColor
            src={patent}
            textArea={
              <>
                특허받은 교수법
                <div className="font-bold">‘삽화기억술'로 확실하게!</div>
              </>
            }
            color="#36a1ee"
          >
            <HomeschoolSection3DropPatent />
          </AccordionColor>
          <AccordionColor
            textArea={
              <>
                최고의 영어교재
                <div className="font-bold">영어성경으로 영어와 신앙 훈련!</div>
                <div className="bg-white text-[#eb5d1a] px-5 py-0.5 rounded-full w-fit text-sm font-bold mt-2">
                  하브루타 교육법 적용!
                </div>
              </>
            }
            color="#eb5d1a"
          >
            <div className="bg-white text-center py-5 px-3 relative text-base">
              전 세계 노벨상 수상자의 32%를 차지하는
              <div className="font-bold">하브루타 교육법을 아시나요?</div>
              <br />
              <div className="font-bold">
                젭스홈스쿨은 신명기의 쉐마를 중심으로한 말씀 중심 암송 교육부터
                <br />
                하브루타의 질문과 토론 수업 과정까지
                <br />
                자녀에게 가르치는{' '}
                <span className="text-[#eb5d1a]">영어 신앙 훈련 프로그램</span>
                입니다
              </div>
            </div>
          </AccordionColor>
        </div>
      </div>
      <motion.div
        className="p-5 w-full max-w-[480px] relative"
        animate={{ x: 0 }}
      >
        <div className="bg-white shadow-xl p-4 md:px-5 md:py-10 rounded-lg text-lg md:text-xl text-center">
          <div>
            언어를 주관하시는 하나님이 알려주시는
            <br />
            성경 속 영어능통의 방법을 알면,
          </div>
          <div className="font-bold">
            <span className="text-[#eb5d1a]">영어</span>가 터지고
            <span className="text-[#eb5d1a]"> 신앙</span>이 자라납니다.
          </div>
          <div className="flex justify-between items-center mt-5 md:mt-10 gap-1">
            <Part3MiniBox bgColor={'#fff1f1'} delay={0.1}>
              <img
                src={image1}
                alt="section3-e"
                className="w-[70px] mt-[-3px] mb-[3px]"
              />
              <div className="text-base font-bold">말씀교육</div>
            </Part3MiniBox>
            <Part3Plus />
            <Part3MiniBox bgColor={'#f6f2ff'} delay={0.3}>
              <img src={image2} alt="section3-e" className="w-[70px]" />
              <div className="text-base font-bold">신앙교육</div>
            </Part3MiniBox>
            <Part3Plus />
            <Part3MiniBox bgColor={'#e4faff'} delay={0.5}>
              <img src={image3} alt="section3-e" className="w-[70px]" />
              <div className="text-base font-bold">영어교육</div>
            </Part3MiniBox>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HomeschoolSection3;
