import React, { useContext, useState } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { StudentProfileContext } from './StudentProfileContext';
import { Button, Input } from '@material-tailwind/react';
import { Link, useNavigate } from 'react-router-dom';
import instance from '../../../api/axios/utils.ts/instance';
import { useAppDispatch } from '../../../store/hook';
import { openAlert, openConfirm } from '../../../store/features/modalSlice';
import HFbutton from '../../../components/common/input/HFbutton';
import { getErrorMessages } from '../../../api/utils/util-func';

interface StudentClassCompProps {
  editable?: boolean;
}
const StudentClassComp = ({ editable }: StudentClassCompProps) => {
  const CLASS_LIMIT = 3;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { studentProfile, setStudentProfile } = useContext(
    StudentProfileContext
  );
  const [classList, setClassList] = useState<string[]>(studentProfile.classIds);
  const [classCode, setClassCode] = useState<string>('');

  const putClassCode = async () => {
    try {
      const param = { classIds: [classCode] };
      const result = await instance.put('/v1/user/student/class-code', param);
      if (result.status === 200) {
        const list = [...classList, classCode];
        setClassList(list);
        setStudentProfile({ ...studentProfile, classIds: list });
        setClassCode('');
      }
    } catch (error) {
      // dispatch(
      //   openAlert({ message: '클래스 코드 추가 실패. 코드를 확인해 주세요.' })
      // );
      dispatch(openAlert({ message: getErrorMessages(error) }));
    }
  };

  const deleteClassCode = async (code: string) => {
    try {
      const result = await instance.delete(
        '/v1/user/student/class-code/' + code
      );
      if (result.status === 200) {
        const list = classList.filter((item) => item !== code);
        setClassList(list);
        setStudentProfile({ ...studentProfile, classIds: list });
      } else if (result.status === 400) {
        dispatch(openAlert({ message: result.data.message }));
      }
    } catch (error) {
      dispatch(
        openAlert({
          message: '클래스 코드 삭제 실패. 새로고침 후 다시 시도해 주세요.',
        })
      );
    }
  };

  const deleteHandler = (code: string) => {
    dispatch(
      openConfirm({
        message: '클래스 코드를 삭제하시겠습니까?',
        onConfirm: () => {
          deleteClassCode(code);
        },
      })
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <TextWinds type="title_h2">클래스 정보</TextWinds>
        {!editable && (
          <Link to="/student/profile/edit/class">
            <div className="flex items-center hover:bg-purple2 px-2 border border-purple5 rounded cursor-pointer">
              <div className="text-purple5">수정하기</div>
              <img src="/svg/icon/write.svg" alt="modify" />
            </div>
          </Link>
        )}
      </div>
      {editable && (
        <TextWinds type="content_body2">
          클래스 코드는 최대 {CLASS_LIMIT}개까지 등록 가능합니다.
        </TextWinds>
      )}
      {classList.map((classId, index) => (
        <div className="flex" key={index}>
          <Input type="text" value={classId} disabled />
          {editable && (
            <img
              alt="delete"
              src="/svg/icon/devide-circle.svg"
              className="ml-2 px-2 border border-gray2 cursor-pointer rounded-lg hover:bg-gray2 hover:shadow-card"
              onClick={() => deleteHandler(classId)}
            />
          )}
        </div>
      ))}
      {editable && classList.length < CLASS_LIMIT && (
        <div className="relative flex w-full">
          <Input
            type="email"
            label="클래스 코드"
            className="pr-20"
            value={classCode}
            onChange={(e) => setClassCode(e.target.value)}
            containerProps={{
              className: 'min-w-0',
            }}
          />
          <Button
            size="sm"
            onClick={putClassCode}
            className="!absolute right-1 top-1 rounded bg-purple5 hover:bg-purple6 shadow-none"
            children="추가하기"
            disabled={classCode === ''}
          />
        </div>
      )}
      {editable && (
        <HFbutton onClick={() => navigate('/student/profile')}>완료</HFbutton>
      )}
    </div>
  );
};

export default StudentClassComp;
