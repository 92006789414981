import React, { useState } from 'react';
import { numberWithCommas } from '../../../api/utils/format/numbers';
import { useTicketExchange } from './TicketExchangeContext';
import { LoadingSpinner } from '../../Loading/LoadingSpinner';
import { Button, Input } from 'antd';
import { Button as MButton, Input as MInput } from '@material-tailwind/react';

const TicketExchange = () => {
  const {
    state: { fromTicket, toTicket, exchangeRate: rate, state },
    setFromSelected,
  } = useTicketExchange();

  const [inputSelected, setInputSelected] = useState<number>(0);

  const handleIncrease = () => {
    if (fromTicket.selected + rate.from <= fromTicket.amount) {
      setFromSelected(fromTicket.selected + rate.from);
      setInputSelected(fromTicket.selected + rate.from);
    }
  };

  const handleDecrease = () => {
    if (fromTicket.selected - rate.from >= 0) {
      setFromSelected(fromTicket.selected - rate.from);
      setInputSelected(fromTicket.selected - rate.from);
    }
  };

  const handleMax = () => {
    setFromSelected(Math.floor(fromTicket.amount / rate.from) * rate.from);
  };

  const handleMin = () => {
    setFromSelected(0);
  };

  const handleApply = () => {
    const amount = Math.floor(Number(inputSelected) / rate.from) * rate.from;
    setFromSelected(amount);
    setInputSelected(amount);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="text-gray-600">보유 현황</div>
        <div className="flex items-center justify-between rounded bg-gray-100 p-3">
          {state === 'init' ? (
            <LoadingSpinner />
          ) : (
            <span>
              {fromTicket.type} :{' '}
              {fromTicket.amount ? numberWithCommas(fromTicket.amount) : 0}개
              {/* {numberWithCommas(fromTicket.amount)}개 */}
            </span>
          )}
          <span>
            교환비율 {rate.from}:{rate.to}
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="text-gray-600">교환 수량</div>
        <div className="flex items-center justify-between gap-4">
          <div className="flex-1 rounded border p-4">
            <div className="text-center text-sm text-gray-500">
              {fromTicket.type}
            </div>
            <div className="mt-2 flex flex-col items-center">
              <div className="flex w-full items-center justify-between gap-2">
                {/* - 버튼 */}
                <Button
                  onClick={handleDecrease}
                  className="rounded border px-3 py-1 text-sm hover:bg-gray-100"
                  disabled={fromTicket.selected === 0}
                >
                  -
                </Button>

                {/* 이용권 수량 입력 */}
                <div className="relative flex w-full">
                  <Input
                    className="w-[100px] pr-12"
                    value={inputSelected}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      setInputSelected(Number(value));
                    }}
                  />
                  <Button
                    size="small"
                    onClick={handleApply}
                    disabled={Number(inputSelected) > fromTicket.amount}
                    className="!absolute right-1 top-1 rounded bg-purple5 text-white shadow-none hover:bg-purple6"
                  >
                    적용
                  </Button>
                </div>

                {/* + 버튼 */}
                <Button
                  onClick={handleIncrease}
                  className="rounded border px-3 py-1 text-sm hover:bg-gray-100"
                  disabled={fromTicket.selected + rate.from > fromTicket.amount}
                >
                  +
                </Button>
              </div>
            </div>
          </div>

          <div className="text-2xl">→</div>

          <div className="flex-1 rounded border p-4">
            <div className="text-center text-sm text-gray-500">
              {toTicket.type}
            </div>
            <div className="mt-2 flex items-center justify-between">
              <span className="w-full text-center text-xl font-bold">
                {numberWithCommas(toTicket.selected)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 rounded bg-blue-50 p-3 text-blue-600">
        {fromTicket.type} 이용권 {numberWithCommas(fromTicket.selected)}개를{' '}
        <br />
        {toTicket.type} 이용권 {numberWithCommas(toTicket.selected)}개로
        교환합니다.
      </div>
    </div>
  );
};

export default TicketExchange;
