import React from 'react';
import { useAppDispatch } from '../../../store/hook';
import { closeModal } from '../../../store/features/modalSlice';
import TicketExchange from './TicketExchange';
import HFbutton from '../../common/input/HFbutton';
import { useTicketExchange } from './TicketExchangeContext';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../Loading/LoadingSpinner';
const TicketExchangeModal = () => {
  const dispatch = useAppDispatch();
  const { exchangeTickets, state } = useTicketExchange();
  const { classId } = useParams();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleExchange = async () => {
    await exchangeTickets(classId);
  };

  return (
    <div className="flex w-full max-w-[500px] flex-col gap-4 rounded-lg bg-white p-4">
      <div className="text-lg font-bold">이용권 교환</div>
      {state.state === 'loading' ? (
        <div className="flex flex-col items-center gap-2">
          <LoadingSpinner message="교환중" />
        </div>
      ) : state.state === 'error' ? (
        <div className="p-5 text-center">{state.error}</div>
      ) : state.state === 'done' ? (
        <div className="p-5 text-center">정상적으로 교환이 완료되었습니다.</div>
      ) : (
        <div>
          <TicketExchange />
        </div>
      )}
      <div className="flex justify-end gap-2">
        <HFbutton type="Outlined" onClick={handleClose}>
          닫기
        </HFbutton>
        {state.state !== 'done' && (
          <HFbutton
            onClick={handleExchange}
            disabled={
              state.state !== 'exchange' || state.fromTicket.selected === 0
            }
          >
            교환하기
          </HFbutton>
        )}
      </div>
    </div>
  );
};

export default TicketExchangeModal;
