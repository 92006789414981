import React from 'react';
import WmwSection1 from './WmwSection1';
import WmwSection2 from './WmwSection2';
import WmwSection3 from './WmwSection3';
import WmwSection4 from './WmwSection4';
import WmwSection5 from './WmwSection5';
import WmwSection6 from './WmwSection6';
import WmwSection7 from './WmwSection7';
import WmwSection8a from './WmwSection8a';
import WmwSection8b from './WmwSection8b';
import WmwSection9 from './WmwSection9';
import WmwSection10 from './WmwSection10';
import WmwSection11 from './WmwSection11';
import WmwSection12 from './WmwSection12';
import WmwSection13 from './WmwSection13';
import WmwSection14 from './WmwSection14';
import GodDealWeekSection from '../event/GodDealWeekSection';
const WmwMain = () => {
  return (
    <div className="flex flex-col">
      {/* 초등학생 전도에 최적화된 주일학교 프로그램이 있다? */}
      <WmwSection1 />

      <WmwSection2 />

      {/* 이런 분들이라면, 반드시 도입하세요! */}
      <WmwSection3 />

      {/* 왜 WMW인지 알 수 있는 동역자들의 수 많은 실제후기! */}
      <WmwSection4 />

      {/* 유튜브 영상, 지금 바로 도입 문의하기 */}
      <WmwSection5 />

      {/* 정답은, WMW Series! */}
      <WmwSection6 />

      {/* 삽화기억술 버튼, 커리큘럼 과정 */}
      <WmwSection7 />

      {/* 교재 구성 */}
      <WmwSection8a />
      <WmwSection8b />

      {/* 갓딜링크 이벤트 */}
      <GodDealWeekSection />

      {/* WMW Series, 어떻게 도입할까요? */}
      <WmwSection9 />

      {/* 프로그램 구성&가격 */}
      <WmwSection10 />

      {/* 성공하는 교회와 아닌 교회의 차이, 젭스플이란? */}
      <WmwSection11 />

      {/* WMW Series는 [교사대학] 수료 후 도입 가능해요! */}
      <WmwSection12 />

      {/* 교사 코스는 이렇게 구성되어 있어요! */}
      <WmwSection13 />

      {/* 환불 규정 */}
      <WmwSection14 />
    </div>
  );
};

export default WmwMain;
