import React from 'react';
import {
  AccordionColor,
  KIDS,
  NumberingText,
  HeadBox,
  SnowFlower,
  PurchaseButton,
  PurchaseBox,
  GrayNumList,
  AsteriskLi,
} from './HomeschoolComp';
import TextWinds from '../../common/TextWinds';
import KidsBibleImg from './img/section4a1-a.png';
import KidsTalkImg from './img/section4a1-b.png';
import KidsBibleLinkImg from './img/kids_bible.png';
import KidsBookLinkImg from './img/kids_book.png';
import KidsTalkLinkImg from './img/kids_talk.png';
import { HOMESCHOOL_PRICES } from '../../../data/statics-datas';
import { numberWithCommas } from '../../../api/utils/format/numbers';

const { KIDS: KIDS_PRICES } = HOMESCHOOL_PRICES;

const HomeschoolKIDS = () => {
  return (
    <div>
      {/* section1 - 미취학 어린이를 위한 맞춤 홈스쿨 코스 */}
      <div className="w-full bg-[#fff8dd]">
        <div className="m-auto flex max-w-[520px] flex-col items-center gap-10 px-5 py-20 text-black">
          <SnowFlower color="#ff4f00" width={24} opacity={1} />
          <div className="mt-3 text-center text-3xl md:text-4xl">
            미취학 어린이를 위한
            <div className="font-bold">맞춤 홈스쿨 코스</div>
          </div>
          <div className="w-full rounded-lg bg-[#ff4f00] py-3 text-center text-xl font-bold text-white">
            KIDS | 2년
          </div>
          {/* 1. 바이블 스토리 암송 */}
          <div className="flex w-full flex-col gap-5 rounded-lg bg-white p-3 py-5">
            <NumberingText number={1} text="바이블 스토리 암송" />
            <img src={KidsBibleImg} alt="kids-bible" className="w-full px-3" />
            <div>
              <TextWinds type="title_h2">키즈바 (Kids Bible)</TextWinds>
              <div className="text-gray6">
                삽화를 통한 연상 기억법으로 신구약 바이블 스토리 문장을 재미있고
                자연스럽게 암송!
              </div>
            </div>
            <div className="flex flex-wrap gap-3 text-base font-semibold text-white">
              <div className="rounded bg-[#ff4f00] p-1">
                총 24권 ( 구약 12권 + 신약 12권 )
              </div>
              <div className="rounded bg-gray6 p-1">젭스펜 적용</div>
            </div>
            <div className="flex rounded bg-gray2 p-2 text-[8px] text-gray8">
              <ol className="flex-1 list-inside list-decimal">
                <li>Who Made the World?</li>
                <li>Adam and Eve</li>
                <li>Noah and the Big Boat</li>
                <li>The Tower of Babel</li>
                <li>Abraham and Isaac</li>
                <li>The Golden Calf</li>
                <li>The Fall of Jericho</li>
                <li>Samuel Hears God</li>
                <li>Saul and David</li>
                <li>Esther Savers Her People</li>
                <li>Daniel and His Friends</li>
                <li>Jonah and the Big Fish</li>
              </ol>
              <ol
                className="flex-1 list-inside list-decimal border-l border-gray4 pl-2"
                start={13}
              >
                <li>Jesus is Born</li>
                <li>John Baptizes Jesus</li>
                <li>Jesus Defeats Satan</li>
                <li>Jesus Calls Twelve Disciples</li>
                <li>Jesus Feeds Five Thousand</li>
                <li>Jesus and Zacchaeus</li>
                <li>Jesus Heals Ten Lepers</li>
                <li>Jesus Raises Lazzarus</li>
                <li>The Last Supper</li>
                <li>Jesus Dies on the Cross</li>
                <li>Jesus Rises Again</li>
                <li>The Holy Spirit Comes</li>
              </ol>
            </div>
          </div>
          {/* 2. 회화 */}
          <div className="flex w-full flex-col gap-5 rounded-lg bg-white p-3 py-5">
            <NumberingText number={2} text="회화" />
            <img src={KidsTalkImg} alt="kids-bible" className="w-full px-3" />
            <div>
              <TextWinds type="title_h2">키즈톡 (Kids Talk)</TextWinds>
              <div className="text-gray6">
                3박자 패턴 영어회화 구성으로 일생생활의 유용한 표현을 나선형
                구조로 학습하여 영어로 말하는 습관 기르기!
              </div>
            </div>
            <div className="flex flex-wrap gap-3 text-base font-semibold text-white">
              <div className="rounded bg-[#ff4f00] p-1">총 24권</div>
              <div className="rounded bg-gray6 p-1">젭스펜 적용</div>
            </div>
            <div className="flex rounded bg-gray2 p-2 text-[7px] text-gray8">
              <ol className="flex-1 list-inside list-decimal">
                <li>Do you like dogs?</li>
                <li>Do you like kimbap?</li>
                <li>Do you like balls?</li>
                <li>Do you like singing?</li>
                <li>Can you act?</li>
                <li>Can you jump?</li>
                <li>Can you play the piano?</li>
                <li>Can you make a shirt?</li>
                <li>Let’s go to the park?</li>
                <li>Let’s go the field!</li>
                <li>Let’s go see ants!</li>
                <li>Let’s plant lettuce!</li>
              </ol>
              <ol
                className="flex-1 list-inside list-decimal border-l border-gray4 pl-2"
                start={13}
              >
                <li>Hi! How are you?</li>
                <li>How’s the weather?</li>
                <li>What’s the matter?</li>
                <li>What are yoyu doing?</li>
                <li>Who is that?</li>
                <li>Who is it?</li>
                <li>Where is the mirrror?</li>
                <li>Mommy! Where are you?</li>
                <li>When do you get up?</li>
                <li>When is the picnic?</li>
                <li>Which do you like more? Kitterns or pupp</li>
                <li>Why do you like donuts?</li>
              </ol>
            </div>
          </div>
          {/* KIDS 권장 수업 시간 */}
          <div className="w-full">
            <div className="m-auto w-fit text-lg font-bold">
              <KIDS /> 권장 수업 시간
            </div>
            <div className="mt-3 w-full drop-shadow-lg">
              <div className="w-full">
                <div className="flex w-full text-center text-sm font-bold text-white">
                  <div className="w-[130px] rounded-tl-lg border-r border-dotted border-[#fff1dc] bg-[#ff4f00] py-2">
                    교재
                  </div>
                  <div className="flex-1 rounded-tr-lg bg-[#ff4f00] py-2">
                    권장 수업 시간
                  </div>
                </div>
                <div className="flex text-center text-xs font-semibold text-[#583b3b]">
                  <div className="w-[130px] rounded-bl-lg bg-[#fff1dc] px-2">
                    <div className="border-b border-[#f9bf64] py-3">
                      키즈바 (Kids Bible)
                    </div>
                    <div className="py-3">키즈톡 (Kids Talk)</div>
                  </div>
                  <div className="flex-1 rounded-br-lg bg-white px-3">
                    <div className="border-b border-gray3 py-3">
                      레슨 당{' '}
                      <span className="font-bold text-[#ff4f00]">30분</span> /
                      주 2회 권장
                    </div>
                    <div className="py-3">
                      레슨 당{' '}
                      <span className="font-bold text-[#ff4f00]">20분</span> /
                      주 2회 권장
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* section 2 - KIDS코스가 좋은 이유 7가지 */}
      <div className="flex w-full max-w-[520px] flex-col gap-5 px-5 py-10">
        <div className="text-center text-2xl font-semibold">
          <KIDS />
          코스가
          <div className="font-extrabold">좋은 이유 7가지</div>
        </div>
        {/* <List /> */}
        <GrayNumList
          textArr={[
            '아이들이 좋아하는 귀여운 삽화로 성경을 재미있게',
            '피닉스 기초와 함께 600개 단어를 체득',
            '3박자 패턴의 회화 구성으로 일상 영어 표현을 쉽게 학습',
            '복습으로 탄탄하게 레벨업되는 나선형 구조의 교수법',
            '젭스펜 원어민 음원으로 완벽하게 발음 연습',
            'Rhythm&Stress,어순감각 등 영어의 기본 원리 습득',
            '신나는 노래와 놀이로 재미있게 영어 학습',
          ]}
        />

        <AccordionColor
          textArea={
            <div className="text-center font-bold">[KIDS 교재] 미리보기</div>
          }
          color={'#ff4f00'}
        >
          <div className="mt-3 flex flex-col gap-3">
            <a
              href="https://teach.jebs2.kr/media/PPTX/kids/book01/kids_bible/lesson02/"
              target="_blank"
            >
              <img src={KidsBibleLinkImg} />
            </a>
            <a
              href="https://teach.jebs2.kr/media/PPTX/kids/book01/kids_talk/lesson02/"
              target="_blank"
            >
              <img src={KidsTalkLinkImg} />
            </a>
            <a
              href="https://teach.jebs2.kr/media/PPTX/kids/sample/"
              target="_blank"
            >
              <img src={KidsBookLinkImg} />
            </a>
          </div>
        </AccordionColor>
      </div>
      <div className="flex flex-col gap-5 bg-[#f4f4f4] px-5 py-10">
        <div className="text-center text-2xl font-semibold">
          <KIDS /> <span className="font-extrabold">멤버십 비용</span>
        </div>

        <PurchaseBox
          head={'1년'}
          textArr={[
            <>
              온라인 교사 교육
              <div className="text-xs font-normal text-gray6">
                (최초 수료 강의 + 연 2회 강화 교육)
              </div>
            </>,
            <>
              KIDS 교재 총 12세트
              <div className="text-xs font-normal text-gray6">
                (키즈바 1-12권/키즈톡 1-12권)
              </div>
            </>,
            '온라인 서비스 1년 & 교사용 메뉴얼',
            '젭스펜 1개',
          ]}
          button={
            <>
              <div className="text-[#d6d0ff]">멤버십 1년 </div>
              {numberWithCommas(KIDS_PRICES.ONEYEAR.DISCOUNT_PRICE)}원{' '}
              <span className="text-base">
                (월 {numberWithCommas(KIDS_PRICES.ONEYEAR.MONTHLY_PRICE)}원)
              </span>
            </>
          }
          link="/courses/homeschool/details/qux25BBx_82y3Web?palnItems=true"
        />
        <PurchaseBox
          head={'2년'}
          textArr={[
            <>
              온라인 교사 교육
              <div className="text-xs font-normal text-gray6">
                (최초 수료 강의 + 연 2회 강화 교육)
              </div>
            </>,
            <>
              KIDS 교재 총 24세트
              <div className="text-xs font-normal text-gray6">
                (키즈바 1-24권/키즈톡 1-24권)
              </div>
            </>,
            '온라인 서비스 2년 & 교사용 메뉴얼',
            '젭스펜 1개',
          ]}
          button={
            <>
              <div className="text-[#d6d0ff]">멤버십 2년 </div>
              {numberWithCommas(KIDS_PRICES.TWOYEAR.DISCOUNT_PRICE)}원{' '}
              <span className="text-base">
                (월 {numberWithCommas(KIDS_PRICES.TWOYEAR.MONTHLY_PRICE)}원)
              </span>
            </>
          }
          link="/courses/homeschool/details/XbaPE1kO0FdHYgU5?palnItems=true"
        />
        <HeadBox
          head={
            <div className="w-fit rounded-full bg-[#ff373f] px-9 py-1 font-bold text-white">
              연장
            </div>
          }
        >
          <div className="p-5">
            <div className="grid grid-cols-2 text-center text-xl font-semibold">
              <div className="pr-5">
                <div className="h-24 leading-[6rem]">연장 패키지</div>
                <PurchaseButton>
                  <div className="relative w-full py-2 pt-3 text-center font-extrabold leading-4 text-white">
                    <div className="absolute left-1/2 top-[-4px] w-fit -translate-x-1/2 -translate-y-full transform text-nowrap rounded bg-[#ff6363] px-2 py-1 text-xs">
                      교재 12세트
                      <div className="absolute bottom-0 left-1/2 h-0 w-0 -translate-x-1/2 translate-y-full transform border-l-[6px] border-r-[6px] border-t-[6px] border-l-transparent border-r-transparent border-t-[#ff6363]"></div>
                    </div>
                    348,000원
                    <br />
                    <span className="text-base font-semibold text-[#d6d0ff]">
                      (월 29,000원)
                    </span>
                  </div>
                </PurchaseButton>
              </div>
              <div className="flex flex-col justify-between border-l pl-5">
                <div className="mt-6">
                  온라인 서비스
                  <br />
                  연장(1년)
                </div>
                <PurchaseButton>
                  <div className="w-full py-2 pt-3 text-center font-extrabold leading-4 text-white">
                    120,000원
                    <br />
                    <span className="text-base font-semibold text-[#d6d0ff]">
                      (월 10,000원)
                    </span>
                  </div>
                </PurchaseButton>
              </div>
            </div>
            <div className="pt-5 text-center text-xs text-gray6 md:text-base">
              * 교재 12세트 구매 시, 온라인 서비스는 무료 연장됩니다.
            </div>
          </div>
        </HeadBox>
        <HeadBox head={<></>}>
          <ul className="list-none space-y-1 p-5 text-sm font-medium text-[#868686]">
            <AsteriskLi>
              해당 멤버십은 KIDS 과정만 이용할 수 있습니다.
            </AsteriskLi>
            <AsteriskLi>
              목사님, 사모님, 선교사님은 교역자 할인이 적용되오니,
              <br /> 젭스 본사로 별도 문의를 부탁 드립니다.
            </AsteriskLi>
          </ul>
        </HeadBox>
      </div>
    </div>
  );
};

export default HomeschoolKIDS;
