import React from 'react';

const Polygon = ({ color }: { color: string }) => (
  <svg
    width="38"
    height="33"
    viewBox="0 0 38 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1054 29.8663C21.7608 33.6625 16.2402 33.6625 13.8956 29.8663L1.27074 9.42491C-1.19808 5.42757 1.67732 0.27206 6.37559 0.272061L31.6254 0.272063C36.3237 0.272063 39.1991 5.42757 36.7302 9.4249L24.1054 29.8663Z"
      fill={color}
    />
  </svg>
);

export default Polygon;
